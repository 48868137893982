<template>
  <v-container
    class="pa-0"
    style="height: 100%; width: 100%; min-width: 100%; position: relative"
  >
    <NavBarAnimation1
      v-if="isMdOrLower"
      title="Formulario Percepción Turista Atractivo"
      subTitle="¡Gracias por apostarle al turismo en el Guaviare! Su retroalimentación es muy importante para seguir construyendo un destino turístico sostenible.
        Recuerde que digitar bien su número de identificación para que el sistema identifique si ya está registrado en el sistema, en caso contrario, le haremos unas preguntas adicionales sobre su llegada al departamento."
    >
    </NavBarAnimation1>

    <v-app-bar class="elevation-0" v-if="isMdOrLower">
      <v-slide-group
        v-model="stepFormServiceOrder"
        class="py-4"
        center-active
        show-arrows
      >
        <v-slide-group-item
          v-for="(item, index) in stepTitles.filter((step) => step.show)"
          :key="index"
          v-slot="{ isSelected }"
          :value="item.id"
        >
          <v-list-item
            :color="isSelected ? 'primary' : 'grey-lighten-1'"
            @click="stepFormServiceOrder = index + 1"
          >
            <template v-slot:prepend>
              <v-avatar
                :color="
                  isSelected || stepFormServiceOrder > index + 1
                    ? 'primary'
                    : 'grey-lighten-1'
                "
                class="text-white"
                size="x-small"
              >
                <v-icon size="x-small" v-if="stepFormServiceOrder == index + 1"
                  >mdi-pencil</v-icon
                >
                <span v-else>{{ index + 1 }}</span>
              </v-avatar>
            </template>
            <v-list-item-title
              class="text-caption"
              :class="
                isSelected || stepFormServiceOrder > index + 1 ? 'text-primary' : ''
              "
              >{{ item.title }}</v-list-item-title
            >
          </v-list-item>
        </v-slide-group-item>
      </v-slide-group>
    </v-app-bar>

    <v-row class="h-100 mh-100 auth">
      <v-col
        lg="5"
        xl="4"
        cols="12"
        class="d-lg-flex d-none align-center justify-center bg-primary pa-0"
        style="position: sticky"
      >
        <LeftPanelAnimation1
          title="Formulario Percepción Turista"
          subTitle="¡Gracias por apostarle al turismo en el Guaviare! Su retroalimentación es muy importante para seguir construyendo un destino turístico sostenible.
                Recuerde que digitar bien su número de identificación para que el sistema identifique si ya está registrado en el sistema, en caso contrario, le haremos unas preguntas adicionales sobre su llegada al departamento."
          :wordsAnimate="['Atractivo']"
        ></LeftPanelAnimation1>
      </v-col>
      <v-col lg="7" xl="8" cols="12" style="background: #fff; z-index: 9">
        <v-row class="h-100 mh-100">
          <v-col
            lg="5"
            xl="4"
            cols="12"
            class="d-lg-flex d-none align-center justify-center position-relative"
          >
            <div class="scroll-container-wrapper">
              <div
                class="scroll-container position-relative"
                ref="scrollContainer"
                @scroll="handleScroll"
              >
                <div class="item">
                  <div
                    class="mouse m-2"
                    :class="{
                      'animate__animated animate__fadeInDown': showIndicator,
                      'animate__animated animate__fadeOutDown': !showIndicator,
                    }"
                  ></div>
                </div>
                <v-timeline class="py-8" align="start" side="end" :line-thickness="1">
                  <v-timeline-item
                    v-for="(item, index) in stepTitles.filter((step) => step.show)"
                    :key="index"
                    :class="stepFormServiceOrder > item.id - 1 ? 'success-step' : ''"
                    :dot-color="
                      stepFormServiceOrder > item.id - 1 ? 'primary' : 'dot-customer'
                    "
                    size="small"
                  >
                    <template v-slot:opposite>
                      <p class="font-weight-medium text-end">
                        {{ showGroupLabel(item, index) ? item.label : null }}
                      </p>
                    </template>
                    <template v-slot:icon>
                      <v-btn
                        width="20"
                        height="20"
                        @click="stepFormServiceOrder = item.id"
                        size="x-small"
                        icon
                        variant="text"
                      >
                        <v-icon v-if="stepFormServiceOrder == item.id">mdi-pencil</v-icon>
                        <v-icon v-else-if="stepFormServiceOrder > item.id"
                          >mdi-check</v-icon
                        >
                        <span v-else>{{ index + 1 }}</span>
                      </v-btn>
                    </template>
                    <div class="d-flex">
                      <div :ref="'timelineItem' + item.id"></div>

                      <div
                        class="text-left text-body-2"
                        :class="{ 'text-primary': stepFormServiceOrder > item.id }"
                      >
                        {{ item.title }}
                      </div>
                    </div>
                  </v-timeline-item>
                </v-timeline>
              </div>
              <div v-if="showTopShadow" class="top-shadow"></div>
              <div v-if="showBottomShadow" class="bottom-shadow"></div>
            </div>
          </v-col>

          <v-col lg="7" xl="8" cols="12" class="d-flex align-center justify-center pr-6">
            <v-card width="100%" elevation="0">
              <v-card-text>
                <p class="text-h4 font-weight-bold text-primary">
                  {{ currentTitle.label }}
                </p>
                <p class="text-h5 font-weight-bold">
                  {{ currentTitle.title }}
                </p>
              </v-card-text>
              <v-col md="12" cols="12" class="py-1">
                <br />
              </v-col>

              <v-window
                :touch="false"
                direction="vertical"
                v-model="stepFormServiceOrder"
              >
                <v-window-item :value="1">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep1">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Nombre del atractivo turístico</span
                          >
                          <span class="font-weight-bold">*</span>

                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_a_managed_sites"
                            :items="formItems.itemsTouristAttractionNames"
                            item-value="id"
                            item-title="text"
                            :rules="[validations.required]"
                            variant="underlined"
                          >
                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-radio
                                      color="primary"
                                      :true-value="item.raw.id"
                                      :model-value="isActive"
                                      v-model="form.field_a_managed_sites"
                                    ></v-radio>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsTouristAttractionNames.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Tipo de documento de identificación</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.items_type_doc"
                            :items="formItems.itemsDocumentType"
                            item-value="id"
                            item-title="text"
                            :rules="[validations.required]"
                            variant="underlined"
                          >
                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-radio
                                      color="primary"
                                      :true-value="item.raw.id"
                                      :model-value="isActive"
                                      v-model="form.items_type_doc"
                                    ></v-radio>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="index + 1 < formItems.itemsDocumentType.length"
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">Número de documento</span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.field_f_document_number"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>


                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Autoriza el tratamiento de los datos personales sensibles que se recolectarán en el presente formulario de registro?</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_d_authorize_sensitive_data_processing"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.field_d_authorize_sensitive_data_processing"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_d_authorize_sensitive_data_processing ==
                                  item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>

                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="2">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep2">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Cuál es su nivel de percepción de las siguientes
                            características del atractivo, teniendo en cuenta que 5
                            <v-icon color="yellow">mdi-star</v-icon> es "Muy satisfecho" y
                            1 "Muy insatisfecho"?</span
                          >
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <v-list style="width: 100%">
                            <v-list-item class="px-0">
                              <v-list-item-title class="text-wrap"> </v-list-item-title>
                              <template v-slot:append>
                                <div
                                  class="d-flex text-center align-center justify-center"
                                  style="width: 100px"
                                >
                                  Sin servicio/No utilizó
                                </div>
                              </template>
                            </v-list-item>
                            <v-list-item
                              v-for="(item, index) in ratings_atractivo"
                              :key="index"
                              class="px-0"
                            >
                              <v-list-item-title class="text-wrap">
                                <span class="">{{ item.label }}</span>
                              </v-list-item-title>

                              <v-list-item-title>
                              
                                <v-rating
                                  v-model="form[item.model]"
                                >
                                  <template v-slot:item="props">
                                    <v-icon
                                      size="28" class="mr-2"
                                      :color="
                                        props.isFilled ? 'orange-lighten-1' : 'grey-lighten-2'
                                      "
                                    >
                                      {{
                                        props.isFilled ? "mdi-star" : "mdi-star-outline"
                                      }}
                                    </v-icon>
                                  </template>
                                </v-rating>
                              </v-list-item-title>
                              <template v-slot:append>
                                <div
                                  class="d-flex text-center align-center justify-center"
                                  style="width: 100px"
                                >
                                  <v-checkbox
                                    v-model="form[item.model]"
                                    value="NA"
                                    color="primary"
                                    hide-details
                                  ></v-checkbox>
                                </div>
                              </template>
                            </v-list-item>
                          </v-list>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <v-input
                            v-model="rating_atractivo"
                            :rules="[validations.required]"
                          >
                          </v-input>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="3">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep3">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Usted observó en el atractivo visitado alguna de las
                            siguientes practicas de sostenibilidad ambiental?.
                          </span>
                          <span class="font-weight-bold">*</span>

                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_f_sustainability_practices_environmental"
                            :items="formItems.itemsSustainabilityPracticesEnvironmental"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{
                                  form.field_f_sustainability_practices_environmental
                                    .length - 2
                                }}
                                {{
                                  form.field_f_sustainability_practices_environmental
                                    .length -
                                    2 ==
                                  1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 <
                                  formItems.itemsSustainabilityPracticesEnvironmental
                                    .length
                                "
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="4">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep4">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Usted observó en el atractivo visitado alguna de las
                            siguientes practicas de sostenibilidad social?. .
                          </span>
                          <span class="font-weight-bold">*</span>

                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_f_sustainability_practices_social"
                            :items="formItems.itemsSustainabilityPracticesSocial"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{
                                  form.field_f_sustainability_practices_social.length - 2
                                }}
                                {{
                                  form.field_f_sustainability_practices_social.length -
                                    2 ==
                                  1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 <
                                  formItems.itemsSustainabilityPracticesSocial.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="5">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep5">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Durante la visita al atractivo uso servicio de guianza
                            turistica o interprete local?</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_f_guide_service_usage"
                          >
                            <v-radio
                              v-for="(item, index) in formItems.itemsGuideServiceUsage"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_f_guide_service_usage == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="6">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep6">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Cuál es su nivel de percepción de las siguientes
                            caracteristicas del guia o intérprete local, teniendo en
                            cuenta que 5 <v-icon color="yellow">mdi-star</v-icon> es "Muy
                            satisfecho" y 1 "Muy insatisfecho"?
                          </span>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1">
                          <v-list style="width: 100%">
                            <!-- <v-list-item class="px-0">
                              <v-list-item-title class="text-wrap"> </v-list-item-title>
                              <template v-slot:append>
                                <div
                                  class="d-flex text-center align-center justify-center"
                                  style="width: 100px"
                                >
                                  Sin servicio/No utilizó
                                </div>
                              </template>
                            </v-list-item> -->
                            <v-list-item
                              v-for="(item, index) in ratings_guianza_turistica"
                              :key="index"
                              class="px-0"
                            >
                              <v-list-item-title class="text-wrap">
                                <span class="">{{ item.label }}</span>
                              </v-list-item-title>

                              <v-list-item-title>
                              
                                <v-rating
                                  v-model="form[item.model]"
                                >
                                  <template v-slot:item="props">
                                    <v-icon
                                      size="28" class="mr-2"
                                      :color="
                                        props.isFilled ? 'orange-lighten-1' : 'grey-lighten-2'
                                      "
                                    >
                                      {{
                                        props.isFilled ? "mdi-star" : "mdi-star-outline"
                                      }}
                                    </v-icon>
                                  </template>
                                </v-rating>
                              </v-list-item-title>
                              <!-- <template v-slot:append>
                                <div
                                  class="d-flex text-center align-center justify-center"
                                  style="width: 100px"
                                >
                                  <v-checkbox
                                    v-model="form[item.model]"
                                    value="NA"
                                    color="primary"
                                    hide-details
                                  ></v-checkbox>
                                </div>
                              </template> -->
                            </v-list-item>
                          </v-list>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <v-input
                            v-model="rating_guia"
                            :rules="[validations.required]"
                          >
                          </v-input>
                        </v-col>

                        <!-- <v-row>
                          <template
                            v-for="(item, index) in ratings_guianza_turistica"
                            :key="index"
                          >
                            <v-col md="6" cols="12" class="py-1 d-flex align-center">
                              <span class="">{{ item.label }}</span>
                            </v-col>
                            <v-col
                              md="6"
                              cols="12"
                              class="py-1 d-flex align-center justify-center"
                            >
                              <v-rating
                                v-model="form[item.model]"
                                active-color="orange-lighten-1"
                                color="orange-lighten-1"
                              ></v-rating>
                            </v-col>
                          </template>
                        </v-row> -->
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="7">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep7">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Realizo la compra de comidas preparadas al interior del
                            atractivo?</span
                          >
                          <span class="font-weight-bold">*</span>
                          <!-- {{ form.field_f_food_purchase_inside }} -->
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_f_food_purchase_inside"
                          >
                            <v-radio
                              v-for="(item, index) in formItems.itemsFoodPurchaseInside"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_f_food_purchase_inside == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="8">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep8">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Cuál es su nivel de percepción de las siguientes
                            caracteristicas respecto a la comida adquirida, teniendo en
                            cuenta que 5 <v-icon color="yellow">mdi-star</v-icon> es "Muy
                            satisfecho" y 1 "Muy insatisfecho"?
                          </span>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1">
                          <v-list style="width: 100%">
                            <!-- <v-list-item class="px-0">
                              <v-list-item-title class="text-wrap"> </v-list-item-title>
                              <template v-slot:append>
                                <div
                                  class="d-flex text-center align-center justify-center"
                                  style="width: 100px"
                                >
                                  Sin servicio/No utilizó
                                </div>
                              </template>
                            </v-list-item> -->
                            <v-list-item
                              v-for="(item, index) in rating_shop_food"
                              :key="index"
                              class="px-0"
                            >
                              <v-list-item-title class="text-wrap">
                                <span class="">{{ item.label }}</span>
                              </v-list-item-title>

                              <v-list-item-title>
                              
                                <v-rating
                                  v-model="form[item.model]"
                                >
                                  <template v-slot:item="props">
                                    <v-icon
                                      size="28" class="mr-2"
                                      :color="
                                        props.isFilled ? 'orange-lighten-1' : 'grey-lighten-2'
                                      "
                                    >
                                      {{
                                        props.isFilled ? "mdi-star" : "mdi-star-outline"
                                      }}
                                    </v-icon>
                                  </template>
                                </v-rating>
                              </v-list-item-title>
                              <!-- <template v-slot:append>
                                <div
                                  class="d-flex text-center align-center justify-center"
                                  style="width: 100px"
                                >
                                  <v-checkbox
                                    v-model="form[item.model]"
                                    value="NA"
                                    color="primary"
                                    hide-details
                                  ></v-checkbox>
                                </div>
                              </template> -->
                            </v-list-item>
                          </v-list>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <v-input
                            v-model="rating_comida"
                            :rules="[validations.required]"
                          >
                          </v-input>
                        </v-col>

                        
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
              </v-window>
              <v-card-actions>
                <br />
              </v-card-actions>
              <v-card-actions class="mb-9">
                <v-spacer></v-spacer>

                <v-btn
                  class="text-capitalize"
                  :disabled="stepFormServiceOrder > 1 ? false : true"
                  variant="text"
                  @click="goToPreviousStep"
                >
                  Anterior
                </v-btn>
                <v-btn
                  class="text-capitalize"
                  v-if="
                    stepFormServiceOrder <
                    stepTitles.filter((step) => step.show)[
                      stepTitles.filter((step) => step.show).length - 1
                    ].id
                  "
                  color="primary"
                  variant="text"
                  @click="goToNextStep"
                >
                  Siguiente
                </v-btn>
                <v-btn
                  @click="saveForm"
                  class="text-capitalize"
                  v-else
                  color="primary"
                  variant="flat"
                >
                  Finalizar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <!-- <v-col cols="12" class="d-flex align-center justify-center"></v-col> -->
        </v-row>
      </v-col>
    </v-row>
    <v-overlay
      contained
      persistent
      :close-on-back="false"
      :close-on-content-click="false"
      class="align-center justify-center position-fixed"
      scrim="#FFF"
      :opacity="1"
      v-model="loadingPageOverlay"
    >
      <div class="text-center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <h4 class="text-h4 mt-4 font-weight-medium text-primary">Enviando formulario</h4>
        <p class="text-h6 font-weight-medium mt-2">
          Por favor, espera mientras procesamos tu solicitud.
        </p>
      </div>
    </v-overlay>
    <UserExperience></UserExperience>
    <DialogResponse
      @cancel="cancelDialogResponse"
      v-if="dialogResponse"
      :dialog="dialogResponse"
    ></DialogResponse>
  </v-container>
  <!-- <div style="    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    max-width: 100%;
    min-height: 100vh;
    min-height: 100dvh;
    position: relative;">
       
    </div> -->
</template>

<script>
import anime from "animejs/lib/anime.es.js";
import { useDisplay } from "vuetify";
import validationFields from "../functions/validationFields";
import LeftPanelAnimation1 from "./includes/LeftPanelAnimation1.vue";
import NavBarAnimation1 from "./includes/NavBarAnimation1.vue";
import UserExperience from "./includes/UserExperience.vue";
import axios from "axios";
import { useToast } from "vue-toastification";
import ErrorLayoutComponent from "@/components/error-request/ErrorLayoutComponent.vue";
import DialogResponse from "@/components/dialog/DialogResponse.vue";

export default {
  name: "FormEmployability",
  mixins: [validationFields],
  components: {
    LeftPanelAnimation1,
    NavBarAnimation1,
    UserExperience,
    DialogResponse,
  },

  setup() {
    const { mdAndDown } = useDisplay();

    return {
      isMdOrLower: mdAndDown,
    };
  },

  data() {
    return {
      rating_atractivo: null,
      rating_guia: null,
      rating_comida: null,

      form_relations_question: null,
      toast: useToast(),
      loadingPageOverlay: false,
      dialogResponse: null,
      formConfig: null,
      formItems: {
        itemsTouristAttractionNames: [],
        itemsDocumentType: [],
        itemsSustainabilityPracticesEnvironmental: [],
        itemsSustainabilityPracticesSocial: [],
        itemsGuideServiceUsage: [],
        itemsFoodPurchaseInside: [],
        field_d_authorize_sensitive_data_processing: [],
      },
      form: {
        field_a_managed_sites: null,
        items_type_doc: null,
        field_f_document_number: null,
        field_d_authorize_sensitive_data_processing: null,
        field_f_perception_cleanliness: null,
        field_f_perception_conservation: null,
        field_f_perception_hospitality: null,
        field_f_perception_ticket_purchase: null,
        field_f_perception_accessibility: null,
        field_f_perception_safety_transport: null,
        field_f_perception_price_quality: null,
        field_f_perception_road_conditions: null,
        field_f_perception_safety_elements: null,
        field_f_perception_internal_safety: null,
        field_f_sustainability_practices_environmental: null,
        field_f_sustainability_practices_social: null,
        field_f_guide_service_usage: null,
        field_f_guide_perception_presentation: null,
        field_f_guide_perception_vocabulary: null,
        field_f_guide_perception_knowledge: null,
        field_f_guide_perception_question_response: null,
        field_f_food_purchase_inside: null,
        field_f_food_perception_menu_variety: null,
        field_f_food_perception_consumed_menu: null,
        field_f_food_perception_service_time: null,
        field_f_food_perception_hygiene: null,
        field_f_food_perception_price_quality: null,
        field_f_food_perception_staff_attitude: null,
      },

      showTopShadow: false,
      showBottomShadow: true,
      ratings_atractivo: [
        {
          model: "field_f_perception_cleanliness",
          label: "Limpieza y aseo del atractivo",
          value: 0,
        },
        {
          model: "field_f_perception_conservation",
          label: "Conservación de los lugares visitados",
          value: 0,
        },
        { model: "field_f_perception_hospitality", label: "Hospitalidad", value: 0 },
        {
          model: "field_f_perception_ticket_purchase",
          label: "Facilidad para comprar las entradas",
          value: 0,
        },
        {
          model: "field_f_perception_accessibility",
          label: "Facilidad para llegar a los atractivos",
          value: 0,
        },
        {
          model: "field_f_perception_safety_transport",
          label: "Seguridad en el desplazamiento hacia el atractivo",
          value: 0,
        },
        {
          model: "field_f_perception_price_quality",
          label: "Relación calidad precio",
          value: 0,
        },
        {
          model: "field_f_perception_road_conditions",
          label: "Estado de las vías de acceso",
          value: 0,
        },
        {
          model: "field_f_perception_safety_elements",
          label: "Elementos de Seguridad (guía con botiquín, suministro de cascos, etc)",
          value: 0,
        },
        {
          model: "field_f_perception_internal_safety",
          label:
            "Seguridad al interior del atractivo (presencia de individuos o situaciones que puedan afectar la actividad)",
          value: 0,
        },
      ],
      ratings_guianza_turistica: [
        {
          model: "field_f_guide_perception_presentation",
          label: "Presentación personal e higiene",
          value: 0,
        },
        {
          model: "field_f_guide_perception_vocabulary",
          label: "Vocabulario utilizado y habilidad para expresar las ideas",
          value: 0,
        },
        {
          model: "field_f_guide_perception_knowledge",
          label: "Conocimiento y calidad de la información suministrada",
          value: 0,
        },
        {
          model: "field_f_guide_perception_question_response",
          label: "Respuesta a preguntas o dudas",
          value: 0,
        },
      ],
      rating_shop_food: [
        {
          model: "field_f_food_perception_menu_variety",
          label: "Variedad del Menú",
          value: 0,
        },
        {
          model: "field_f_food_perception_consumed_menu",
          label: "Menú consumido",
          value: 0,
        },
        {
          model: "field_f_food_perception_service_time",
          label: "Tiempo de atención",
          value: 0,
        },
        {
          model: "field_f_food_perception_hygiene",
          label: "Aseo e higiene de las instalaciones gastronómicas",
          value: 0,
        },
        {
          model: "field_f_food_perception_price_quality",
          label: "Relación calidad precio",
          value: 0,
        },
        {
          model: "field_f_food_perception_staff_attitude",
          label: "Trato del personal del restaurante",
          value: 0,
        },
      ],
      guianzaTuristica: 0,
      shopingFood: 0,

      isMobile: false,

      num: 120,
      vw: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
      vh: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),

      stepFormServiceOrder: 1,
      marketplace: "",
      stepTitles: [
        {
          group: 1,
          label: "Sitio turístico",
          id: 1,
          title: "Información general",
          show: true,
        },
        {
          group: 2,
          label: "Percepción del visitante",
          id: 2,
          title: "Sitio turístico",
          show: true,
        },
        {
          group: 2,
          label: "Percepción del visitante",
          id: 3,
          title: "Sostenibilidad ambiental",
          show: true,
        },
        {
          group: 2,
          label: "Percepción del visitante",
          id: 4,
          title: "Sostenibilidad social",
          show: true,
        },
        {
          group: 2,
          id: 5,
          label: "Percepción del visitante",
          title: "Servicio de guianza turística",
          show: true,
        },
        {
          group: 2,
          id: 6,
          label: "Percepción del visitante",
          title: "Percepción de la guianza turística",
          show: true,
        },
        {
          group: 2,
          id: 7,
          label: "Percepción del visitante",
          title: "Compra de comidas",
          show: true,
        },
        {
          group: 2,
          id: 8,
          label: "Percepción del visitante",
          title: "Percepción de comidas",
          show: false,
        },
      ],

      showIndicator: true, // Initially show the indicator
    };
  },
  watch: {
    stepFormServiceOrder(newVal) {
      if (newVal < this.stepTitles.length) {
        this.scrollToItem(newVal);
      }
    },
    "form.field_f_perception_cleanliness"() {
      this.checkValueRatingAtractivo();
    },
    "form.field_f_perception_conservation"() {
      this.checkValueRatingAtractivo();
    },
    "form.field_f_perception_hospitality"() {
      this.checkValueRatingAtractivo();
    },
    "form.field_f_perception_ticket_purchase"() {
      this.checkValueRatingAtractivo();
    },
    "form.field_f_perception_accessibility"() {
      this.checkValueRatingAtractivo();
    },
    "form.field_f_perception_safety_transport"() {
      this.checkValueRatingAtractivo();
    },
    "form.field_f_perception_price_quality"() {
      this.checkValueRatingAtractivo();
    },
    "form.field_f_perception_road_conditions"() {
      this.checkValueRatingAtractivo();
    },
    "form.field_f_perception_safety_elements"() {
      this.checkValueRatingAtractivo();
    },
    "form.field_f_perception_internal_safety"() {
      this.checkValueRatingAtractivo();
    },

    "form.field_f_guide_perception_presentation"(){
      this.checkValueRatingGuia();
    },
    "form.field_f_guide_perception_vocabulary"(){
      this.checkValueRatingGuia();
    },
    "form.field_f_guide_perception_knowledge"(){
      this.checkValueRatingGuia();
    },
    "form.field_f_guide_perception_question_response"(){
      this.checkValueRatingGuia();
    },

    "form.field_f_food_perception_menu_variety"(){
      this.checkValueRatingComida();
    },
    "form.field_f_food_perception_consumed_menu"(){
      this.checkValueRatingComida();
    },
    "form.field_f_food_perception_service_time"(){
      this.checkValueRatingComida();
    },
    "form.field_f_food_perception_hygiene"(){
      this.checkValueRatingComida();
    },
    "form.field_f_food_perception_price_quality"(){
      this.checkValueRatingComida();
    },
    "form.field_f_food_perception_staff_attitude"(){
      this.checkValueRatingComida();
    },



    "form.field_f_food_purchase_inside"() {
      this.form.field_f_food_perception_menu_variety = null;
      this.form.field_f_food_perception_consumed_menu = null;
      this.form.field_f_food_perception_service_time = null;
      this.form.field_f_food_perception_hygiene = null;
      this.form.field_f_food_perception_price_quality = null;
      this.form.field_f_food_perception_staff_attitude = null;

      if (this.form.field_f_food_purchase_inside == 520) {
        const form8 = this.stepTitles.findIndex((step) => step.id === 8);
        this.stepTitles[form8].show = true;
      } else {
        const form8 = this.stepTitles.findIndex((step) => step.id === 8);
        this.stepTitles[form8].show = false;
      }
    },
  },
  methods: {
    checkValueRatingAtractivo() {
      if (
        this.form.field_f_perception_cleanliness == null ||
        this.form.field_f_perception_conservation == null ||
        this.form.field_f_perception_hospitality == null ||
        this.form.field_f_perception_ticket_purchase == null ||
        this.form.field_f_perception_accessibility == null ||
        this.form.field_f_perception_safety_transport == null ||
        this.form.field_f_perception_price_quality == null ||
        this.form.field_f_perception_road_conditions == null ||
        this.form.field_f_perception_safety_elements == null ||
        this.form.field_f_perception_internal_safety == null ||
        this.form.field_f_perception_cleanliness == "" ||
        this.form.field_f_perception_conservation == "" ||
        this.form.field_f_perception_hospitality == "" ||
        this.form.field_f_perception_ticket_purchase == "" ||
        this.form.field_f_perception_accessibility == "" ||
        this.form.field_f_perception_safety_transport == "" ||
        this.form.field_f_perception_price_quality == "" ||
        this.form.field_f_perception_road_conditions == "" ||
        this.form.field_f_perception_safety_elements == "" ||
        this.form.field_f_perception_internal_safety == ""
      ) {
        this.rating_atractivo = null;
      } else {
        this.rating_atractivo = 1;
      }
    },
    checkValueRatingGuia() {
      if (
        this.form.field_f_guide_perception_presentation == null ||
        this.form.field_f_guide_perception_vocabulary == null ||
        this.form.field_f_guide_perception_knowledge == null ||
        this.form.field_f_guide_perception_question_response == null ||
        this.form.field_f_guide_perception_presentation == "" ||
        this.form.field_f_guide_perception_vocabulary == "" ||
        this.form.field_f_guide_perception_knowledge == "" ||
        this.form.field_f_guide_perception_question_response == ""
      ) {
        this.rating_guia = null;
      } else {
        this.rating_guia = 1;
      }
    },

    checkValueRatingComida(){
      if (
        this.form.field_f_food_perception_menu_variety == null ||
        this.form.field_f_food_perception_consumed_menu == null ||
        this.form.field_f_food_perception_service_time == null ||
        this.form.field_f_food_perception_hygiene == null ||
        this.form.field_f_food_perception_price_quality == null ||
        this.form.field_f_food_perception_staff_attitude == null ||
        this.form.field_f_food_perception_menu_variety == "" ||
        this.form.field_f_food_perception_consumed_menu == "" ||
        this.form.field_f_food_perception_service_time == "" ||
        this.form.field_f_food_perception_hygiene == "" ||
        this.form.field_f_food_perception_price_quality == "" ||
        this.form.field_f_food_perception_staff_attitude == ""
      ) {
        this.rating_comida = null;
      } else {
        this.rating_comida = 1;
      }
    },

    async saveForm() {
      this.loadingPageOverlay = true;
      let otherThis = this;
      await setTimeout(async function () {
        const allValid = await otherThis.validateAllSteps();

        if (allValid) {
          otherThis.formResponseStore();
        } else {
          otherThis.loadingPageOverlay = false;
          console.log("Hay errores en algunos de los formularios.");
        }
      }, 1000);
    },
    async validateAllSteps() {
      let allValid = true;

      // Filtramos los pasos visibles y recorremos su longitud
      const visibleSteps = this.stepTitles.filter((step) => step.show);

      for (let i = 0; i < visibleSteps.length; i++) {
        this.stepFormServiceOrder = visibleSteps[i].id; // Navegar al paso correspondiente
        await this.$nextTick(); // Esperar a que el DOM se actualice y el formulario se monte

        const formRef = this.$refs[`formStep${visibleSteps[i].id}`];

        if (formRef && formRef.validate) {
          const { valid } = await formRef.validate();

          if (!valid) {
            allValid = false;

            // Resaltar los campos con errores
            this.$nextTick(() => {
              const firstErrorField = formRef.$el.querySelector(".v-input--error");
              if (firstErrorField) {
                firstErrorField.scrollIntoView({ behavior: "smooth" });
              }
            });

            break; // Detener la validación en el primer error encontrado
          }
        }
      }

      return allValid;
    },
    formResponseStore() {
      let url = process.env.VUE_APP_API_URL;

      axios
        .post(url + "/form-responses/store", {
          form: this.form,
          form_relations_question: this.form_relations_question,
          form_id: 7,
          season_id: this.season_id,
        })
        .then((response) => {
          this.dialogResponse = {
            title: "¡Formulario enviado con éxito!",
            message:
              "Tus respuestas han sido registradas correctamente. Gracias por tomarte el tiempo de completar el formulario. Puedes estar seguro de que tus datos han sido guardados de manera segura.",
            type: "success",
            color: "secondary",
            icon: "mdi-check",
            show: true,
            redirect: false,
          };
          console.log(response);
          this.loadingPageOverlay = false;
          //resetear todo el componente
          this.resetFormFields();
          this.stepFormServiceOrder = 1;
        })
        .catch((error) => {
          this.loadingPageOverlay = false;
          if (
            error &&
            error.response &&
            error.response.status &&
            error.response.status == 422
          ) {
            //ERROR RETORNADO POR LAS VALIDACIONES
            this.toast.error({
              component: ErrorLayoutComponent,
              props: {
                errorMessage: error.response.data,
              },
              timeout: 8000,
            });
          } else {
            this.dialogResponse = {
              title: "Error al enviar el formulario",
              message:
                "Ocurrió un problema al intentar guardar tus respuestas. Por favor, revisa los campos y vuelve a intentarlo. Si el problema persiste, contacta al soporte técnico.",
              type: "success",
              color: "red-app",
              icon: "mdi-cloud-alert",
              show: true,
              redirect: false,
            };
          }

          console.log(error);
        });
    },
    resetFormFields() {
      this.form = {
        field_a_managed_sites: null,
        items_type_doc: null,
        field_f_document_number: null,
        field_d_authorize_sensitive_data_processing: null,
        field_f_perception_cleanliness: null,
        field_f_perception_conservation: null,
        field_f_perception_hospitality: null,
        field_f_perception_ticket_purchase: null,
        field_f_perception_accessibility: null,
        field_f_perception_safety_transport: null,
        field_f_perception_price_quality: null,
        field_f_perception_road_conditions: null,
        field_f_perception_safety_elements: null,
        field_f_perception_internal_safety: null,
        field_f_sustainability_practices_environmental: null,
        field_f_sustainability_practices_social: null,
        field_f_guide_service_usage: null,
        field_f_guide_perception_presentation: null,
        field_f_guide_perception_vocabulary: null,
        field_f_guide_perception_knowledge: null,
        field_f_guide_perception_question_response: null,
        field_f_food_purchase_inside: null,
        field_f_food_perception_menu_variety: null,
        field_f_food_perception_consumed_menu: null,
        field_f_food_perception_service_time: null,
        field_f_food_perception_hygiene: null,
        field_f_food_perception_price_quality: null,
        field_f_food_perception_staff_attitude: null,
      };

      this.stepFormServiceOrder = 1;
    },
   
    async getInfoForm() {
      try {
        let url = process.env.VUE_APP_API_URL;
        const token = this.$store.state.token;

        axios
          .get(url + `/forms/show/7`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            this.formConfig = response.data.form_config;
            this.form_relations_question = response.data.form_relations_question;

            if (this.formConfig && this.formConfig.questions) {
              const field_a_managed_sites = this.formConfig.questions.find(
                (q) => q.v_model === "field_a_managed_sites"
              );
              if (field_a_managed_sites) {
                this.formItems.itemsTouristAttractionNames =
                  field_a_managed_sites.options;
              }

              const items_type_doc = this.formConfig.questions.find(
                (q) => q.v_model === "items_type_doc"
              );
              if (items_type_doc) {
                this.formItems.itemsDocumentType = items_type_doc.options;
              }

              const field_d_authorize_sensitive_data_processing = this.formConfig.questions.find(
                (q) => q.v_model === "field_d_authorize_sensitive_data_processing"
              );
              if (field_d_authorize_sensitive_data_processing) {
                this.formItems.field_d_authorize_sensitive_data_processing = field_d_authorize_sensitive_data_processing.options;
              }

              const field_f_sustainability_practices_environmental = this.formConfig.questions.find(
                (q) => q.v_model === "field_f_sustainability_practices_environmental"
              );
              if (field_f_sustainability_practices_environmental) {
                this.formItems.itemsSustainabilityPracticesEnvironmental =
                  field_f_sustainability_practices_environmental.options;
              }

              const field_f_sustainability_practices_social = this.formConfig.questions.find(
                (q) => q.v_model === "field_f_sustainability_practices_social"
              );
              if (field_f_sustainability_practices_social) {
                this.formItems.itemsSustainabilityPracticesSocial =
                  field_f_sustainability_practices_social.options;
              }

              const field_f_guide_service_usage = this.formConfig.questions.find(
                (q) => q.v_model === "field_f_guide_service_usage"
              );
              if (field_f_guide_service_usage) {
                this.formItems.itemsGuideServiceUsage =
                  field_f_guide_service_usage.options;
              }

              const field_f_food_purchase_inside = this.formConfig.questions.find(
                (q) => q.v_model === "field_f_food_purchase_inside"
              );
              if (field_f_food_purchase_inside) {
                this.formItems.itemsFoodPurchaseInside =
                  field_f_food_purchase_inside.options;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      } finally {
        this.showDeleteConfirmation = false;
      }
    },

    handleScroll() {
      const container = this.$refs.scrollContainer;
      if (container) {
        this.showIndicator = container.scrollTop === 0; // Muestra el indicador si está en la parte superior

        // Control de las sombras
        this.showTopShadow = container.scrollTop > 0;
        this.showBottomShadow =
          container.scrollTop < container.scrollHeight - container.clientHeight;
      }
    },
    scrollToItem(index) {
      const item = this.$refs["timelineItem" + index][0]; // Acceso directo al primer elemento
      if (item) {
        const container = this.$refs.scrollContainer;
        const itemRect = item.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        // Ajuste adicional para el margen superior
        const offsetTop = itemRect.top + container.scrollTop - containerRect.top - 300; // El valor negativo asegura que el elemento no quede oculto

        container.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
        console.log(
          "Item Rect:",
          itemRect,
          "Container Rect:",
          containerRect,
          "Offset Top:",
          offsetTop
        );
      }
    },

    toggleActividadesCamara(activity) {
      const index = this.selectActividadesCamara.indexOf(activity);
      if (index === -1) {
        this.selectActividadesCamara.push(activity);
      } else {
        this.selectActividadesCamara.splice(index, 1);
      }
    },

    toggleActividadesOfrecidas(activity) {
      const index = this.selectActividadesOfrecidas.indexOf(activity);
      if (index === -1) {
        this.selectActividadesOfrecidas.push(activity);
      } else {
        this.selectActividadesOfrecidas.splice(index, 1);
      }
    },

    toggleServicioAdicional(activity) {
      const index = this.selectServicioAdicional.indexOf(activity);
      if (index === -1) {
        this.selectServicioAdicional.push(activity);
      } else {
        this.selectServicioAdicional.splice(index, 1);
      }
    },

    toggleGremiosAsociaciones(activity) {
      const index = this.selectGremiosAsociaciones.indexOf(activity);
      if (index === -1) {
        this.selectGremiosAsociaciones.push(activity);
      } else {
        this.selectGremiosAsociaciones.splice(index, 1);
      }
    },

    starryNight() {
      anime({
        targets: ["#sky .star"],
        opacity: [
          { duration: 700, value: "0" },
          { duration: 700, value: "1" },
        ],
        easing: "linear",
        loop: true,
        delay: (el, i) => 50 * i,
      });
    },
    shootingStars() {
      anime({
        targets: ["#shootingstars .wish"],
        easing: "linear",
        loop: true,
        delay: (el, i) => 1000 * i,
        opacity: [{ duration: 700, value: "1" }],
        width: [{ value: "150px" }, { value: "0px" }],
        translateX: 350,
      });
    },
    randomRadius() {
      return Math.random() * 0.7 + 0.6;
    },
    getRandomX() {
      return Math.floor(Math.random() * Math.floor(this.vw)).toString();
    },
    getRandomY() {
      return Math.floor(Math.random() * Math.floor(this.vh)).toString();
    },

    checkMobile() {
      this.isMobile = window.innerWidth < 600;
    },

    goToPreviousStep() {
      if (this.previousStep) {
        this.stepFormServiceOrder = this.previousStep.id;
      }
    },
    async goToNextStep() {
      if (this.nextStep) {
        //VALIDAR FORMULARIO ACTUAL EN REF formStep1,2,3... etc
        const { valid } = await this.$refs[
          "formStep" + this.stepFormServiceOrder
        ].validate();
        if (valid) {
          this.stepFormServiceOrder = this.nextStep.id;
        }
      }
    },
    showGroupLabel(item, index) {
      // Verifica si es el primer paso del grupo visible
      return index === 0 || item.group !== this.visibleSteps[index - 1].group;
    },
    async cancelDialogResponse() {
      this.dialogResponse = null;
    },
  },

  mounted() {
    this.starryNight();
    this.shootingStars();

    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);

    this.getInfoForm();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  },

  computed: {
    visibleSteps() {
      // Filtrar los pasos visibles
      return this.stepTitles.filter((item) => item.show);
    },
    currentTitle() {
      const step = this.stepTitles[this.stepFormServiceOrder - 1];
      // Busca el label del grupo al que pertenece el step actual
      const groupLabel = this.stepTitles.find(
        (item) => item.group === step.group && item.label
      )?.label;

      return {
        title: step.title,
        label: groupLabel || "", // Si no se encuentra el label, se asigna una cadena vacía
      };
    },

    nextStep() {
      const currentIndex = this.stepTitles.findIndex(
        (step) => step.id === this.stepFormServiceOrder
      );
      return this.stepTitles.slice(currentIndex + 1).find((step) => step.show) || null;
    },

    previousStep() {
      const currentIndex = this.stepTitles.findIndex(
        (step) => step.id === this.stepFormServiceOrder
      );
      return (
        this.stepTitles
          .slice(0, currentIndex)
          .reverse()
          .find((step) => step.show) || null
      );
    },
  },
};
</script>
<style>
.v-field__input {
  padding-top: 8px;
}

/* #sky {
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    margin: 0;
    padding: 0;
} */

/* #shootingstars {
    margin: 0;
    padding: 0;
    width: 150vh;
    height: 100vw;
    position: fixed;
    overflow: hidden;
    transform: translatex(calc(50vw - 50%)) translatey(calc(50vh - 50%)) rotate(120deg);
} */

/* .wish {
    height: 2px;
    top: 300px;
    width: 100px;
    margin: 0;
    opacity: 0;
    padding: 0;
    background-color: white;
    position: absolute;
    background: linear-gradient(-45deg, white, rgba(0, 0, 255, 0));
    filter: drop-shadow(0 0 6px white);
    overflow: hidden;
} */

.v-row {
  margin: 0;
}
</style>

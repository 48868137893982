<template>
  <v-navigation-drawer
    temporary
    touchless
    v-model="drawerUser"
    width="400"
    location="right"
    persistent
    style="height: 100%; top: 0; z-index: 1006; position: fixed"
  >
    <template v-slot:prepend>
      <v-list-item v-if="!loadingGetData">
        <v-list-item-action>
          <v-card-title class="font-weight-bold text-h5">Detalle Usuario</v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="close"
            icon
            size="x-small"
            variant="outlined"
            style="border: 2px solid"
            ><v-icon size="20">mdi-close</v-icon></v-btn
          >
        </v-list-item-action>
      </v-list-item>
    </template>

    <v-card v-if="!loadingGetData && infoUser" elevation="0" color="transparent">
      <v-card-text class="pt-0">
        <v-row>
          <v-col xs="12" sm="12" md="12" cols="12" class="pb-1">
            <p class="font-weight-bold mb-1 text-body-1">Nombre Completo</p>
            <p class="text-body-1 d-inline-block">{{ infoUser.name }}</p>
          </v-col>
          <v-col xs="12" sm="12" md="12" cols="12" class="pb-1">
            <p class="font-weight-bold mb-1 text-body-1">Correo Electrónico</p>
            <p class="text-body-1 d-inline-block">{{ infoUser.email }}</p>
          </v-col>
          <v-col xs="12" sm="12" md="12" cols="12" class="pb-1">
            <p class="font-weight-bold mb-1 text-body-1">Teléfono</p>
            <p class="text-body-1 d-inline-block">
              {{
                infoUser.response && infoUser.response.details
                  ? getResponseUser(infoUser.response.details, 7)
                  : null
              }}
            </p>
          </v-col>
          <v-col xs="12" sm="12" md="12" cols="12" class="pb-1">
            <p class="font-weight-bold mb-1 text-body-1">Rol</p>
            <p class="text-body-1 d-inline-block">
              {{
                infoUser.roles && infoUser.roles[0]
                  ? infoUser.roles[0].name
                  : "Sin definir"
              }}
            </p>
          </v-col>
          <v-col xs="12" sm="12" md="12" cols="12" class="pb-1">
            <p class="font-weight-bold mb-1 text-body-1">
              Nombre de la Empresa o Entidad
            </p>
            <p class="text-body-1 d-inline-block">
              {{
                infoUser.response && infoUser.response.details
                  ? getResponseUser(infoUser.response.details, 16)
                  : null
              }}
            </p>
          </v-col>
          <v-col xs="12" sm="12" md="12" cols="12" class="pb-1">
            <p class="font-weight-bold mb-1 text-body-1">Nit</p>
            <p class="text-body-1 d-inline-block">
              {{
                infoUser.response && infoUser.response.details
                  ? getResponseUser(infoUser.response.details, 14)
                  : null
              }}
            </p>
          </v-col>
          <v-col xs="12" sm="12" md="12" cols="12" class="pb-1">
            <p class="font-weight-bold mb-1 text-body-1">Municipio</p>
            <p class="text-body-1 d-inline-block">
              {{
                infoUser.response && infoUser.response.details
                  ? getResponseUser(infoUser.response.details, 17)
                  : null
              }}
            </p>
          </v-col>
          <v-col xs="12" sm="12" md="12" cols="12" class="pb-1">
            <p class="font-weight-bold mb-1 text-body-1">Dirección</p>
            <p class="text-body-1 d-inline-block">
              {{
                infoUser.response && infoUser.response.details
                  ? getResponseUser(infoUser.response.details, 18)
                  : null
              }}
            </p>
          </v-col>
          <v-col
            v-if="
              infoUser.response &&
              infoUser.response.details &&
              getResponseUser(infoUser.response.details, 25)
            "
            xs="12"
            sm="12"
            md="12"
            cols="12"
            class="pb-1"
          >
            <p class="font-weight-bold mb-1 text-body-1">
              Registro Nacional de Turismo (RNT)
            </p>
            <p class="text-body-1 d-inline-block">
              {{
                infoUser.response && infoUser.response.details
                  ? getResponseUser(infoUser.response.details, 24)
                  : null
              }}
            </p>
          </v-col>
          <v-col
            v-if="
              infoUser.response &&
              infoUser.response.details &&
              getResponseUser(infoUser.response.details, 25)
            "
            xs="12"
            sm="12"
            md="12"
            cols="12"
            class="pb-1"
          >
            <p class="font-weight-bold mb-1 text-body-1">Categoría de RNT</p>
            <p class="text-body-1 d-inline-block">
              {{
                infoUser.response && infoUser.response.details
                  ? getResponseUser(infoUser.response.details, 25)
                  : null
              }}
            </p>
          </v-col>
          <v-col
            v-if="
              infoUser.response &&
              infoUser.response.details &&
              getResponseUser(infoUser.response.details, 25)
            "
            xs="12"
            sm="12"
            md="12"
            cols="12"
            class="pb-1"
          >
            <p class="font-weight-bold mb-1 text-body-1">
              Actividades económicas registradas en Cámara de Comercio
            </p>
            <p class="text-body-1 d-inline-block">
              {{
                infoUser.response && infoUser.response.details
                  ? getResponseUser(infoUser.response.details, 27)
                  : null
              }}
            </p>
          </v-col>

          <v-divider class="mt-5 mb-3"></v-divider>
        </v-row>
        <v-form ref="formStatus">
          <v-row>
            <v-col xs="12" sm="12" md="12" cols="12" class="pb-1 align-center">
              <div class="d-md-flex">
                <p class="font-weight-bold mb-1 text-body-1">Asignar Rol</p>
                <v-tooltip location="bottom" content-class="bg-secondary">
                  <template v-slot:activator="{ props }">
                    <v-icon end v-bind="props" color="secondary">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span
                    >Tenga en cuenta la información del usuario y <br />
                    asigne el rol que más se adapte para su perfil.</span
                  >
                </v-tooltip>
              </div>
              <div class="ml-auto">
                <v-select
                  :menu-props="{ 'max-width': '100%' }"
                  :items="[
                    { id: 6, text: 'Agencia de viaje' },
                    { id: 7, text: 'Agencia de viajes + alojamiento' },
                    { id: 2, text: 'Alojamiento' },
                    { id: 1, text: 'Atractivo' },
                    { id: 8, text: 'Atractivo + Alojamiento' },
                    { id: 5, text: 'ICANH' },
                    { id: 4, text: 'Policía de Turismo' },
                  ]"
                  :rules="[validations.required]"
                  item-value="id"
                  item-title="text"
                  density="compact"
                  variant="outlined"
                  v-model="formUser.role_id"
                >
                </v-select>
              </div>
            </v-col>
            <v-col xs="12" sm="12" md="12" cols="12" class="pb-1 align-center">
              <div class="d-md-flex">
                <p class="font-weight-bold mb-1 text-body-1">Estado del usuario</p>

                <v-chip
                  label
                  size="small"
                  :color="
                    infoUser.status == 'Verificado'
                      ? 'green-app-2'
                      : infoUser.status == 'Registrado'
                      ? 'primary'
                      : infoUser.status == 'Rechazado'
                      ? 'red-app'
                      : 'grey-app-2'
                  "
                  variant="flat"
                  class="d-flex justify-center font-weight-bold ml-auto"
                  :class="{ 'text-white': infoUser.status == 'Verificado' }"
                >
                  {{ infoUser.status }}
                </v-chip>
              </div>

              <div class="ml-auto">
                <v-radio-group
                  v-model="formUser.status"
                  color="secondary"
                  :rules="[validations.required]"
                >
                  <v-radio label="Verificado" value="Verificado"></v-radio>
                  <v-radio label="Rechazado" value="Rechazado"></v-radio>
                </v-radio-group>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <template v-slot:append>
      <div v-if="!loadingGetData" class="px-8 pb-9 pt-2">
        <v-btn
          rounded="lg"
          :disabled="loading"
          :loading="loading"
          class="text-capitalize mb-3"
          color="primary"
          @click="updateStatusUser"
          block
        >
          Guardar
        </v-btn>
        <v-btn rounded="lg" @click="close" class="text-capitalize" variant="text" block>
          Cancelar
        </v-btn>
      </div>
    </template>

    <v-overlay
      v-model="loadingGetData"
      contained
      persistent
      :close-on-back="false"
      :close-on-content-click="false"
      class="align-center justify-center"
      scrim="#FFF"
      :opacity="1"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
  </v-navigation-drawer>
</template>

<script>
import axios from "axios";
import validationFields from "@/views/functions/validationFields";
import ErrorLayoutComponent from "@/components/error-request/ErrorLayoutComponent.vue";
import { useToast } from "vue-toastification";

export default {
  name: "DetailUser",
  mixins: [validationFields],

  props: {
    userId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      toast: useToast(),
      loadingGetData: true,
      loading: false,
      drawerUser: true,
      visible: false,
      infoUser: null,
      formUser: {
        status: null,
        role_id: null,
      },
    };
  },

  watch: {
    userId: {
      immediate: true,
      handler(newValue) {
        this.getDataUser(newValue);
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    close() {
      this.$emit("close");
    },
    getResponseUser(response_details, questionId) {
      const detail = response_details.find((item) => item.question_id == questionId);

      if (detail && detail.question_type && detail.question_type == "multiple_choice") {
        //LA RESPUESTA SE ENCUENTRA EN selected_options_detail Y TOCA RECORRERLO PARA OBTENER EL TEXTO DE LA RESPUESTA QUE ES text
        return detail.selected_options_detail.map((item) => item.text).join(", ");
      } else if (
        detail &&
        detail.question_type &&
        detail.question_type == "multiple_text"
      ) {
        //LA RESPUESTA SE ENCUENTRA EN selected_options Y ES UN ARRAY DE RESPUESTAS, DE STRINGS
        return detail.selected_options.join(", ");
      } else if (detail && detail.question_type && detail.question_type == "text") {
        //LA RESPUESTA SE ENCUENTRA EN selected_option Y ES UN STRING
        return detail.text_response;
      } else if (
        detail &&
        detail.question_type &&
        detail.question_type == "single_choice"
      ) {
        return detail.selected_options_detail.text;
      } else {
        return null;
      }
    },
    async updateStatusUser() {
      const { valid } = await this.$refs.formStatus.validate();

      if (valid) {
        this.loading = true;
        let url = process.env.VUE_APP_API_URL;
        let data = {
          status: this.formUser.status,
          role_id: this.formUser.role_id,
        };

        console.log("asdadasd", data);

        axios
          .put(url + "/users/update-status/" + this.userId, data)
          .then((response) => {
            this.loading = false;
            console.log(response);

            this.$emit("update");
            this.close();
          })
          .catch((error) => {
            this.loading = false;
            if (error.response.status == 422) {
              //ERROR RETORNADO POR LAS VALIDACIONES
              this.toast.error({
                component: ErrorLayoutComponent,
                props: {
                  errorMessage: error.response.data,
                },
                timeout: 8000,
              });
            }
            console.log(error);
          });
      }
    },

    async getDataUser(userId) {
      this.loadingGetData = true;
      let url = process.env.VUE_APP_API_URL;

      axios
        .get(url + "/users/show/" + userId)
        .then((response) => {
          console.log(response);
          this.infoUser = response.data;
          if (this.infoUser.status == "Verificado") {
            this.formUser.status = "Verificado";
          } else if (this.infoUser.status == "Rechazado") {
            this.formUser.status = "Rechazado";
          } else {
            this.formUser.status = null;
          }

          if (
            this.infoUser &&
            this.infoUser.roles &&
            this.infoUser.roles[0] &&
            this.infoUser.roles[0].id
          ) {
            this.formUser.role_id = this.infoUser.roles[0].id;
          }

          this.loadingGetData = false;
        })
        .catch((error) => {
          this.loadingGetData = false;
          console.log(error);
        });
    },
  },
};
</script>

<template>
  <div class="d-flex align-center">
    <v-app-bar-nav-icon
      class="mr-3"
      variant="text"
      @click.stop="toggleDrawer"
    ></v-app-bar-nav-icon>
    <div v-if="showTitle">
      <span
        class="text-h4 font-weight-bold"
        :class="{ 'animate__animated animate__bounceIn': showAnimationTitle }"
      >
        {{ $route.meta.title }}
      </span>
      <br />
      <span>
        <span
          class="text-caption text-grey"
          :class="{
            'animate__animated animate__bounceIn animate__delay-1s': showAnimationTitle,
          }"
        >
          {{ $route.meta.subTitle }}
        </span>
      </span>
      <br />
    </div>
    <v-spacer></v-spacer>
    <div style="width: 300px" class="text-subtitle-2 d-none align-center mr-0 d-md-flex">
      <span class="text-right mr-2" style="width: 100%; height: 20px">
        {{ formattedDate }}
      </span>

      <!-- <v-btn readonly icon variant="tonal" color="grey-darken-1" size="small" class="mr-4">
        <v-icon size="25" color="grey-darken-3">mdi-calendar-clock-outline</v-icon>
      </v-btn> -->

      <!-- <v-avatar class="mr-4 bg-grey-lighten-3">
        <v-icon size="25" color="grey-darken-3">  </v-icon>
      </v-avatar> -->
    </div>

    <v-menu
      attach
      :close-on-content-click="false"
      v-model="menuNotifications"
      ref="menuNotifications"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          icon
          variant="tonal"
          color="grey-darken-1"
          size="small"
          class="mr-4"
        >
          <!-- <v-avatar
          
          class="mr-4 d-none bg-grey-lighten-3 d-md-flex overflow-visible avatar-notifications"
        > -->
          <v-badge
            v-if="totalNotifications > 0"
            transition="v-expand-transition"
            location="top end"
            color="red-app"
            offset-x="-3"
            offset-y="-3"
            :content="totalNotifications"
            ><v-icon size="25" color="grey-darken-3"> mdi-bell-outline </v-icon>
          </v-badge>
          <v-icon size="25" color="grey-darken-3" v-else> mdi-bell-outline </v-icon>
          <!-- </v-avatar> -->
        </v-btn>
      </template>
      <v-card rounded="lg" v-if="loadingGetData" width="350">
        <v-card-text class="d-flex justify-center py-8">
          <v-progress-circular
            color="primary"
            indeterminate
            :size="40"
          ></v-progress-circular>
        </v-card-text>
      </v-card>
      <v-card rounded="lg" v-else>
        <v-card-title class="d-flex py-4">
          Tus Notificaciones
          <v-spacer></v-spacer>
          <!-- <v-btn color="primary" icon size="x-small" variant="text">
            <v-icon size="20">mdi-dots-horizontal</v-icon>
          </v-btn> -->
        </v-card-title>
        <div
          class="d-flex align-center justify-center bg-grey-lighten-4 py-0 rounded-lg mx-3"
        >
          <v-btn
            @click="filterNotification = 'General'"
            style="flex-grow: 1"
            height="28"
            rounded="lg"
            class="mx-0 text-caption text-capitalize"
            :variant="filterNotification === 'General' ? 'outlined' : 'text'"
            :class="
              filterNotification === 'General'
                ? 'bg-white no-hover select-option-filter'
                : 'no-select-filter'
            "
            value="General"
          >
            <v-badge
              v-if="totalNotifications > 0"
              color="red-app"
              :content="totalNotifications"
              inline
            >
              <span> General &nbsp;</span>
            </v-badge>
            <span v-else>General</span>
          </v-btn>
          <v-btn
            @click="filterNotification = 'Encuestas'"
            style="flex-grow: 1"
            height="28"
            rounded="lg"
            class="mx-0 text-caption text-capitalize"
            :variant="filterNotification === 'Encuestas' ? 'outlined' : 'text'"
            :class="
              filterNotification === 'Encuestas'
                ? 'bg-white no-hover select-option-filter'
                : 'no-select-filter'
            "
            value="Encuestas"
          >
            Encuestas
          </v-btn>
          <v-btn
            @click="filterNotification = 'Inbox'"
            style="flex-grow: 1"
            height="28"
            rounded="lg"
            class="mx-0 text-caption text-capitalize"
            :variant="filterNotification === 'Inbox' ? 'outlined' : 'text'"
            :class="
              filterNotification === 'Inbox'
                ? 'bg-white no-hover select-option-filter'
                : 'no-select-filter'
            "
            value="Inbox"
          >
            Inbox
          </v-btn>
        </div>

        <v-card-text class="px-0">
          <v-list max-width="400" lines="three">
            <template
              v-for="(item, index) in itemsNotifications.slice(0, 5)"
              :key="index"
            >
              <v-list-item
                v-if="item.message"
                class="py-2 px-5"
                :class="{ 'bg-grey-lighten-3': item.status == 'unread' }"
              >
                <template v-slot:prepend>
                  <v-avatar color="transparent">
                    <v-img
                      v-if="item.type == 'diligenciar-formulario'"
                      src="@/assets/img/admin/diligenciar-formulario.png"
                    >
                    </v-img>
                  </v-avatar>
                </template>
                <v-list-item-title
                  v-html="item.title"
                  class="text-caption font-weight-bold text-capitalize"
                >
                </v-list-item-title>
                <v-list-item-subtitle v-html="item.message" class="text-caption">
                </v-list-item-subtitle>
                <v-list-item-title class="text-caption">
                  <span
                    class="text-disabled text-primary"
                    v-html="item.fecha_string"
                  ></span>
                  <!-- • 
                  <span class="text-disabled text-primary" > {{  item.type == 'diligenciar-formulario' ? 'Diligenciar Fomulario' : null }} </span> -->
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-card-text>

        <v-card-actions class="py-3">
          <v-btn
            rounded="lg"
            block
            color="primary"
            variant="tonal"
            class="text-capitalize"
            @click="menuNotifications = false"
            :to="$store.state.user.roles[0] == 2 ? 'notificaciones' : 'notificaciones'"
          >
            Ver Notificaciones Anteriores
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <v-list-item class="d-none d-md-flex" lines="two" append-avatar="">
      <template v-slot:append>
        <v-avatar role="button" id="menu-activator" color="grey-lighten-1">

          <v-img v-if="$store && $store.state && $store.state.user && $store.state.user.profile_photo" :src="$store.state.user.profile_photo"></v-img>
          <v-icon v-else color="white">mdi-account</v-icon>



        </v-avatar>
      </template>

      <v-list-item-title>{{ $store.state.user.name }}</v-list-item-title>
      <v-list-item-subtitle>{{ nameRole }}</v-list-item-subtitle>
    </v-list-item>
  </div>

  <v-menu
    transition="slide-y-transition"
    activator="#menu-activator"
    bottom
    content-class="my-menu"
  >
  <v-card rounded="lg" width="250">
  <v-list class="" density="compact">
      
      <v-list-item :to="item.to" v-for="(item, i) in itemsMenu" :key="i">
        <template v-slot:prepend>
          <v-icon>{{ item.icon }}</v-icon>
      </template>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-card>
    
  </v-menu>
</template>

<script>
import { ref } from "vue";
import moment from "moment";
import { useDisplay } from "vuetify";
import axios from "axios";

export default {
  name: "TopBar",
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
    countNotifications: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const { mdAndDown } = useDisplay();
    const formattedDate = ref("");

    const getFormattedDate = () => {
      const date = moment(); // Obtiene la fecha y hora actual
      formattedDate.value = date.locale("es").format("D MMMM YYYY");
    };

    setInterval(getFormattedDate, 1000);

    return {
      isMdOrLower: mdAndDown,
      formattedDate,
    };
  },
  data() {
    return {
      itemsMenu: [
        { title: "Ir a mi perfil", to: "/empresario/configuracion/informacion-personal", icon: "mdi-account" },
      ],
      loadingGetData: true,
      showAnimationTitle: false,
      totalNotifications: 0,
      menuNotifications: false,
      filterNotification: "General",
      itemsNotifications: [],
    };
  },
  computed: {
    nameRole() {
      const rolesMap = {
        1: "Atractivo",
        2: "Alojamiento",
        4: "Policía de Turismo",
        5: "ICANH",
        6: "Agencia de viaje",
        7: "Agencia de viajes + alojamiento",
        8: "Atractivo + Alojamiento",
      };

      const roleId = this.$store.state.user.roles[0]; // Obtener el primer rol
      return rolesMap[roleId] || null; // Devolver el nombre del rol o null si no existe
    },
  },
  created() {},
  watch: {
    $route(to, from) {
      console.log(to, from);
      this.showAnimationTitle = false;
      setTimeout(() => {
        this.showAnimationTitle = true;
      }, 1);
    },
    countNotifications: {
      immediate: true,
      handler(newValue) {
        this.totalNotifications = newValue;
      },
      deep: true,
    },
    // "$vuetify.display.height": {
    //   immediate: true,
    //   handler(newValue) {
    //     if (newValue && this.menuNotifications) {
    //       this.menuNotifications = true;
    //     }
    //   },
    //   deep: true,
    // },
    // "$vuetify.display.width": {
    //   immediate: true,
    //   handler(newValue) {
    //     if (newValue && this.menuNotifications) {
    //       this.menuNotifications = true;
    //     }
    //   },
    //   deep: true,
    // },
    menuNotifications(newValue) {
      if (newValue) {
        this.listNotifications();
      } else {
        this.totalNotifications = 0;
      }
    },
  },
  methods: {
    toggleDrawer() {
      this.$emit("toggleDrawer");
    },
    listNotifications() {
      this.loadingGetData = true;
      let url = process.env.VUE_APP_API_URL;

      axios
        .get(url + "/notifications/list?user_id=" + this.$store.state.user.id, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          this.itemsNotifications = response.data;
          // this.totalNotifications = 0;
          setTimeout(() => {
            this.loadingGetData = false;
          }, 1000);
        })
        .catch((error) => {
          console.error("Error listing notifications", error);
        });
    },
  },
};
</script>

<style>
.no-hover:hover {
  background-color: inherit !important; /* Mantener el color de fondo original */
  color: inherit !important; /* Mantener el color de texto original */
  box-shadow: none !important; /* Quitar sombra */
  transition: none !important; /* Desactivar la transición */
}
.select-option-filter {
  border: 1px solid #8b8b8b !important;
}
.no-select-filter {
  border: 1px solid transparent !important;
}
.avatar-notifications {
  cursor: pointer;
}
.avatar-notifications:hover {
  background-color: #e0e0e0 !important;
}

.my-menu {
  margin-top: 40px;
  contain: initial;
  overflow: visible;
}
.my-menu::before {
  position: absolute;
  content: "";
  top: 0;
  right: 10px;
  transform: translateY(-100%);
  width: 10px;
  height: 13px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #fff;
}
</style>

<template>
  <v-container
    class="pa-0"
    style="height: 100%; width: 100%; min-width: 100%; position: relative"
  >
    <NavBarAnimation1
      v-if="isMdOrLower"
      title="Formulario de Ofertas para Guías"
      subTitle="Hola apreciado/a guía de turismo, en esta sección podrás registrar tus datos. Recuerda que
para el departamento es importante contar con esta información actualizada, ya que es útil para realizar
planificación de políticas públicas del departamento, planificar proyectos o realizar gestiones para la mejora de nuestro destino turístico.
Los datos que se solicitan en este formulario son estrictamente confidenciales y se utilizarán únicamente con fines estadísticos,
por lo que no se compartirán con otras entidades como: DIAN, Cámara de Comercio, Alcaldías, etc."
    >
    </NavBarAnimation1>

    <v-app-bar class="elevation-0" v-if="isMdOrLower">
      <v-slide-group
        v-model="stepFormServiceOrder"
        class="py-4"
        center-active
        show-arrows
      >
        <v-slide-group-item
          v-for="(item, index) in stepTitles.filter((step) => step.show)"
          :key="index"
          v-slot="{ isSelected }"
          :value="item.id"
        >
          <v-list-item
            :color="isSelected ? 'primary' : 'grey-lighten-1'"
            @click="stepFormServiceOrder = index + 1"
          >
            <template v-slot:prepend>
              <v-avatar
                :color="
                  isSelected || stepFormServiceOrder > index + 1
                    ? 'primary'
                    : 'grey-lighten-1'
                "
                class="text-white"
                size="x-small"
              >
                <v-icon size="x-small" v-if="stepFormServiceOrder == index + 1"
                  >mdi-pencil</v-icon
                >
                <span v-else>{{ index + 1 }}</span>
              </v-avatar>
            </template>
            <v-list-item-title
              class="text-caption"
              :class="
                isSelected || stepFormServiceOrder > index + 1 ? 'text-primary' : ''
              "
              >{{ item.title }}</v-list-item-title
            >
          </v-list-item>
        </v-slide-group-item>
      </v-slide-group>
    </v-app-bar>

    <v-row class="h-100 mh-100 auth">
      <v-col
        lg="5"
        xl="4"
        cols="12"
        class="d-lg-flex d-none align-center justify-center bg-primary pa-0"
        style="position: sticky"
      >
        <LeftPanelAnimation1
          title="Formulario de Ofertas para"
          subTitle="Hola apreciado/a guía de turismo, en esta sección podrás registrar tus datos. Recuerda que
para el departamento es importante contar con esta información actualizada, ya que es útil para realizar
planificación de políticas públicas del departamento, planificar proyectos o realizar gestiones para la mejora de nuestro destino turístico.
Los datos que se solicitan en este formulario son estrictamente confidenciales y se utilizarán únicamente con fines estadísticos,
por lo que no se compartirán con otras entidades como: DIAN, Cámara de Comercio, Alcaldías, etc."
          :wordsAnimate="['Guías']"
        ></LeftPanelAnimation1>
      </v-col>
      <v-col lg="7" xl="8" cols="12" style="background: #fff; z-index: 9">
        <v-row class="h-100 mh-100">
          <v-col
            lg="5"
            xl="4"
            cols="12"
            class="d-lg-flex d-none align-center justify-center position-relative"
          >
            <div class="scroll-container-wrapper">
              <div
                class="scroll-container position-relative"
                ref="scrollContainer"
                @scroll="handleScroll"
              >
                <div class="item">
                  <div
                    class="mouse m-2"
                    :class="{
                      'animate__animated animate__fadeInDown': showIndicator,
                      'animate__animated animate__fadeOutDown': !showIndicator,
                    }"
                  ></div>
                </div>
                <v-timeline class="py-8" align="start" side="end" :line-thickness="1">
                  <v-timeline-item
                    v-for="(item, index) in stepTitles.filter((step) => step.show)"
                    :key="index"
                    :class="stepFormServiceOrder > item.id - 1 ? 'success-step' : ''"
                    :dot-color="
                      stepFormServiceOrder > item.id - 1 ? 'primary' : 'dot-customer'
                    "
                    size="small"
                  >
                    <template v-slot:opposite>
                      <p class="font-weight-medium text-end">
                        {{ showGroupLabel(item, index) ? item.label : null }}
                      </p>
                    </template>
                    <template v-slot:icon>
                      <v-btn
                        width="20"
                        height="20"
                        @click="stepFormServiceOrder = item.id"
                        size="x-small"
                        icon
                        variant="text"
                      >
                        <v-icon v-if="stepFormServiceOrder == item.id">mdi-pencil</v-icon>
                        <v-icon v-else-if="stepFormServiceOrder > item.id"
                          >mdi-check</v-icon
                        >
                        <span v-else>{{ index + 1 }}</span>
                      </v-btn>
                    </template>
                    <div class="d-flex">
                      <div :ref="'timelineItem' + item.id"></div>

                      <div
                        class="text-left text-body-2"
                        :class="{ 'text-primary': stepFormServiceOrder > item.id }"
                      >
                        {{ item.title }}
                      </div>
                    </div>
                  </v-timeline-item>
                </v-timeline>
              </div>
              <div v-if="showTopShadow" class="top-shadow"></div>
              <div v-if="showBottomShadow" class="bottom-shadow"></div>
            </div>
          </v-col>

          <v-col lg="7" xl="8" cols="12" class="d-flex align-center justify-center pr-6">
            <v-card width="100%" elevation="0">
              <v-card-text>
                <p class="text-h4 font-weight-bold text-primary">
                  {{ currentTitle.label }}
                </p>
                <p class="text-h5 font-weight-bold">
                  {{ currentTitle.title }}
                </p>
              </v-card-text>
              <v-col md="12" cols="12" class="py-1">
                <br />
              </v-col>

              <v-window
                :touch="false"
                direction="vertical"
                v-model="stepFormServiceOrder"
              >
                <v-window-item :value="1">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep1">
                      <v-row>
                        <v-col md="6" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Tipo de documento de identificación</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-select
                            v-model="form.field_a_doc_type"
                            :rules="[validations.required]"
                            color="primary"
                            variant="underlined"
                            :items="formItems.itemsDocType"
                            item-value="id"
                            item-title="text"
                            placeholder="Selecciona una opción"
                          ></v-select>
                        </v-col>
                        <v-col
                          md="6"
                          cols="12"
                          class="py-1"
                          v-if="form.field_a_doc_type == 3"
                        >
                          <span class="font-weight-bold">¿Cuál?</span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required]"
                            v-model="form.field_a_doc_type_other"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col
                          :md="form.field_a_doc_type == 3 ? 12 : 6"
                          cols="12"
                          class="py-1"
                        >
                          <span class="font-weight-bold"
                            >Número de documento de identificación</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(11)]"
                            v-model="form.field_a_doc_number"
                            color="primary"
                            variant="underlined"
                            v-numbers-only-app
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="2">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep2">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Cuál es el nombre del representante legal de la
                            empresa?</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            v-model="form.field_a_rep_name"
                            :rules="[validations.required, validations.maxLength(255)]"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Sexo al nacer del representante legal de la empresa?</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_a_rep_sex"
                          >
                            <v-radio
                              v-for="(item, index) in formItems.itemsRepSex"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_a_rep_sex == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="3">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep3">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Indique si pertenece a estos grupos poblacionales</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_a_pop_group"
                            :items="formItems.itemsPopGroup"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_a_pop_group.length - 2 }}
                                {{
                                  form.field_a_pop_group.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="index + 1 < formItems.itemsPopGroup.length"
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.field_a_pop_group.includes(7)"
                        >
                          <span class="font-weight-bold"
                            >De acuerdo con su cultura, pueblo, rasgos físicos, se
                            reconoce como:</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_a_cultural_identity"
                            :items="formItems.itemsCulturalIdentity"
                            item-value="id"
                            item-title="text"
                            :rules="[validations.checkboxRequired]"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_a_cultural_identity.length - 2 }}
                                {{
                                  form.field_a_cultural_identity.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="index + 1 < formItems.itemsCulturalIdentity.length"
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">Teléfono(s) personal(es)</span>
                          <span class="font-weight-bold">*</span>

                          <v-tooltip location="top" content-class="bg-secondary">
                            <template v-slot:activator="{ props }">
                              <v-icon end v-bind="props" color="secondary">
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span
                              >Agrega tantos teléfonos como desees <br />
                              simplemente presionando Enter.</span
                            >
                          </v-tooltip>

                          <v-combobox
                            v-model="form.field_a_personal_phones"
                            color="primary"
                            variant="underlined"
                            v-numbers-only-app
                            chips
                            :rules="[validations.checkboxRequired]"
                            multiple
                            closable-chips
                          ></v-combobox>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Correo electrónico personal</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            v-model="form.field_a_personal_email"
                            :rules="[validations.required, validations.emailFormat]"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="4">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep4">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Tiene Registro Nacional de Turismo activo?
                          </span>
                          <span class="font-weight-bold">*</span>

                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_c_active_tourism_registry"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.itemsActiveTourismRegistry"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_c_active_tourism_registry == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>

                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.field_c_active_tourism_registry == 248"
                        >
                          <span class="font-weight-bold"
                            >Registro Nacional de Turismo (RNT)</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.field_c_tourism_registry_number"
                            color="primary"
                            variant="underlined"
                            v-numbers-only-app
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Tiene tarjeta profesional activa?
                          </span>
                          <span class="font-weight-bold">*</span>

                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_c_active_professional_card"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.itemsActiveProfessionalCard"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_c_active_professional_card == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>

                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.field_c_active_professional_card == 250"
                        >
                          <span class="font-weight-bold"
                            >Número de Tarjeta Profesional. Escriba el número de la
                            Tarjeta Profesional</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.field_c_professional_card_number"
                            color="primary"
                            variant="underlined"
                            v-numbers-only-app
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="5">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep5">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Ejerce actualmente la actividad de guianza turística?
                          </span>
                          <span class="font-weight-bold">*</span>

                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_c_currently_guiding"
                          >
                            <v-radio
                              v-for="(item, index) in formItems.itemsCurrentlyGuiding"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_c_currently_guiding == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿En qué municipios ejerce su actividad?</span
                          >
                          <span class="font-weight-bold">*</span>

                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_c_municipalities_guiding"
                            :items="formItems.itemsMunicipalitiesGuiding"
                            item-value="id"
                            item-title="text"
                            :rules="[validations.checkboxRequired]"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_c_municipalities_guiding.length - 2 }}
                                {{
                                  form.field_c_municipalities_guiding.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsMunicipalitiesGuiding.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Tiene usted alguna profesión diferente a la guianza?
                          </span>
                          <span class="font-weight-bold">*</span>

                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_c_different_profession"
                          >
                            <v-radio
                              v-for="(item, index) in formItems.itemsDifferentProfession"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_c_different_profession == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>

                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.field_c_different_profession == 258"
                        >
                          <span class="font-weight-bold">¿Qué profesión?</span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.field_c_which_profession"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.field_c_different_profession == 258"
                        >
                          <span class="font-weight-bold"
                            >¿Cuál es el nivel educativo máximo en esta profesión?</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_c_max_educational_level"
                            :items="formItems.itemsMaxEducationalLevel"
                            item-value="id"
                            item-title="text"
                            :rules="[validations.required]"
                            variant="underlined"
                          >
                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-radio
                                      color="primary"
                                      :true-value="item.raw.id"
                                      :model-value="isActive"
                                      v-model="form.field_c_max_educational_level"
                                    ></v-radio>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsMaxEducationalLevel.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="6">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep6">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Cuánto tiempo lleva operando? Experiencia. Por favor indicar
                            cuántos meses y/o años.</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-row>
                            <v-col md="6" cols="12">
                              <span class="font-weight-medium">Años</span>
                              <v-text-field
                                :rules="[validations.required, validations.maxLength(2)]"
                                v-model="form.field_c_years_experience"
                                color="primary"
                                variant="underlined"
                                v-numbers-only-app
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" cols="12">
                              <span class="font-weight-medium">Meses</span>
                              <v-text-field
                                :rules="[validations.required, validations.maxLength(2)]"
                                v-model="form.field_c_months_experience"
                                color="primary"
                                variant="underlined"
                                v-numbers-only-app
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="7">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep7">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Indique su especialidad en la guianza turística. Seleccione
                            las actividades en las que se especializa.</span
                          >
                          <span class="font-weight-bold">*</span>

                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_c_specialization"
                            :items="formItems.itemsSpecialization"
                            item-value="id"
                            item-title="text"
                            :rules="[validations.checkboxRequired]"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_c_specialization.length - 2 }}
                                {{
                                  form.field_c_specialization.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="index + 1 < formItems.itemsSpecialization.length"
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="8">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep8">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Seleccione el idioma que está en capacidad de hablar en sus
                            recorridos y su nivel.</span
                          >
                          <span class="font-weight-bold">*</span>
                          <!-- <v-select
                          :rules="[validations.required]"
                          color="primary"
                          variant="underlined"
                          v-model="form.idiomas_recorridos"
                          :items="itemsIdiomasRecorridos"
                        ></v-select> -->
                          <div class="d-sm-flex justify-center align-center mt-3">
                            <span class="font-weight-medium mr-3 w-25"> Español </span>
                            <v-select
                              :menu-props="{ 'max-width': '100%' }"
                              v-model="form.field_c_spanish_language"
                              :items="formItems.itemsSpanishLanguage"
                              item-value="id"
                              item-title="text"
                              :rules="[validations.required]"
                              variant="underlined"
                            >
                              <template v-slot:item="{ props, item, index }">
                                <v-list-item v-bind="props" class="text-caption-font">
                                  <template v-slot:prepend="{ isActive }">
                                    <v-list-item-action start>
                                      <v-radio
                                        color="primary"
                                        :true-value="item.raw.id"
                                        :model-value="isActive"
                                        v-model="form.field_c_spanish_language"
                                      ></v-radio>
                                    </v-list-item-action>
                                  </template>

                                  <v-list-item-title class="text-wrap text-caption">
                                    <span class="text-caption"
                                      >{{ item.raw.title }}
                                    </span>
                                  </v-list-item-title>
                                </v-list-item>
                                <v-divider
                                  v-if="index + 1 < formItems.itemsSpanishLanguage.length"
                                ></v-divider>
                              </template>
                            </v-select>
                          </div>
                          <div class="d-sm-flex justify-center align-center">
                            <span class="font-weight-medium mr-3 w-25"> Inglés </span>
                            <v-select
                              :menu-props="{ 'max-width': '100%' }"
                              v-model="form.field_c_english_language"
                              :items="formItems.itemsEnglishLanguage"
                              item-value="id"
                              item-title="text"
                              :rules="[validations.required]"
                              variant="underlined"
                            >
                              <template v-slot:item="{ props, item, index }">
                                <v-list-item v-bind="props" class="text-caption-font">
                                  <template v-slot:prepend="{ isActive }">
                                    <v-list-item-action start>
                                      <v-radio
                                        color="primary"
                                        :true-value="item.raw.id"
                                        :model-value="isActive"
                                        v-model="form.field_c_english_language"
                                      ></v-radio>
                                    </v-list-item-action>
                                  </template>

                                  <v-list-item-title class="text-wrap text-caption">
                                    <span class="text-caption"
                                      >{{ item.raw.title }}
                                    </span>
                                  </v-list-item-title>
                                </v-list-item>
                                <v-divider
                                  v-if="index + 1 < formItems.itemsEnglishLanguage.length"
                                ></v-divider>
                              </template>
                            </v-select>
                          </div>
                          <div class="d-sm-flex justify-center align-center">
                            <span class="font-weight-medium mr-3 w-25"> Alemán </span>
                            <v-select
                              :menu-props="{ 'max-width': '100%' }"
                              v-model="form.field_c_german_language"
                              :items="formItems.itemsGermanLanguage"
                              item-value="id"
                              item-title="text"
                              :rules="[validations.required]"
                              variant="underlined"
                            >
                              <template v-slot:item="{ props, item, index }">
                                <v-list-item v-bind="props" class="text-caption-font">
                                  <template v-slot:prepend="{ isActive }">
                                    <v-list-item-action start>
                                      <v-radio
                                        color="primary"
                                        :true-value="item.raw.id"
                                        :model-value="isActive"
                                        v-model="form.field_c_german_language"
                                      ></v-radio>
                                    </v-list-item-action>
                                  </template>

                                  <v-list-item-title class="text-wrap text-caption">
                                    <span class="text-caption"
                                      >{{ item.raw.title }}
                                    </span>
                                  </v-list-item-title>
                                </v-list-item>
                                <v-divider
                                  v-if="index + 1 < formItems.itemsGermanLanguage.length"
                                ></v-divider>
                              </template>
                            </v-select>
                          </div>
                          <div class="d-sm-flex justify-center align-center">
                            <span class="font-weight-medium mr-3 w-25"> Portugués </span>

                            <v-select
                              :menu-props="{ 'max-width': '100%' }"
                              v-model="form.field_c_portuguese_language"
                              :items="formItems.itemsPortugueseLanguage"
                              item-value="id"
                              item-title="text"
                              :rules="[validations.required]"
                              variant="underlined"
                            >
                              <template v-slot:item="{ props, item, index }">
                                <v-list-item v-bind="props" class="text-caption-font">
                                  <template v-slot:prepend="{ isActive }">
                                    <v-list-item-action start>
                                      <v-radio
                                        color="primary"
                                        :true-value="item.raw.id"
                                        :model-value="isActive"
                                        v-model="form.field_c_portuguese_language"
                                      ></v-radio>
                                    </v-list-item-action>
                                  </template>

                                  <v-list-item-title class="text-wrap text-caption">
                                    <span class="text-caption"
                                      >{{ item.raw.title }}
                                    </span>
                                  </v-list-item-title>
                                </v-list-item>
                                <v-divider
                                  v-if="
                                    index + 1 < formItems.itemsPortugueseLanguage.length
                                  "
                                ></v-divider>
                              </template>
                            </v-select>
                          </div>
                          <div class="d-sm-flex justify-center align-center">
                            <span class="font-weight-medium mr-3 w-25"> Italiano </span>
                            <v-select
                              :menu-props="{ 'max-width': '100%' }"
                              v-model="form.field_c_italian_language"
                              :items="formItems.itemsPortugueseLanguage"
                              item-value="id"
                              item-title="text"
                              :rules="[validations.required]"
                              variant="underlined"
                            >
                              <template v-slot:item="{ props, item, index }">
                                <v-list-item v-bind="props" class="text-caption-font">
                                  <template v-slot:prepend="{ isActive }">
                                    <v-list-item-action start>
                                      <v-radio
                                        color="primary"
                                        :true-value="item.raw.id"
                                        :model-value="isActive"
                                        v-model="form.field_c_italian_language"
                                      ></v-radio>
                                    </v-list-item-action>
                                  </template>

                                  <v-list-item-title class="text-wrap text-caption">
                                    <span class="text-caption"
                                      >{{ item.raw.title }}
                                    </span>
                                  </v-list-item-title>
                                </v-list-item>
                                <v-divider
                                  v-if="
                                    index + 1 < formItems.itemsPortugueseLanguage.length
                                  "
                                ></v-divider>
                              </template>
                            </v-select>
                          </div>
                          <div class="d-sm-flex justify-center align-center">
                            <span class="font-weight-medium mr-3 w-25"> Otro </span>
                            <v-select
                              :menu-props="{ 'max-width': '100%' }"
                              v-model="form.field_c_other_language"
                              :items="formItems.itemsOtherLanguage"
                              item-value="id"
                              item-title="text"
                              :rules="[validations.required]"
                              variant="underlined"
                            >
                              <template v-slot:item="{ props, item, index }">
                                <v-list-item v-bind="props" class="text-caption-font">
                                  <template v-slot:prepend="{ isActive }">
                                    <v-list-item-action start>
                                      <v-radio
                                        color="primary"
                                        :true-value="item.raw.id"
                                        :model-value="isActive"
                                        v-model="form.field_c_other_language"
                                      ></v-radio>
                                    </v-list-item-action>
                                  </template>

                                  <v-list-item-title class="text-wrap text-caption">
                                    <span class="text-caption"
                                      >{{ item.raw.title }}
                                    </span>
                                  </v-list-item-title>
                                </v-list-item>
                                <v-divider
                                  v-if="index + 1 < formItems.itemsOtherLanguage.length"
                                ></v-divider>
                              </template>
                            </v-select>
                          </div>
                        </v-col>
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="
                            form.field_c_other_language == 303 ||
                            form.field_c_other_language == 304 ||
                            form.field_c_other_language == 305
                          "
                        >
                          <v-text-field
                            v-model="form.field_c_other_language_specify"
                            :rules="[validations.required]"
                            color="primary"
                            variant="underlined"
                            placeholder="¿Cuál?"
                          ></v-text-field>
                        </v-col>

                        <!-- <v-col md="12" cols="12" class="py-1">
                        <span class="font-weight-bold"
                          >¿Cuál es su nivel de manejo del idioma?</span
                        >
                        <span class="font-weight-bold">*</span>
                        <v-radio-group
                          :rules="[validations.required]"
                          color="primary"
                          class="mt-2"
                          v-model="form.nivel_idioma"
                        >
                          <v-radio
                            label="Básico"
                            value="Básico"
                            class="pa-1 my-1"
                            :class="{
                              ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                form.nivel_idioma == 'Básico',
                            }"
                          ></v-radio>
                          <v-radio
                            label="Intermedio"
                            value="Intermedio"
                            class="pa-1 my-1"
                            :class="{
                              'rounded-lg animate__animated animate__headShake bg-primary-second':
                                form.nivel_idioma == 'Intermedio',
                            }"
                          ></v-radio>
                          <v-radio
                            label="Avanzado"
                            value="Avanzado"
                            class="pa-1 my-1"
                            :class="{
                              'rounded-lg animate__animated animate__headShake bg-primary-second':
                                form.nivel_idioma == 'Avanzado',
                            }"
                          ></v-radio>
                        </v-radio-group>
                      </v-col> -->
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="9">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep9">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Indique la tarifa promedio por hora de la prestación de sus
                            servicios en idioma Español</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.field_c_avg_rate_spanish"
                            color="primary"
                            variant="underlined"
                            v-numbers-only-app
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="
                            (form.field_c_english_language != null &&
                              form.field_c_english_language != 286) ||
                            (form.field_c_french_language != null &&
                              form.field_c_french_language != 290) ||
                            (form.field_c_german_language != null &&
                              form.field_c_german_language != 294) ||
                            (form.field_c_portuguese_language != null &&
                              form.field_c_portuguese_language != 298) ||
                            (form.field_c_italian_language != null &&
                              form.field_c_italian_language != 302) ||
                            (form.field_c_other_language != null &&
                              form.field_c_other_language != 306)
                          "
                        >
                          <span class="font-weight-bold"
                            >Indique la tarifa promedio por hora de la prestación de sus
                            servicios en idioma diferente al Español</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.field_c_avg_rate_other_language"
                            color="primary"
                            variant="underlined"
                            v-numbers-only-app
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="10">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep10">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Principalmente, ¿quién contrata sus servicios como Guía
                            Profesional de Turismo?</span
                          >
                          <span class="font-weight-bold">*</span>

                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_c_who_hires_services"
                            :items="formItems.itemsWhoHiresServices"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_c_who_hires_services.length - 2 }}
                                {{
                                  form.field_c_who_hires_services.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="index + 1 < formItems.itemsWhoHiresServices.length"
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Indique los días de la semana en los que labora
                            principalmente.</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_c_working_days"
                            :items="formItems.itemsWorkingDays"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_c_working_days.length - 2 }}
                                {{
                                  form.field_c_working_days.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="index + 1 < formItems.itemsWorkingDays.length"
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
              </v-window>
              <v-card-actions>
                <br />
              </v-card-actions>
              <v-card-actions class="mb-9">
                <v-spacer></v-spacer>

                <v-btn
                  class="text-capitalize"
                  :disabled="stepFormServiceOrder > 1 ? false : true"
                  variant="text"
                  @click="goToPreviousStep"
                >
                  Anterior
                </v-btn>
                <v-btn
                  class="text-capitalize"
                  v-if="
                    stepFormServiceOrder <
                    stepTitles.filter((step) => step.show)[
                      stepTitles.filter((step) => step.show).length - 1
                    ].id
                  "
                  color="primary"
                  variant="text"
                  @click="goToNextStep"
                >
                  Siguiente
                </v-btn>
                <v-btn
                  @click="saveForm"
                  class="text-capitalize"
                  v-else
                  color="primary"
                  variant="flat"
                >
                  Finalizar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <!-- <v-col cols="12" class="d-flex align-center justify-center"></v-col> -->
        </v-row>
      </v-col>
    </v-row>
    <v-overlay
      contained
      persistent
      :close-on-back="false"
      :close-on-content-click="false"
      class="align-center justify-center"
      scrim="#FFF"
      :opacity="1"
      v-model="loadingPageOverlay"
    >
      <div class="text-center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <h4 class="text-h4 mt-4 font-weight-medium text-primary">Enviando formulario</h4>
        <p class="text-h6 font-weight-medium mt-2">
          Por favor, espera mientras procesamos tu solicitud.
        </p>
      </div>
    </v-overlay>
    <UserExperience></UserExperience>
    <DialogResponse
      @cancel="cancelDialogResponse"
      v-if="dialogResponse"
      :dialog="dialogResponse"
    ></DialogResponse>
  </v-container>
  <!-- <div style="    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    max-width: 100%;
    min-height: 100vh;
    min-height: 100dvh;
    position: relative;">
       
    </div> -->
</template>

<script>
import { useDisplay } from "vuetify";
import validationFields from "../functions/validationFields";
import LeftPanelAnimation1 from "./includes/LeftPanelAnimation1.vue";
import NavBarAnimation1 from "./includes/NavBarAnimation1.vue";
import UserExperience from "./includes/UserExperience.vue";
import axios from "axios";
import { useToast } from "vue-toastification";
import ErrorLayoutComponent from "@/components/error-request/ErrorLayoutComponent.vue";
import DialogResponse from "@/components/dialog/DialogResponse.vue";

export default {
  name: "FormOfferGuides",
  mixins: [validationFields],
  components: {
    LeftPanelAnimation1,
    NavBarAnimation1,
    UserExperience,
    DialogResponse,
  },

  setup() {
    const { mdAndDown } = useDisplay();

    return {
      isMdOrLower: mdAndDown,
    };
  },

  data() {
    return {
      showTopShadow: false,
      showBottomShadow: true,
      formConfig: null,
      form_relations_question: null,
      toast: useToast(),
      loadingPageOverlay: false,
      dialogResponse: null,

      formItems: {
        itemsDocType: [],
        itemsRepSex: [],
        itemsPopGroup: [],
        itemsCulturalIdentity: [],
        itemsActiveTourismRegistry: [],
        itemsActiveProfessionalCard: [],
        itemsCurrentlyGuiding: [],
        itemsMunicipalitiesGuiding: [],
        itemsDifferentProfession: [],
        itemsMaxEducationalLevel: [],
        itemsSpecialization: [],
        itemsSpanishLanguage: [],
        itemsEnglishLanguage: [],
        itemsFrenchLanguage: [],
        itemsGermanLanguage: [],
        itemsPortugueseLanguage: [],
        itemsItalianLanguage: [],
        itemsOtherLanguage: [],
        itemsWhoHiresServices: [],
        itemsWorkingDays: [],
      },

      form: {
        field_a_doc_type: null,
        field_a_operating_docs_other: null,
        field_a_doc_number: null,
        field_a_rep_name: null,
        field_a_rep_sex: null,
        field_a_pop_group: [],
        field_a_cultural_identity: [],
        field_a_personal_phones: [],
        field_a_personal_email: null,
        field_c_active_tourism_registry: null,
        field_c_tourism_registry_number: null,
        field_c_active_professional_card: null,
        field_c_professional_card_number: null,
        field_c_currently_guiding: null,
        field_c_municipalities_guiding: [],
        field_c_different_profession: null,
        field_c_which_profession: null,
        field_c_max_educational_level: null,
        field_c_years_experience: null,
        field_c_months_experience: null,
        field_c_specialization: [],
        field_c_spanish_language: null,
        field_c_english_language: null,
        field_c_french_language: null,
        field_c_german_language: null,
        field_c_portuguese_language: null,
        field_c_italian_language: null,
        field_c_other_language: null,
        field_c_other_language_specify: null,
        field_c_avg_rate_spanish: null,
        field_c_avg_rate_other_language: null,
        field_c_who_hires_services: [],
        field_c_working_days: [],
      },

      //   itemsRepreGenero: [
      //     {
      //       label: "Mujer",
      //       description: null,
      //       value: "Mujer",
      //     },
      //     {
      //       label: "Hombre",
      //       description: null,
      //       value: "Hombre",
      //     },
      //     {
      //       label: "Intersexual",
      //       description: "Son personas que biológicamente desarrollan características",
      //       value: "Intersexual",
      //     },
      //   ],

      //   itemRepreGruposPoblacionales: [
      //     "Grupos Étnicos",
      //     "Sector Social LGBTIQ+",
      //     "Víctimas del conflicto",
      //     "Reincorporado/reinsertado",
      //     "Lider Social",
      //     "Mujer cabeza de hogar",
      //     "Ninguno",
      //   ],

      //   itemRepreCultura: [
      //     "Indígena",
      //     "Gitano/a o Rrom",
      //     "Palenquero/a de San Basilio",
      //     "Raizal del archipielago de San Andrés, Providencia y Santa Catalina",
      //     "Negro/a, mulato/a, afrodescendiente, afrocolombiano/a",
      //   ],

      //   itemsMunicipiosEjerceActividad: [
      //     "San José del Guaviare",
      //     "El Retorno",
      //     "Miraflores",
      //     "Calamar",
      //   ],

      //   itemsNivelEducaticoMax: [
      //     "Técnico",
      //     "Tecnólogo",
      //     "Profesional Incompleto",
      //     "Profesional Graduado",
      //     "Especialista",
      //     "Magister",
      //   ],

      //   itemsEspecialidadGuianza: [
      //     "Histórico - Cultural",
      //     "Arte y museos",
      //     "Interpretación ambiental",
      //     "Arquitectónico o religioso",
      //     "Aventura",
      //     "Agroturismo",
      //     "Balsaje",
      //     "Gastrónomico",
      //     "Bienestar (sanación, yoga, etc)",
      //     "Botánica - observación de flora",
      //     "Senderismo/Caminatas",
      //     "Avistamiento de Aves",
      //     "Avistamiento de fauna (diferente a las aves)",
      //     "Otro",
      //   ],
      //   itemsIdiomasRecorridos: [
      //     "Español",
      //     "Inglés",
      //     "Francés",
      //     "Alemán",
      //     "Portugués",
      //     "Italiano",
      //     "Otro",
      //   ],

      //   itemsQuienContrataServiciosGuia: [
      //     "Agencia de Viajes",
      //     "Hoteles",
      //     "OPC (Operadores Profesionales de Congresos)",
      //     "Empresas de Transporte Terrestre Automotor",
      //     "Administradores de Atractivos",
      //     "Agremiaciones",
      //     "Entidades Distritales",
      //     "Turistas",
      //     "Instituciones Académicas",
      //     "Independiente",
      //     "Otros",
      //   ],

      //   itemsLaboraPrincipalmente: [
      //     "Lunes",
      //     "Martes",
      //     "Miércoles",
      //     "Jueves",
      //     "Viernes",
      //     "Sábado",
      //     "Domingo",
      //   ],

      isMobile: false,

      num: 120,
      vw: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
      vh: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),

      drawerStep: true,
      stepFormServiceOrder: 1,
      // stepTitles: [
      //     'Información de Identificación',
      //     'Información del Representante Legal',
      //     'Información Personal y Pertenencia a Grupos Poblacionales',
      //     'Información sobre Registro y Tarjeta Profesional',
      //     'Actividad de Guianza y Profesión',
      //     'Educación y Experiencia Profesional',
      //     'Especialidad en Guianza Turística',
      //     'Idiomas en Guianza Turística',
      //     'Tarifas por Hora de Servicios de Guianza',
      //     'Contratación y Disponibilidad Laboral',

      // ],
      stepTitles: [
        {
          group: 1,
          label: "Datos de la Empresa",
          id: 1,
          title: "Identificación del Representante",
          show: true,
        },
        {
          group: 1,
          label: "Datos de la Empresa",
          id: 2,
          title: "Datos Básicos",
          show: true,
        },
        {
          group: 1,
          label: "Datos de la Empresa",
          id: 3,
          title: "Información Personal y Grupos Poblacionales",
          show: true,
        },
        {
          group: 2,
          label: "Información Profesional",
          id: 4,
          title: "Registro y Tarjeta Profesional",
          show: true,
        },
        {
          group: 2,
          id: 5,
          label: "Información Profesional",
          title: "Actividad de Guianza y Profesión",
          show: true,
        },
        {
          group: 2,
          id: 6,
          label: "Información Profesional",
          title: "Experiencia",
          show: true,
        },
        {
          group: 2,
          id: 7,
          label: "Información Profesional",
          title: "Especialidad en Guianza Turística",
          show: true,
        },
        {
          group: 2,
          id: 8,
          label: "Información Profesional",
          title: "Idiomas en Guianza Turística",
          show: true,
        },
        {
          group: 2,
          id: 9,
          label: "Información Profesional",
          title: "Tarifas de Guianza",
          show: true,
        },
        {
          group: 2,
          id: 10,
          label: "Información Profesional",
          title: "Contratación y Disponibilidad",
          show: true,
        },
      ],

      showIndicator: true, // Initially show the indicator
      activeWatch: true,
    };
  },
  watch: {
    stepFormServiceOrder(newVal) {
      if (newVal < this.stepTitles.length) {
        this.scrollToItem(newVal);
      }
    },
    "form.field_a_pop_group"() {
      if (!this.form.field_a_pop_group.includes(7)) {
        if (this.activeWatch) {
          this.form.field_a_cultural_identity = [];
        }
      }
    },
    "form.field_c_active_tourism_registry"() {
      this.form.field_c_tourism_registry_number = null;
    },
    "form.field_c_active_professional_card"() {
      this.form.field_c_professional_card_number = null;
    },
    "form.field_c_different_profession"() {
      this.form.field_c_which_profession = null;
      this.form.field_c_max_educational_level = null;
    },
    "form.field_c_english_language"() {
      this.form.field_c_avg_rate_other_language = null;
    },
    "form.field_c_french_language"() {
      this.form.field_c_avg_rate_other_language = null;
    },
    "form.field_c_german_language"() {
      this.form.field_c_avg_rate_other_language = null;
    },
    "form.field_c_portuguese_language"() {
      this.form.field_c_avg_rate_other_language = null;
    },
    "form.field_c_italian_language"() {
      this.form.field_c_avg_rate_other_language = null;
    },

    "form.field_c_other_language"() {
      this.form.field_c_other_language_specify = null;
    },
  },
  methods: {
    async saveForm() {
      this.loadingPageOverlay = true;
      let otherThis = this;
      await setTimeout(async function () {
        const allValid = await otherThis.validateAllSteps();

        if (allValid) {
          otherThis.formResponseStore();
        } else {
          otherThis.loadingPageOverlay = false;
          console.log("Hay errores en algunos de los formularios.");
        }
      }, 1000);
    },
    async validateAllSteps() {
      let allValid = true;

      // Filtramos los pasos visibles y recorremos su longitud
      const visibleSteps = this.stepTitles.filter((step) => step.show);

      for (let i = 0; i < visibleSteps.length; i++) {
        this.stepFormServiceOrder = visibleSteps[i].id; // Navegar al paso correspondiente
        await this.$nextTick(); // Esperar a que el DOM se actualice y el formulario se monte

        const formRef = this.$refs[`formStep${visibleSteps[i].id}`];

        if (formRef && formRef.validate) {
          const { valid } = await formRef.validate();

          if (!valid) {
            allValid = false;

            // Resaltar los campos con errores
            this.$nextTick(() => {
              const firstErrorField = formRef.$el.querySelector(".v-input--error");
              if (firstErrorField) {
                firstErrorField.scrollIntoView({ behavior: "smooth" });
              }
            });

            break; // Detener la validación en el primer error encontrado
          }
        }
      }

      return allValid;
    },
    formResponseStore() {
      let url = process.env.VUE_APP_API_URL;

      axios
        .post(url + "/form-responses/store", {
          form: this.form,
          form_relations_question: this.form_relations_question,
          form_id: 3,
          user_id: this.$store.state.user.id,
          season_id: this.season_id,
        })
        .then((response) => {
          this.dialogResponse = {
            title: "¡Formulario enviado con éxito!",
            message:
              "Tus respuestas han sido registradas correctamente. Gracias por tomarte el tiempo de completar el formulario. Puedes estar seguro de que tus datos han sido guardados de manera segura.",
            type: "success",
            color: "secondary",
            icon: "mdi-check",
            show: true,
            redirect: false,
          };
          console.log(response);
          this.loadingPageOverlay = false;
          //resetear todo el componente
          this.resetFormFields();
          this.stepFormServiceOrder = 1;
        })
        .catch((error) => {
          this.loadingPageOverlay = false;
          if (
            error &&
            error.response &&
            error.response.status &&
            error.response.status == 422
          ) {
            //ERROR RETORNADO POR LAS VALIDACIONES
            this.toast.error({
              component: ErrorLayoutComponent,
              props: {
                errorMessage: error.response.data,
              },
              timeout: 8000,
            });
          } else {
            this.dialogResponse = {
              title: "Error al enviar el formulario",
              message:
                "Ocurrió un problema al intentar guardar tus respuestas. Por favor, revisa los campos y vuelve a intentarlo. Si el problema persiste, contacta al soporte técnico.",
              type: "success",
              color: "red-app",
              icon: "mdi-cloud-alert",
              show: true,
              redirect: false,
            };
          }

          console.log(error);
        });
    },
    resetFormFields() {
      //restablecer valores por defecto
      this.form = {
        field_a_doc_type: null,
        field_a_operating_docs_other: null,
        field_a_doc_number: null,
        field_a_rep_name: null,
        field_a_rep_sex: null,
        field_a_pop_group: [],
        field_a_cultural_identity: [],
        field_a_personal_phones: [],
        field_a_personal_email: null,
        field_c_active_tourism_registry: null,
        field_c_tourism_registry_number: null,
        field_c_active_professional_card: null,
        field_c_professional_card_number: null,
        field_c_currently_guiding: null,
        field_c_municipalities_guiding: [],
        field_c_different_profession: null,
        field_c_which_profession: null,
        field_c_max_educational_level: null,
        field_c_years_experience: null,
        field_c_months_experience: null,
        field_c_specialization: [],
        field_c_spanish_language: null,
        field_c_english_language: null,
        field_c_french_language: null,
        field_c_german_language: null,
        field_c_portuguese_language: null,
        field_c_italian_language: null,
        field_c_other_language: null,
        field_c_other_language_specify: null,
        field_c_avg_rate_spanish: null,
        field_c_avg_rate_other_language: null,
        field_c_who_hires_services: [],
        field_c_working_days: [],
      };

      this.stepFormServiceOrder = 1;
    },

    handleScroll() {
      const container = this.$refs.scrollContainer;
      if (container) {
        this.showIndicator = container.scrollTop === 0; // Muestra el indicador si está en la parte superior

        // Control de las sombras
        this.showTopShadow = container.scrollTop > 0;
        this.showBottomShadow =
          container.scrollTop < container.scrollHeight - container.clientHeight;
      }
    },
    scrollToItem(index) {
      const item = this.$refs["timelineItem" + index][0]; // Acceso directo al primer elemento
      if (item) {
        const container = this.$refs.scrollContainer;
        const itemRect = item.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        // Ajuste adicional para el margen superior
        const offsetTop = itemRect.top + container.scrollTop - containerRect.top - 300; // El valor negativo asegura que el elemento no quede oculto

        container.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
        console.log(
          "Item Rect:",
          itemRect,
          "Container Rect:",
          containerRect,
          "Offset Top:",
          offsetTop
        );
      }
    },

    toggleMunicipiosEjerceActividad(municipio) {
      const index = this.form.municipios_ejerce_actividad.indexOf(municipio);
      if (index === -1) {
        this.form.municipios_ejerce_actividad.push(municipio);
      } else {
        this.form.municipios_ejerce_actividad.splice(index, 1);
      }
    },

    randomRadius() {
      return Math.random() * 0.7 + 0.6;
    },
    getRandomX() {
      return Math.floor(Math.random() * Math.floor(this.vw)).toString();
    },
    getRandomY() {
      return Math.floor(Math.random() * Math.floor(this.vh)).toString();
    },

    checkMobile() {
      this.isMobile = window.innerWidth < 600;
    },

    showGroupLabel(item, index) {
      // Verifica si es el primer paso del grupo visible
      return index === 0 || item.group !== this.visibleSteps[index - 1].group;
    },

    goToPreviousStep() {
      if (this.previousStep) {
        this.stepFormServiceOrder = this.previousStep.id;
      }
    },
    async goToNextStep() {
      if (this.nextStep) {
        //VALIDAR FORMULARIO ACTUAL EN REF formStep1,2,3... etc
        const { valid } = await this.$refs[
          "formStep" + this.stepFormServiceOrder
        ].validate();
        if (valid) {
          this.stepFormServiceOrder = this.nextStep.id;
        }
      }
    },

    async getInfoForm() {
      try {
        let url = process.env.VUE_APP_API_URL;
        const token = this.$store.state.token;

        axios
          .get(url + `/forms/show/3`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            this.formConfig = response.data.form_config;
            this.form_relations_question = response.data.form_relations_question;

            if (this.formConfig && this.formConfig.questions) {
              const field_a_doc_type = this.formConfig.questions.find(
                (q) => q.v_model === "field_a_doc_type"
              );
              if (field_a_doc_type) {
                this.formItems.itemsDocType = field_a_doc_type.options;
              }

              const field_a_rep_sex = this.formConfig.questions.find(
                (q) => q.v_model === "field_a_rep_sex"
              );
              if (field_a_rep_sex) {
                this.formItems.itemsRepSex = field_a_rep_sex.options;
              }

              const field_a_pop_group = this.formConfig.questions.find(
                (q) => q.v_model === "field_a_pop_group"
              );
              if (field_a_pop_group) {
                this.formItems.itemsPopGroup = field_a_pop_group.options;
              }

              const field_a_cultural_identity = this.formConfig.questions.find(
                (q) => q.v_model === "field_a_cultural_identity"
              );
              if (field_a_cultural_identity) {
                this.formItems.itemsCulturalIdentity = field_a_cultural_identity.options;
              }

              const field_c_active_tourism_registry = this.formConfig.questions.find(
                (q) => q.v_model === "field_c_active_tourism_registry"
              );
              if (field_c_active_tourism_registry) {
                this.formItems.itemsActiveTourismRegistry =
                  field_c_active_tourism_registry.options;
              }

              const field_c_active_professional_card = this.formConfig.questions.find(
                (q) => q.v_model === "field_c_active_professional_card"
              );
              if (field_c_active_professional_card) {
                this.formItems.itemsActiveProfessionalCard =
                  field_c_active_professional_card.options;
              }

              const field_c_currently_guiding = this.formConfig.questions.find(
                (q) => q.v_model === "field_c_currently_guiding"
              );
              if (field_c_currently_guiding) {
                this.formItems.itemsCurrentlyGuiding = field_c_currently_guiding.options;
              }

              const field_c_municipalities_guiding = this.formConfig.questions.find(
                (q) => q.v_model === "field_c_municipalities_guiding"
              );
              if (field_c_municipalities_guiding) {
                this.formItems.itemsMunicipalitiesGuiding =
                  field_c_municipalities_guiding.options;
              }

              const field_c_different_profession = this.formConfig.questions.find(
                (q) => q.v_model === "field_c_different_profession"
              );
              if (field_c_different_profession) {
                this.formItems.itemsDifferentProfession =
                  field_c_different_profession.options;
              }

              const field_c_max_educational_level = this.formConfig.questions.find(
                (q) => q.v_model === "field_c_max_educational_level"
              );
              if (field_c_max_educational_level) {
                this.formItems.itemsMaxEducationalLevel =
                  field_c_max_educational_level.options;
              }

              const field_c_specialization = this.formConfig.questions.find(
                (q) => q.v_model === "field_c_specialization"
              );
              if (field_c_specialization) {
                this.formItems.itemsSpecialization = field_c_specialization.options;
              }

              const field_c_spanish_language = this.formConfig.questions.find(
                (q) => q.v_model === "field_c_spanish_language"
              );
              if (field_c_spanish_language) {
                this.formItems.itemsSpanishLanguage = field_c_spanish_language.options;
              }

              const field_c_english_language = this.formConfig.questions.find(
                (q) => q.v_model === "field_c_english_language"
              );
              if (field_c_english_language) {
                this.formItems.itemsEnglishLanguage = field_c_english_language.options;
              }

              const field_c_french_language = this.formConfig.questions.find(
                (q) => q.v_model === "field_c_french_language"
              );
              if (field_c_french_language) {
                this.formItems.itemsFrenchLanguage = field_c_french_language.options;
              }

              const field_c_german_language = this.formConfig.questions.find(
                (q) => q.v_model === "field_c_german_language"
              );
              if (field_c_german_language) {
                this.formItems.itemsGermanLanguage = field_c_german_language.options;
              }

              const field_c_portuguese_language = this.formConfig.questions.find(
                (q) => q.v_model === "field_c_portuguese_language"
              );
              if (field_c_portuguese_language) {
                this.formItems.itemsPortugueseLanguage =
                  field_c_portuguese_language.options;
              }

              const field_c_italian_language = this.formConfig.questions.find(
                (q) => q.v_model === "field_c_italian_language"
              );
              if (field_c_italian_language) {
                this.formItems.itemsItalianLanguage = field_c_italian_language.options;
              }

              const field_c_other_language = this.formConfig.questions.find(
                (q) => q.v_model === "field_c_other_language"
              );
              if (field_c_other_language) {
                this.formItems.itemsOtherLanguage = field_c_other_language.options;
              }

              const field_c_who_hires_services = this.formConfig.questions.find(
                (q) => q.v_model === "field_c_who_hires_services"
              );
              if (field_c_who_hires_services) {
                this.formItems.itemsWhoHiresServices = field_c_who_hires_services.options;
              }

              const field_c_working_days = this.formConfig.questions.find(
                (q) => q.v_model === "field_c_working_days"
              );
              if (field_c_working_days) {
                // Map for day order
                const dayOrder = {
                  Lunes: 1,
                  Martes: 2,
                  Miércoles: 3,
                  Jueves: 4,
                  Viernes: 5,
                  Sábado: 6,
                  Domingo: 7,
                };

                field_c_working_days.options.sort(
                  (a, b) => dayOrder[a.text] - dayOrder[b.text]
                );

                this.formItems.itemsWorkingDays = field_c_working_days.options;
              }
            }

            if (
              this.$store &&
              this.$store.state &&
              this.$store.state.user &&
              this.$store.state.user.roles &&
              this.$store.state.user.roles[0] != 3
            ) {
              if (response.data.autocomplete) {
                this.activeWatch = false;
                response.data.autocomplete.forEach((item) => {
                  // Verifica si el campo existe en el formulario de manera segura
                  if (Object.prototype.hasOwnProperty.call(this.form, item.field)) {
                    // Actualiza el campo del formulario con el valor correspondiente
                    this.form[item.field] = item.text_response || item.selected_options;
                  }
                });
              }
            }

            setTimeout(() => {
              this.activeWatch = true;
            }, 1000);

            // }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      } finally {
        this.showDeleteConfirmation = false;
      }
    },
  },

  mounted() {
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
    this.getInfoForm();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  },

  computed: {
    visibleSteps() {
      // Filtrar los pasos visibles
      return this.stepTitles.filter((item) => item.show);
    },
    currentTitle() {
      const step = this.stepTitles[this.stepFormServiceOrder - 1];
      // Busca el label del grupo al que pertenece el step actual
      const groupLabel = this.stepTitles.find(
        (item) => item.group === step.group && item.label
      )?.label;

      return {
        title: step.title,
        label: groupLabel || "", // Si no se encuentra el label, se asigna una cadena vacía
      };
    },

    nextStep() {
      const currentIndex = this.stepTitles.findIndex(
        (step) => step.id === this.stepFormServiceOrder
      );
      return this.stepTitles.slice(currentIndex + 1).find((step) => step.show) || null;
    },

    previousStep() {
      const currentIndex = this.stepTitles.findIndex(
        (step) => step.id === this.stepFormServiceOrder
      );
      return (
        this.stepTitles
          .slice(0, currentIndex)
          .reverse()
          .find((step) => step.show) || null
      );
    },
  },
};
</script>
<style>
.v-field__input {
  padding-top: 8px;
}

/* #sky {
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    margin: 0;
    padding: 0;
} */

/* #shootingstars {
    margin: 0;
    padding: 0;
    width: 150vh;
    height: 100vw;
    position: fixed;
    overflow: hidden;
    transform: translatex(calc(50vw - 50%)) translatey(calc(50vh - 50%)) rotate(120deg);
} */

/* .wish {
    height: 2px;
    top: 300px;
    width: 100px;
    margin: 0;
    opacity: 0;
    padding: 0;
    background-color: white;
    position: absolute;
    background: linear-gradient(-45deg, white, rgba(0, 0, 255, 0));
    filter: drop-shadow(0 0 6px white);
    overflow: hidden;
} */

.v-row {
  margin: 0;
}
</style>

<template>
  <v-row class="fill-height">
    <v-col cols="12" md="12">
      <div class="d-flex pt-3">
        <v-img height="60" :src="require('@/assets/img/logo-guaviare-color.png')"></v-img>
        <v-spacer></v-spacer>
      </div>

      <v-card-title class="mt-8 px-5">
        <h4
          class="text-left text-h4 font-weight-bold"
          style="white-space: normal; word-break: auto-phrase"
        >
          ¿Quieres ser hacer parte activa del Observatorio?
        </h4>
      </v-card-title>
      <v-card-subtitle class="text-wrap pb-9 px-5">
        ¡Regístrate para activar tu usuario y construyamos juntos los datos para conocer
        mejor cómo se está moviendo el turismo en el Guaviare!
      </v-card-subtitle>

      <div class="d-flex justify-center mb-16 pb-6 mt-5" style="height: 100%">
        <v-form ref="formregisterentity">
          <v-card elevation="0" max-width="410" class="mx-auto">
            <!-- <v-img height="120" :src="require('@/assets/img/logo-guaviare-large.png')"></v-img> -->
            <v-card-title class="">
              <h4
                class="text-left text-h5 font-weight-bold"
                style="white-space: normal; word-break: auto-phrase"
              >
                Registro de Entidades
              </h4>
            </v-card-title>
            <v-card-subtitle class="text-wrap pb-9">
              Completa los detalles de tu entidad para registrarte.
            </v-card-subtitle>

            <v-card-text>
              <v-row>
                <v-col
                  sm="12"
                  md="12"
                  cols="12"
                  class="px-1 py-1"
                  :class="getAnimationClasses(1)"
                  :style="getAnimationStyle(1)"
                >
                  <span class="text-body-1 font-weight-bold">Información General</span>
                </v-col>
                <v-col
                  sm="12"
                  md="12"
                  cols="12"
                  class="px-1 py-1"
                  :class="getAnimationClasses(1)"
                  :style="getAnimationStyle(1)"
                >
                  <span class="font-weight-medium">Nombre de la Entidad</span>
                  <v-text-field
                    :rules="[validations.required, validations.maxLength(255)]"
                    variant="outlined"
                    density="compact"
                    rounded="lg"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col
                  sm="12"
                  md="12"
                  cols="12"
                  class="px-1 py-1"
                  :class="getAnimationClasses(2)"
                  :style="getAnimationStyle(2)"
                >
                  <span class="text-body-1 font-weight-bold">Datos de Contacto</span>
                </v-col>
                <v-col
                  sm="12"
                  md="12"
                  cols="12"
                  class="px-1 py-1"
                  :class="getAnimationClasses(2)"
                  :style="getAnimationStyle(2)"
                >
                  <span class="font-weight-medium">Nombre Completo</span>
                  <v-text-field
                    :rules="[validations.required, validations.maxLength(255)]"
                    variant="outlined"
                    density="compact"
                    rounded="lg"
                  ></v-text-field>
                </v-col>
                <!-- <v-col sm="12" md="6" cols="12" class="px-1 py-1" :class="getAnimationClasses(3)"
                                :style="getAnimationStyle(3)">
                                    <span class="font-weight-medium">Apellido</span>
                                    <v-text-field :rules="[validations.required, validations.maxLength(255)]" variant="outlined" density="compact" rounded="lg"></v-text-field>
                                </v-col> -->
                <v-col
                  sm="12"
                  md="12"
                  cols="12"
                  class="px-1 py-1"
                  :class="getAnimationClasses(4)"
                  :style="getAnimationStyle(4)"
                >
                  <span class="font-weight-medium">Teléfono</span>
                  <v-text-field
                    :rules="[validations.required, validations.maxLength(255)]"
                    variant="outlined"
                    density="compact"
                    rounded="lg"
                  ></v-text-field>
                </v-col>
                <v-col
                  sm="12"
                  md="12"
                  cols="12"
                  class="px-1 py-1"
                  :class="getAnimationClasses(5)"
                  :style="getAnimationStyle(5)"
                >
                  <span class="font-weight-medium">Correo Electrónico </span>
                  <v-text-field
                    :rules="[
                      validations.required,
                      validations.maxLength(255),
                      validations.emailFormat,
                    ]"
                    variant="outlined"
                    density="compact"
                    rounded="lg"
                  ></v-text-field>
                </v-col>
                <v-col
                  sm="12"
                  md="12"
                  cols="12"
                  class="px-1 py-1"
                  :class="getAnimationClasses(6)"
                  :style="getAnimationStyle(6)"
                >
                  <span class="font-weight-medium">Contraseña </span>
                  <v-text-field
                    :rules="[
                      validations.required,
                      validations.maxLength(255),
                      validations.securePassword,
                    ]"
                    autocomplete="new-password"
                    :type="visible ? 'text' : 'password'"
                    rounded="lg"
                    density="compact"
                    variant="outlined"
                  >
                    <template v-slot:append-inner="">
                      <v-icon
                        style="opacity: 1"
                        color="primary"
                        @click="visible = !visible"
                      >
                        {{ visible ? "mdi-eye-off" : "mdi-eye" }}
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-responsive class="mx-auto mt-5 pt-5">
                <v-hover>
                  <template v-slot:default="{ isHovering, props }">
                    <v-btn
                      v-bind="props"
                      :disabled="loadingForm"
                      rounded="lg"
                      :loading="loadingForm"
                      @click="registerEntity"
                      color="primary"
                      block
                      :variant="isHovering ? 'outlined' : 'flat'"
                      class="text-capitalize"
                    >
                      <span
                        v-if="isHovering"
                        :class="{ 'animate__animated  animate__slideInLeft': isHovering }"
                      >
                        Únete Ahora
                      </span>
                      <span
                        class="animate__animated animate__slideInRight"
                        :class="{ 'd-none': isHovering }"
                      >
                        Únete Ahora
                      </span>
                      <div class="area">
                        <ul class="circles">
                          <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                        </ul>
                      </div>
                    </v-btn>
                  </template>
                </v-hover>
              </v-responsive>

              <v-responsive class="mx-auto py-5 mt-4 text-center">
                <span>
                  ¿Ya tienes cuenta?

                  <router-link
                    to="/login"
                    class="text-decoration-none text-primary font-weight-medium"
                  >
                    Inicia sesión aquí</router-link
                  >
                </span>
              </v-responsive>
            </v-card-text>
          </v-card>
        </v-form>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import validationFields from "./functions/validationFields";

export default {
  name: "RegisterEntityView",
  mixins: [validationFields],
  setup() {
    // Get toast interface
  },
  data() {
    return {
      typePerson: null,
      loadingForm: false,
      visible: false,
    };
  },
  methods: {
    registerEntity() {},
  },
};
</script>

<style>
.select-content .v-slide-group__content {
  justify-content: center;
}
</style>

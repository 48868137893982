<template>
  <v-container
    class="pa-0"
    style="height: 100%; width: 100%; min-width: 100%; position: relative"
  >
    <NavBarAnimation3
      v-if="isMdOrLower"
      title="Registro de Empresarios"
      subTitle="Hola apreciado/a empresario/a, en esta sección podrás actualizar los datos 
                de ubicación y contacto de tu empresa. Recuerda que para el departamento es importante contar con tu información actualizada ya 
                que es útil para realizar planificación de políticas públicas del departamento, incluirte en proyectos o eventos que realicemos, 
                así como en gestiones para las intervenciones de cooperación internacional. Los datos que se solicitan en este formulario son 
                estrictamente confidenciales y se utilizarán únicamente con fines estadísticos, por lo que no se compartirán con otras entidades 
                como: DIAN, Cámara de Comercio, Alcaldías, etc."
    >
    </NavBarAnimation3>

    <v-app-bar class="elevation-0" v-if="isMdOrLower">
      <v-slide-group
        v-model="stepFormServiceOrder"
        class="py-4"
        center-active
        show-arrows
      >
        <v-slide-group-item
          v-for="(item, index) in stepTitles.filter((step) => step.show)"
          :key="index"
          v-slot="{ isSelected }"
          :value="item.id"
        >
          <v-list-item
            :color="isSelected ? 'primary' : 'grey-lighten-1'"
            @click="stepFormServiceOrder = index + 1"
          >
            <template v-slot:prepend>
              <v-avatar
                :color="
                  isSelected || stepFormServiceOrder > index + 1
                    ? 'primary'
                    : 'grey-lighten-1'
                "
                class="text-white"
                size="x-small"
              >
                <v-icon size="x-small" v-if="stepFormServiceOrder == index + 1"
                  >mdi-pencil</v-icon
                >
                <span v-else>{{ index + 1 }}</span>
              </v-avatar>
            </template>
            <v-list-item-title
              class="text-caption"
              :class="
                isSelected || stepFormServiceOrder > index + 1 ? 'text-primary' : ''
              "
              >{{ item.title }}</v-list-item-title
            >
          </v-list-item>
        </v-slide-group-item>
      </v-slide-group>
    </v-app-bar>

    <v-row class="h-100 mh-100 auth">
      <v-col
        lg="5"
        xl="4"
        cols="12"
        class="d-lg-flex d-none align-center justify-center bg-secondary pa-0"
        style="position: fixed; height: 100%"
      >
        <LeftPanelAnimation3
          title="Registro de"
          subTitle="Hola apreciado/a empresario/a, en esta sección podrás actualizar los datos 
                de ubicación y contacto de tu empresa. Recuerda que para el departamento es importante contar con tu información actualizada ya 
                que es útil para realizar planificación de políticas públicas del departamento, incluirte en proyectos o eventos que realicemos, 
                así como en gestiones para las intervenciones de cooperación internacional. Los datos que se solicitan en este formulario son 
                estrictamente confidenciales y se utilizarán únicamente con fines estadísticos, por lo que no se compartirán con otras entidades 
                como: DIAN, Cámara de Comercio, Alcaldías, etc."
          :wordsAnimate="['Empresarios']"
        ></LeftPanelAnimation3>
      </v-col>
      <v-col class="ml-auto" lg="7" xl="8" cols="12" style="background: #fff; z-index: 9">
        <v-row class="h-100 mh-100">
          <v-col
            lg="5"
            xl="4"
            cols="12"
            class="d-lg-flex d-none align-center justify-center position-relative"
          >
            <div class="scroll-container-wrapper">
              <div
                class="scroll-container position-relative"
                ref="scrollContainer"
                @scroll="handleScroll"
              >
                <!-- <div class="item">
                  <div
                    class="mouse m-2"
                    :class="{
                      'animate__animated animate__fadeInDown': showIndicator,
                      'animate__animated animate__fadeOutDown': !showIndicator,
                    }"
                  ></div>
                </div> -->
                <v-timeline class="py-8" align="start" side="end" :line-thickness="1">
                  <v-timeline-item
                    v-for="(item, index) in stepTitles.filter((step) => step.show)"
                    :key="index"
                    :class="stepFormServiceOrder > item.id - 1 ? 'success-step' : ''"
                    :dot-color="stepFormServiceOrder > item.id - 1 ? 'primary' : 'dot-customer'"
                    size="small"
                  >
                    <template v-slot:opposite>
                      <p class="font-weight-medium text-end">
                        {{ item.label ? item.label : null }}
                      </p>
                    </template>
                    <template v-slot:icon>
                      <v-btn
                        width="20"
                        height="20"
                        @click="stepFormServiceOrder = item.id"
                        size="x-small"
                        icon
                        variant="text"
                      >
                        <v-icon v-if="stepFormServiceOrder == item.id "
                          >mdi-pencil</v-icon
                        >
                        <v-icon v-else-if="stepFormServiceOrder > item.id"
                          >mdi-check</v-icon
                        >
                        <span v-else>{{ index + 1}}</span>
                      </v-btn>
                    </template>
                    <div class="d-flex">
                      <div :ref="'timelineItem' + item.id"></div>

                      <div
                        class="text-left text-body-2"
                        :class="{ 'text-primary': stepFormServiceOrder > item.id }"
                      >
                        {{ item.title }}
                      </div>
                    </div>
                  </v-timeline-item>
                </v-timeline>
              </div>
              <div v-if="showTopShadow" class="top-shadow"></div>
              <div v-if="showBottomShadow" class="bottom-shadow"></div>
            </div>
          </v-col>

          <v-col lg="7" xl="8" cols="12" class="d-flex align-center justify-center pr-6">
            <v-card width="100%" elevation="0">
              <v-card-text>
                <p class="text-h4 font-weight-bold text-primary">
                  {{ currentTitle.label }}
                </p>
                <p class="text-h5 font-weight-bold">
                  {{ currentTitle.title }}
                </p>
              </v-card-text>
              <v-col md="12" cols="12" class="py-1">
                <br />
              </v-col>

              <v-window
                :touch="false"
                direction="vertical"
                v-model="stepFormServiceOrder"
              >
                <v-window-item :value="1">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep1">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Cuál es el nombre del representante legal de la
                            empresa?</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            v-model="form.field_a_rep_name"
                            :rules="[validations.required, validations.maxLength(255)]"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col md="6" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Tipo de documento de identificación</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-select
                            v-model="form.field_a_doc_type"
                            :rules="[validations.required]"
                            color="primary"
                            variant="underlined"
                            :items="formItems.itemsTypeDocument"
                            item-value="id"
                            item-title="text"
                            placeholder="Selecciona una opción"
                          ></v-select>
                        </v-col>
                        <v-col
                          md="6"
                          cols="12"
                          class="py-1"
                          v-if="form.field_a_doc_type == 3"
                        >
                          <span class="font-weight-bold">¿Cuál?</span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required]"
                            v-model="form.field_a_doc_type_other"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col md="6" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Número de documento de identificación</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(11)]"
                            v-model="form.field_a_doc_number"
                            color="primary"
                            variant="underlined"
                            v-numbers-only-app
                            placeholder=""
                          ></v-text-field>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Autoriza el tratamiento de los datos personales sensibles que se recolectarán en el presente formulario de registro?</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_d_authorize_sensitive_data_processing"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.field_d_authorize_sensitive_data_processing"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_d_authorize_sensitive_data_processing ==
                                  item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>


                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Autoriza el envío de mensajes o notificaciones informativas a través de WhatsApp utilizando los datos de contacto proporcionados en este formulario?</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_authorize_whatsApp"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.field_authorize_whatsApp"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_authorize_whatsApp ==
                                  item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>

                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="2">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep2">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Sexo al nacer del representante legal de la empresa?</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_a_rep_sex"
                          >
                            <v-radio
                              v-for="(item, index) in formItems.itemsGender"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_a_rep_sex == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Indique si pertenece a estos grupos poblacionales</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_a_pop_group"
                            :items="formItems.itemsPopGroup"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_a_pop_group.length - 2 }}
                                {{
                                  form.field_a_pop_group.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="index + 1 < formItems.itemsPopGroup.length"
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.field_a_pop_group.includes(7)"
                        >
                          <span class="font-weight-bold"
                            >De acuerdo con su cultura, pueblo, rasgos físicos, se
                            reconoce como:</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_a_cultural_identity"
                            :items="formItems.itemsCulturalIdentity"
                            item-value="id"
                            item-title="text"
                            :rules="[validations.checkboxRequired]"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_a_cultural_identity.length - 2 }}
                                {{
                                  form.field_a_cultural_identity.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="index + 1 < formItems.itemsCulturalIdentity.length"
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                        >
                          <span class="font-weight-bold"
                            >Seleccione su rango de edad</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_a_your_age_range"
                            :items="formItems.field_a_your_age_range"
                            item-value="id"
                            item-title="text"
                            :rules="[validations.required]"
                            variant="underlined"
                          >
                            
                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-radio
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-radio>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="index + 1 < formItems.field_a_your_age_range.length"
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="3">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep3">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">Teléfono(s) personal(es)</span>
                          <span class="font-weight-bold">*</span>

                          <v-tooltip location="top" content-class="bg-secondary">
                            <template v-slot:activator="{ props }">
                              <v-icon end v-bind="props" color="secondary">
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span
                              >Agrega tantos teléfonos como desees <br />
                              simplemente presionando Enter.</span
                            >
                          </v-tooltip>

                          <v-combobox
                            v-model="form.field_a_personal_phones"
                            color="primary"
                            variant="underlined"
                            v-numbers-only-app
                            chips
                            :rules="[validations.checkboxRequired]"
                            multiple
                            closable-chips
                          ></v-combobox>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Correo electrónico personal</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            v-model="form.field_a_personal_email"
                            :rules="[validations.required, validations.emailFormat]"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Marque se rol de acuerdo a las actividades que realiza?</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_a_role_activities_perform"
                            :items="formItems.field_a_role_activities_perform"
                            item-value="id"
                            item-title="text"
                            :rules="[validations.checkboxRequired]"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_a_role_activities_perform.length - 2 }}
                                {{
                                  form.field_a_role_activities_perform.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="index + 1 < formItems.field_a_role_activities_perform.length"
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="4">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep4">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿La empresa es la administradora de algún sitio o atractivo
                            turístico?
                          </span>
                          <span class="font-weight-bold">*</span>

                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_a_admin_tour_site"
                          >
                            <v-radio
                              v-for="(item, index) in formItems.itemsAdminTourSite"
                              :key="index"
                              :label="item.text"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_a_admin_tour_site == item.id,
                              }"
                            ></v-radio>

                          </v-radio-group>
                        </v-col>
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.field_a_admin_tour_site == 19"
                        >
                          <span class="font-weight-bold"
                            >¿Cuál o de los siguientes sitios/atractivos
                            administra?
                          </span>
                          <span class="font-weight-bold">*</span>

                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_a_managed_sites"
                            :items="formItems.itemsAdminSitesManage"
                            item-value="id"
                            item-title="text"
                            :rules="[validations.required]"
                            
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_a_managed_sites.length - 2 }}
                                {{
                                  form.field_a_managed_sites.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-radio
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-radio>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="index + 1 < formItems.itemsAdminSitesManage.length"
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="5">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep5">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿La empresa tiene matricula activa actualmente en Cámara de
                            Comercio?
                          </span>
                          <span class="font-weight-bold">*</span>

                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_a_active_registration"
                          >
                            <v-radio
                              v-for="(item, index) in formItems.itemsActiveRegistration"
                              :key="index"
                              :label="item.text"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_a_active_registration == item.id,
                              }"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.field_a_active_registration == 46"
                        >
                          <span class="font-weight-bold"
                            >¿El portafolio de productos y/o servicios comerciales de su
                            empresa incluye actividades del sector turismo (Alojamiento,
                            venta de tours, operadores de congresos, gastronomía, etc)?
                          </span>
                          <span class="font-weight-bold">*</span>

                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_a_includes_tourism"
                          >
                            <v-radio
                              v-for="(item, index) in formItems.itemsIncludesTourism"
                              :key="index"
                              :label="item.text"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_a_includes_tourism == item.id,
                              }"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.field_a_includes_tourism == 47"
                        >
                          <span class="font-weight-bold"
                            >¿De acuerdo con las actividades económicas que ejerce cómo se
                            clasifica?
                          </span>
                          <span class="font-weight-bold">*</span>

                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_a_economic_classification"
                            :items="formItems.itemsEconomicClassification"
                            item-value="id"
                            item-title="text"
                            :rules="[validations.checkboxRequired]"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_a_economic_classification.length - 2 }}
                                {{
                                  form.field_a_economic_classification.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsEconomicClassification.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="6">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep6">
                      <v-row>
                        <v-col md="6" cols="12" class="py-1">
                          <span class="font-weight-bold">NIT</span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.field_a_company_nit"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col md="6" cols="12" class="py-1">
                          <span class="font-weight-bold">DV</span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.field_a_company_dv"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">Nombre del Establecimiento</span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.field_a_name_estab"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Ciudad de ubicación del establecimiento
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_a_estab_city"
                          >
                            <v-radio
                              v-for="(item, index) in formItems.itemsEstabCity"
                              :key="index"
                              :label="item.text"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_a_estab_city == item.id,
                              }"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="7">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep7">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">Dirección de la empresa</span>
                          <span class="font-weight-bold">*</span>

                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            color="primary"
                            @input="onPlaceChanged"
                            v-model="form.field_a_company_address"
                            ref="addressField"
                            variant="underlined"
                            placeholder="Escribe la dirección de la empresa"
                          ></v-text-field>

                          <div
                            ref="map"
                            class="map mb-3"
                            style="width: 100%; height: 400px"
                          ></div>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">Email de la empresa</span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            v-model="form.field_a_company_email"
                            :rules="[validations.required, validations.emailFormat]"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">Página web</span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            v-model="form.field_a_company_website"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="8">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep8">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">Teléfono(s) de la empresa</span>
                          <span class="font-weight-bold">*</span>
                          <v-tooltip location="top" content-class="bg-secondary">
                            <template v-slot:activator="{ props }">
                              <v-icon end v-bind="props" color="secondary">
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span
                              >Agrega tantos teléfonos como desees <br />
                              simplemente presionando Enter.</span
                            >
                          </v-tooltip>
                          <v-combobox
                            v-model="form.field_a_company_phones"
                            color="primary"
                            variant="underlined"
                            v-numbers-only-app
                            chips
                            :rules="[validations.checkboxRequired]"
                            multiple
                            closable-chips
                          ></v-combobox>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Indique el año de inicio de actividades del
                            establecimiento</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            v-model="form.field_a_estab_start_year"
                            :rules="[validations.required, validations.maxLength(4)]"
                            color="primary"
                            variant="underlined"
                            v-numbers-only-app
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="9">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep9">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Qué documentos posee el establecimiento para la
                            operación?</span
                          >
                          <span class="font-weight-bold">*</span>

                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_a_operating_docs"
                            :items="formItems.itemsOperatingDocs"
                            item-value="id"
                            item-title="text"
                            :rules="[validations.checkboxRequired]"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_a_operating_docs.length - 2 }}
                                {{
                                  form.field_a_operating_docs.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="index + 1 < formItems.itemsOperatingDocs.length"
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>

                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.field_a_operating_docs.includes(75)"
                        >
                          <span class="font-weight-bold">¿Cuál?</span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            v-model="form.field_a_operating_docs_other"
                            :rules="[validations.required, validations.maxLength(255)]"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.field_a_operating_docs.includes(67)"
                        >
                          <span class="font-weight-bold"
                            >Registro Nacional de Turismo (RNT)</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            v-model="form.field_a_national_tourism_reg"
                            :rules="[validations.required, validations.maxLength(255)]"
                            color="primary"
                            variant="underlined"
                            v-numbers-only-app
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="10">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep10">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">Categoría de RNT</span>
                          <span class="font-weight-bold">*</span>

                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_a_rnt_category"
                            :items="formItems.itemsRntCategory"
                            item-value="id"
                            item-title="text"
                            :rules="[validations.required]"
                            variant="underlined"
                          >
                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-radio
                                      color="primary"
                                      :true-value="item.raw.id"
                                      :model-value="isActive"
                                      v-model="form.field_a_rnt_category"
                                    ></v-radio>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="index + 1 < formItems.itemsRntCategory.length"
                              ></v-divider>
                            </template>
                          </v-select>

                          <!-- <v-radio-group :rules="[validations.required]" color="primary"
                                                    class="mt-2 two-columns" v-model="form.categoria_rnt">
                                                    <v-radio hi v-for="(item, index) in itemsCategoriaRnt" :key="index"
                                                        :value="item" class=" pa-1 my-1"
                                                        :class="{ ' rounded-lg animate__animated animate__headShake bg-primary-second': form.categoria_rnt == item }">
                                                        <template v-slot:label="">

                                                            <div>
                                                                {{ item }}
                                                            </div>

                                                        </template>
                                                    </v-radio>

                                                </v-radio-group> -->
                        </v-col>

                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="formItems.itemsRntSubcategory.length > 0"
                        >
                          <span class="font-weight-bold">Subcategoría de RNT</span>
                          <span class="font-weight-bold">*</span>

                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_a_rnt_subcategory"
                            :items="formItems.itemsRntSubcategory"
                            item-value="id"
                            item-title="text"
                            :rules="[validations.required]"
                            variant="underlined"
                          >
                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-radio
                                      color="primary"
                                      :true-value="item.raw.id"
                                      :model-value="isActive"
                                      v-model="form.field_a_rnt_subcategory"
                                    ></v-radio>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="index + 1 < formItems.itemsRntSubcategory.length"
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="11">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep11">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Señale las actividades económicas registradas en Cámara de
                            Comercio de este establecimiento</span
                          >
                          <span class="font-weight-bold">*</span>

                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_a_registered_activities"
                            :items="formItems.itemsRegisteredActivities"
                            item-value="id"
                            item-title="text"
                            :rules="[validations.checkboxRequired]"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_a_registered_activities.length - 2 }}
                                {{
                                  form.field_a_registered_activities.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsRegisteredActivities.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
              </v-window>
              <v-card-actions>
                <br />
              </v-card-actions>
              <v-card-actions class="mb-9">
                <v-spacer></v-spacer>

                <v-btn
                  class="text-capitalize"
                  :disabled="stepFormServiceOrder > 1 ? false : true"
                  variant="text"
                  @click="goToPreviousStep"
                >
                  Anterior
                </v-btn>
                <v-btn
                  class="text-capitalize"
                  v-if="
                    stepFormServiceOrder < stepTitles.filter((step) => step.show).length
                  "
                  color="primary"
                  variant="text"
                  @click="goToNextStep"
                >
                  Siguiente
                </v-btn>
                <v-btn
                  @click="saveForm"
                  class="text-capitalize"
                  v-else
                  color="primary"
                  variant="flat"
                >
                  Finalizar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <!-- <v-col cols="12" class="d-flex align-center justify-center"></v-col> -->
        </v-row>
      </v-col>
    </v-row>
    <v-overlay
      contained
      persistent
      :close-on-back="false"
      :close-on-content-click="false"
      class="align-center justify-center"
      scrim="#FFF"
      :opacity="1"
      v-model="loadingPageOverlay"
    >
      <div class="text-center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <h4 class="text-h4 mt-4 font-weight-medium text-primary">Enviando formulario</h4>
        <p class="text-h6 font-weight-medium mt-2">
          Por favor, espera mientras procesamos tu solicitud.
        </p>
      </div>
    </v-overlay>
    <UserExperience></UserExperience>
    <DialogResponse
      @cancel="cancelDialogResponse"
      v-if="dialogResponse"
      :dialog="dialogResponse"
    ></DialogResponse>
  </v-container>
  <!-- <div style="    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    max-width: 100%;
    min-height: 100vh;
    min-height: 100dvh;
    position: relative;">
       
    </div> -->
</template>

<script>
import anime from "animejs/lib/anime.es.js";
import { useDisplay } from "vuetify";
import validationFields from "../functions/validationFields";
import LeftPanelAnimation3 from "./includes/LeftPanelAnimation3.vue";
import NavBarAnimation3 from "./includes/NavBarAnimation3.vue";
import UserExperience from "./includes/UserExperience.vue";
import axios from "axios";
import DialogResponse from "@/components/dialog/DialogResponse.vue";
import { useToast } from "vue-toastification";
import ErrorLayoutComponent from "@/components/error-request/ErrorLayoutComponent.vue";

/* global google */
export default {
  name: "FormEntrepreneurRegistration",
  mixins: [validationFields],
  components: {
    LeftPanelAnimation3,
    NavBarAnimation3,
    UserExperience,
    DialogResponse,
  },

  setup() {
    const { mdAndDown } = useDisplay();

    return {
      isMdOrLower: mdAndDown,
    };
  },

  data() {
    return {
      loadingPageOverlay: false,
      formConfig: null,
      toast: useToast(),
      dialogResponse: null,

      itemsRepreGenero: [
        {
          label: "Mujer",
          description: null,
          value: "Mujer",
        },
        {
          label: "Hombre",
          description: null,
          value: "Hombre",
        },
        {
          label: "Intersexual",
          description: "Son personas que biológicamente desarrollan características",
          value: "Intersexual",
        },
      ],
      itemRepreGruposPoblacionales: [
        "Grupos Étnicos",
        "Sector Social LGBTIQ+",
        "Víctimas del conflicto",
        "Reincorporado/reinsertado",
        "Lider Social",
        "Mujer cabeza de hogar",
        "Ninguno",
      ],
      itemRepreCultura: [
        "Indígena",
        "Gitano/a o Rrom",
        "Palenquero/a de San Basilio",
        "Raizal del archipielago de San Andrés, Providencia y Santa Catalina",
        "Negro/a, mulato/a, afrodescendiente, afrocolombiano/a",
      ],

      itemsSitiosAdministra: [
        "Arte Rupestre Cerro Azul",
        "Arte Rupestre Nuevo Tolima",
        "Arte Rupestre Raudal del Guayabero",
        "Cachiveras de Itilla",
        "Cachiveras del Chiribiquete",
        "Caño sabana Trankilandia",
        "Cavernas Rupicolas",
        "Charco Indio",
        "Ciudad de Piedra",
        "Damas de Nare",
        "Diamante de las aguas",
        "Finca Agro ecoturística El Paraíso",
        "Laguna Negra",
        "Los Picachos",
        "Los Termales",
        "Pozos Naturales",
        "Puentes Naturales",
        "Puerta de Orión",
        "Reserva Amadai",
        "Reserva Cheyen",
        "Reserva El Gallito de Roca",
        "Rincón de los Toros",
        "Sector Dique",
        "Túneles Naturales",
      ],

      itemsActividadesEconomicas: [
        "Agencia de viajes",
        "Arrendador de vehículos para turismo nacional e internacional",
        "Bar con potencial turístico",
        "Compañía de intercambio vacacional",
        "Concesionario de servicios turísticos en el parque",
        "Empresa de tiempo compartido y multipropiedad",
        "Establecimientos de alojamiento turistico",
        "Establecimientos de gastronomía y similares",
        "Operadores profesionales de congresos, ferias y convenciones",
      ],

      itemsEstablecimientoUbicacion: [
        "San José del Guaviare",
        "El Retorno",
        "Miraflores",
        "Calamar",
      ],

      itemsDocumentosEstablecimiento: [
        "Industría y Comercio (Pago a alcaldía)",
        "Póliza De Responsabilidad Civil Y Extracontractual",
        "RUT",
        "Concepto Bomberos",
        "Uso Del Suelo  ",
        "RNT",
        "Concepto Sanidad",
        "Tarjetas de asistencia médica",
        "Licencia de Construcción",
        "Resolución de Facturación",
        "Derechos de autor",
        "Procesos ante CDA",
        "Certificado Secretaria Salud",
        "Otro",
      ],

      itemsCategoriaRnt: [
        "Agencias de viajes ",
        "Arrendadores de vehículos para turismo nacional e internacional",
        "Bares",
        "Compañía de intercambio vacacional",
        "Concesionarios de servicios turísticos en el parque",
        "Empresa de tiempo compartido y multipropiedad",
        "Empresa de transporte terrestre automotor",
        "Empresas captadoras de ahorro para viajes",
        "Establecimientos de alojamiento turistico",
        "Establecimientos de gastronomía y similares",
        "Oficinas de representación turística",
        "Operadores de plataforma electrónicas o digitales de servicios turísticos",
        "Operadores profesionales de congresos, ferias y convenciones",
        "Viviendas Turísticas",
      ],

      itemsSubcategoriaRnt: [
        "agencia de viajes y de turismo",
        "agencia de viajes mayoristas",
        "agencia de viajes operadoras",
        "gastrobar",
        "bar social",
        "bar con música en vivo",
        "comercializadora",
        "promotora",
        "promotora y comercializadora",
        "transporte terrestre automotor especial",
        "operador de chivas",
        "operador de yipaos",
        "hotel",
        "apartahotel",
        "centro vacacional",
        "hostal",
        "campamento",
        "albergue",
        "refugio",
        "glamping",
        "restaurante",
        "restaurante de autoservicio",
        "bar y restaurante",
        "café o cafetería",
        "restaurante de comida rápida",
        "cevichería y pescadería",
        "heladería",
        "panadería repostería pastelería o chocolatería",
        "piqueteadero",
        "frutería",
        "parque temático",
        "parque de ecoturismo",
        "parque de agroturismo",
        "apartamento turístico",
        "casa turística",
        "finca turistica (alojamiento rural)",
        "otros tipos de hospedaje turísticos no permanentes",
        "otro tipo de vivienda turística",
        "Posada turística",
      ],

      itemsActividadesEconomicasCamara: [
        "Bar (CIIU 5630)",
        "Restaurante y Gastrobares (CIIU 5611, 5612, 5613, 5619, 5621, 5629)",
        "Alojamiento (CIIU 5511, 5512, 5513, 5514, 5519, 5520)",
        "Agencias de viajes (CIIU 7911, 7912, 7990)",
        "Transporte (CIIU 4911, 4921, 4922, 7710, 5111, 5112, 5011, 5021)",
        "Actividades culturales y eventos (CIIU  8230, 9006, 9007, 9008, 9102, 9103, 9311, 9319, 9321, 9329)",
      ],

      showTopShadow: false,
      showBottomShadow: true,

      address: null,
      map: null,
      marker: null,
      geocoder: null,

      isMobile: false,

      vw: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
      vh: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),

      formItems: {
        field_a_your_age_range: [],
        field_a_role_activities_perform: [],

        itemsTypeDocument: [],
        itemsGender: [],
        itemsPopGroup: [],
        itemsCulturalIdentity: [],
        itemsAdminTourSite: [],
        itemsAdminSitesManage: [],
        itemsActiveRegistration: [],
        itemsIncludesTourism: [],
        itemsEconomicClassification: [],
        itemsEstabCity: [],
        itemsOperatingDocs: [],
        itemsRntCategory: [],
        itemsRntSubcategory: [],
        itemsRegisteredActivities: [],
        field_d_authorize_sensitive_data_processing: [],
        field_authorize_whatsApp: [],
      },
      form: {
        field_a_your_age_range: null,
        field_a_role_activities_perform: [],
        field_a_rep_name: null,
        field_a_doc_type: null,
        field_a_doc_type_other: null,
        field_a_doc_number: null,
        field_d_authorize_sensitive_data_processing: null,
        field_authorize_whatsApp: null,
        field_a_rep_sex: null,
        field_a_pop_group: [],
        field_a_cultural_identity: [],
        field_a_personal_phones: [],
        field_a_personal_email: null,
        field_a_admin_tour_site: null,
        field_a_managed_sites: [],
        field_a_active_registration: null,
        field_a_includes_tourism: null,
        field_a_economic_classification: [],
        field_a_company_nit: null,
        field_a_company_dv: null,
        field_a_name_estab: null,
        field_a_estab_city: null,
        field_a_company_address: null,
        field_a_company_email: null,
        field_a_company_website: null,
        field_a_company_phones: [],
        field_a_estab_start_year: null,
        field_a_operating_docs: [],
        field_a_operating_docs_other: null,
        field_a_national_tourism_reg: null,
        field_a_rnt_category: null,
        field_a_rnt_subcategory: null,
        field_a_registered_activities: [],
      },

      form_relations_question: null,

      stepFormServiceOrder: 1,
      // stepTitles: [
      //   "Información del Representante Legal",
      //   "Identidad y Pertenencia del Representante Legal",
      //   "Contacto Personal del Representante Legal",
      //   "Administración de Sitios Turísticos",
      //   "Registro y Actividades Comerciales de la Empresa",
      //   "Información General del Establecimiento",
      //   "Contacto del Establecimiento",
      //   "Teléfono y Año de Inicio de Actividades",
      //   "Documentos y Registro Nacional de Turismo",
      //   "Categorías y Subcategorías Registro Nacional de Turismo",
      //   "Actividades Económicas Registradas",
      // ],

      stepTitles: [
        {
          group: 1,
          label: "Datos del Representante Legal",
          id: 1,
          title: "Información Personal",
          show: true,
        },
        {
          group: 1,
          id: 2,
          title: "Identidad y Pertenencia",
          show: true,
        },
        {
          group: 1,
          id: 3,
          title: "Contacto Personal",
          show: true,
        },

        {
          label: "Datos de la Empresa",
          group: 2,
          id: 4,
          title: "Administración de Sitios Turísticos",
          show: true,
        },
        {
          group: 2,
          id: 5,
          title: "Registro y Actividades Comerciales",
          show: true,
        },

        {
          group: 2,
          id: 6,
          title: "Contacto",
          show: false,
        },
        { group: 2, id: 7, title: "Información Contacto", show: false },
        {
          group: 2,
          id: 8,
          title: "Inicio de actividades",
          show: false,
        },
        {
          group: 2,
          id: 9,
          title: "Formalización",
          show: false,
        },
        {
          group: 2,
          id: 10,
          title: "RNT",
          show: false,
        },
        { group: 2, id: 11, title: "Actividades Económicas", show: false },
      ],
      // stepTitles: [
      //   {
      //     group: 1,
      //     label: "Representante legal",
      //     id: 1,
      //     title: "Información del Representante Legal",
      //     show: true,
      //   },
      //   {
      //     group: 1,
      //     id: 2,
      //     title: "Identidad y Pertenencia del Representante Legal",
      //     show: true,
      //   },
      //   {
      //     group: 1,
      //     id: 3,
      //     title: "Contacto Personal del Representante Legal",
      //     show: true,
      //   },

      //   { id: 4, title: "Administración de Sitios Turísticos", show: true },
      //   { id: 5, title: "Registro y Actividades Comerciales de la Empresa", show: true },
      //   { id: 6, title: "Información General del Establecimiento", show: false },
      //   { id: 7, title: "Contacto del Establecimiento", show: false },
      //   { id: 8, title: "Teléfono y Año de Inicio de Actividades", show: false },
      //   {
      //     group: 2,
      //     id: 9,
      //     title: "Documentos y Registro Nacional de Turismo",
      //     show: false,
      //   },
      //   {
      //     group: 2,
      //     id: 10,
      //     title: "Categorías y Subcategorías Registro Nacional de Turismo",
      //     show: false,
      //   },
      //   { group: 2, id: 11, title: "Actividades Económicas Registradas", show: false },
      // ],

      showIndicator: true, // Initially show the indicator
    };
  },
  watch: {
    "form.field_a_doc_type"() {
      this.form.field_a_doc_type_other = null;
    },
    "form.field_a_pop_group"() {
      if (!this.form.field_a_pop_group.includes(7)) {
        this.form.field_a_cultural_identity = [];
      }
    },
    "form.field_a_admin_tour_site"() {
      if (this.form.field_a_admin_tour_site) {
        this.form.field_a_managed_sites = [];
      }
    },

    "form.field_a_active_registration"() {
      this.form.field_a_includes_tourism = null;
      if (this.form.field_a_active_registration == 45) {
        // habilitar los demas titulos de formularios por id en la variable stepTitles
        //En la variable stepTitles debe buscar el id y cambiar el show a true

        const form6 = this.stepTitles.findIndex((step) => step.id === 6);
        const form7 = this.stepTitles.findIndex((step) => step.id === 7);
        const form8 = this.stepTitles.findIndex((step) => step.id === 8);
        const form9 = this.stepTitles.findIndex((step) => step.id === 9);

        this.stepTitles[form6].show = true;
        this.stepTitles[form7].show = true;
        this.stepTitles[form8].show = true;
        this.stepTitles[form9].show = true;

        //LIMPIAR TODOS LOS CAMPOS V-MODEL QUE ESTAN EN ESOS FORMULARIOS
        this.form.field_a_company_nit = null;
        this.form.field_a_company_dv = null;
        this.form.field_a_name_estab = null;
        this.form.field_a_estab_city = null;

        this.form.field_a_company_address = null;
        this.form.field_a_company_email = null;
        this.form.field_a_company_website = null;

        this.form.field_a_company_phones = [];
        this.form.field_a_estab_start_year = null;

        this.form.field_a_operating_docs = [];
        this.form.field_a_operating_docs_other = null;
      } else {
        const form6 = this.stepTitles.findIndex((step) => step.id === 6);
        const form7 = this.stepTitles.findIndex((step) => step.id === 7);
        const form8 = this.stepTitles.findIndex((step) => step.id === 8);
        const form9 = this.stepTitles.findIndex((step) => step.id === 9);

        this.stepTitles[form6].show = false;
        this.stepTitles[form7].show = false;
        this.stepTitles[form8].show = false;
        this.stepTitles[form9].show = false;
        //LIMPIAR TODOS LOS CAMPOS V-MODEL QUE ESTAN EN ESOS FORMULARIOS

        this.form.field_a_company_nit = null;
        this.form.field_a_company_dv = null;
        this.form.field_a_name_estab = null;
        this.form.field_a_estab_city = null;

        this.form.field_a_company_address = null;
        this.form.field_a_company_email = null;
        this.form.field_a_company_website = null;

        this.form.field_a_company_phones = [];
        this.form.field_a_estab_start_year = null;

        this.form.field_a_operating_docs = [];
        this.form.field_a_operating_docs_other = null;
      }
    },
    "form.field_a_includes_tourism"() {
      this.form.field_a_economic_classification = [];
    },

    "form.field_a_operating_docs"() {
      //SI TIENE RUT
      if (this.form.field_a_operating_docs.includes(64)) {
        //MUESTRA
        const form11 = this.stepTitles.findIndex((step) => step.id === 11);
        this.stepTitles[form11].show = true;

        // this.form.field_a_registered_activities = [];
      } else {
        //OCULTA
        const form11 = this.stepTitles.findIndex((step) => step.id === 11);
        this.stepTitles[form11].show = false;
        this.form.field_a_registered_activities = [];
      }

      // SI TIENE RNT
      if (this.form.field_a_operating_docs.includes(67)) {
        // this.form.field_a_national_tourism_reg = null;
        // this.form.field_a_rnt_category = null;
        // this.form.field_a_rnt_subcategory = null;

        const form10 = this.stepTitles.findIndex((step) => step.id === 10);
        this.stepTitles[form10].show = true;
      } else {
        this.form.field_a_national_tourism_reg = null;
        this.form.field_a_rnt_category = null;
        this.form.field_a_rnt_subcategory = null;

        const form10 = this.stepTitles.findIndex((step) => step.id === 10);
        this.stepTitles[form10].show = false;
      }

      // SI TIENE OTRO
      if (this.form.field_a_operating_docs.includes(75)) {
        // this.form.field_a_operating_docs_other = null;
      } else {
        this.form.field_a_operating_docs_other = null;
      }
    },

    "form.field_a_rnt_category"() {
      this.formItems.itemsRntSubcategory = [];
      if (this.form.field_a_rnt_category) {
        this.form.field_a_rnt_subcategory = null;
        this.getOptionsSubcategoryRnt(this.form.field_a_rnt_category);
      }
    },

    stepFormServiceOrder(newVal) {
      if (newVal < this.stepTitles.filter((step) => step.show).length) {
        this.scrollToItem(newVal);
      }

      if (this.stepFormServiceOrder == 7 && this.form.field_a_company_address == null) {
        setTimeout(() => {
          this.initMap();
          this.initAutocomplete();
        }, 2000);
      }
    },
  },
  mounted() {
    this.starryNight();
    this.shootingStars();

    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);

    this.getInfoForm();
  },
  methods: {
    getOptionsSubcategoryRnt(id) {
      let url = process.env.VUE_APP_API_URL;

      axios
        .get(`${url}/forms/rnt/subcategory/${id}`)
        .then((response) => {
          this.formItems.itemsRntSubcategory = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setPlace() {},
    addPhoneNumber() {
      this.form.repre_numero_telefono.push(""); // Agrega un nuevo campo vacío al array
    },
    removePhoneNumber(index) {
      if (this.form.repre_numero_telefono.length > 1) {
        this.form.repre_numero_telefono.splice(index, 1); // Elimina el campo de teléfono en el índice dado
      }
    },

    handleScroll() {
      const container = this.$refs.scrollContainer;
      if (container) {
        this.showIndicator = container.scrollTop === 0; // Muestra el indicador si está en la parte superior

        // Control de las sombras
        this.showTopShadow = container.scrollTop > 0;
        this.showBottomShadow =
          container.scrollTop < container.scrollHeight - container.clientHeight;
      }
    },
    scrollToItem(index) {
      const item = this.$refs["timelineItem" + index][0]; // Acceso directo al primer elemento
      if (item) {
        const container = this.$refs.scrollContainer;
        const itemRect = item.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        // Ajuste adicional para el margen superior
        const offsetTop = itemRect.top + container.scrollTop - containerRect.top - 300; // El valor negativo asegura que el elemento no quede oculto

        container.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
        console.log(
          "Item Rect:",
          itemRect,
          "Container Rect:",
          containerRect,
          "Offset Top:",
          offsetTop
        );
      }
    },

    starryNight() {
      anime({
        targets: ["#sky .star"],
        opacity: [
          { duration: 700, value: "0" },
          { duration: 700, value: "1" },
        ],
        easing: "linear",
        loop: true,
        delay: (el, i) => 50 * i,
      });
    },
    shootingStars() {
      anime({
        targets: ["#shootingstars .wish"],
        easing: "linear",
        loop: true,
        delay: (el, i) => 1000 * i,
        opacity: [{ duration: 700, value: "1" }],
        width: [{ value: "150px" }, { value: "0px" }],
        translateX: 350,
      });
    },
    randomRadius() {
      return Math.random() * 0.7 + 0.6;
    },
    getRandomX() {
      return Math.floor(Math.random() * Math.floor(this.vw)).toString();
    },
    getRandomY() {
      return Math.floor(Math.random() * Math.floor(this.vh)).toString();
    },

    checkMobile() {
      this.isMobile = window.innerWidth < 600;
    },

    initMap() {
      this.geocoder = new google.maps.Geocoder(); // Inicializa el servicio de Geocoder

      this.map = new google.maps.Map(this.$refs.map, {
        center: { lat: 3.432973174626513, lng: -73.24313931951718 },
        zoom: 7,
        disableDefaultUI: true, // Desactivar los controles predeterminados
        zoomControl: false, // Desactivar los controles de zoom
        streetViewControl: false, // Desactivar el control de Street View
        mapTypeControl: false, // Desactivar el control de tipo de mapa
        fullscreenControl: false, // Desactivar el control de pantalla completa
        styles: [
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [
              {
                visibility: "on",
              },
              {
                color: "#e0efef",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "geometry.fill",
            stylers: [
              {
                visibility: "on",
              },
              {
                hue: "#1900ff",
              },
              {
                color: "#c0e8e8",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [
              {
                lightness: 100,
              },
              {
                visibility: "simplified",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit.line",
            elementType: "geometry",
            stylers: [
              {
                visibility: "on",
              },
              {
                lightness: 700,
              },
            ],
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: [
              {
                color: "#7dcdcd",
              },
            ],
          },
        ],
      });
      this.marker = new google.maps.Marker({
        map: this.map,
        position: this.map.getCenter(),
        draggable: true, // Permitir arrastrar el marcador
      });

      // Añadir un listener para el evento click en el mapa
      this.map.addListener("click", (event) => {
        this.setAddressFromCoordinates(event.latLng);
      });

      // Añadir un listener para el evento dragend del marcador
      this.marker.addListener("dragend", (event) => {
        this.setAddressFromCoordinates(event.latLng);
      });
    },
    initAutocomplete() {
      // Asegurarse de que el campo de texto está correctamente referenciado
      const input = this.$refs.addressField.$el.querySelector("input");

      if (input) {
        const autocomplete = new google.maps.places.Autocomplete(input);
        autocomplete.bindTo("bounds", this.map);

        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          if (!place.geometry || !place.geometry.location) {
            return;
          }

          this.form.field_a_company_address = place.formatted_address;
          this.map.setCenter(place.geometry.location);
          this.marker.setPosition(place.geometry.location);
        });
      } else {
        console.error(
          "No se pudo encontrar el campo de entrada para Google Maps Autocomplete."
        );
      }
    },

    setAddressFromCoordinates(latLng) {
      if (this.geocoder) {
        this.geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              this.form.field_a_company_address = results[0].formatted_address;
              this.marker.setPosition(latLng);
              this.map.setCenter(latLng);
            } else {
              console.error("No se encontraron resultados.");
            }
          } else {
            console.error("Error en Geocoder: " + status);
          }
        });
      } else {
        console.error("Geocoder no está inicializado.");
      }
    },

    onPlaceChanged() {
      // This method is to handle manual address entry
    },

    async getInfoForm() {
      try {
        let url = process.env.VUE_APP_API_URL;

        axios
          .get(url + `/forms/entrepreneur-registration/1`)
          .then((response) => {
            this.formConfig = response.data.form_config;
            this.form_relations_question = response.data.form_relations_question;

            if (this.formConfig && this.formConfig.questions) {

              /**
               * field_a_your_age_range
                  field_a_role_activities_perform
               */
              const field_a_your_age_range = this.formConfig.questions.find(
                (q) => q.v_model === "field_a_your_age_range"
              );
              if (field_a_your_age_range) {
                this.formItems.field_a_your_age_range = field_a_your_age_range.options;
              }

              const field_a_role_activities_perform = this.formConfig.questions.find(
                (q) => q.v_model === "field_a_role_activities_perform"
              );
              if (field_a_role_activities_perform) {
                this.formItems.field_a_role_activities_perform =
                  field_a_role_activities_perform.options;
              }

              const field_d_authorize_sensitive_data_processing = this.formConfig.questions.find(
                (q) => q.v_model === "field_d_authorize_sensitive_data_processing"
              );
              if (field_d_authorize_sensitive_data_processing) {
                this.formItems.field_d_authorize_sensitive_data_processing =
                field_d_authorize_sensitive_data_processing.options;
              }

              const field_authorize_whatsApp = this.formConfig.questions.find(
                (q) => q.v_model === "field_authorize_whatsApp"
              );
              if (field_authorize_whatsApp) {
                this.formItems.field_authorize_whatsApp =
                field_authorize_whatsApp.options;
              }


              const field_a_doc_type = this.formConfig.questions.find(
                (q) => q.v_model === "field_a_doc_type"
              );
              if (field_a_doc_type) {
                this.formItems.itemsTypeDocument = field_a_doc_type.options;
              }

              const field_a_rep_sex = this.formConfig.questions.find(
                (q) => q.v_model === "field_a_rep_sex"
              );

              if (field_a_rep_sex) {
                this.formItems.itemsGender = field_a_rep_sex.options;
              }

              const field_a_pop_group = this.formConfig.questions.find(
                (q) => q.v_model === "field_a_pop_group"
              );

              if (field_a_pop_group) {
                this.formItems.itemsPopGroup = field_a_pop_group.options;
              }

              const field_a_cultural_identity = this.formConfig.questions.find(
                (q) => q.v_model === "field_a_cultural_identity"
              );

              if (field_a_cultural_identity) {
                this.formItems.itemsCulturalIdentity = field_a_cultural_identity.options;
              }

              const field_a_admin_tour_site = this.formConfig.questions.find(
                (q) => q.v_model === "field_a_admin_tour_site"
              );

              if (field_a_admin_tour_site) {
                this.formItems.itemsAdminTourSite = field_a_admin_tour_site.options;
              }

              const field_a_managed_sites = this.formConfig.questions.find(
                (q) => q.v_model === "field_a_managed_sites"
              );
              if (field_a_managed_sites) {
                this.formItems.itemsAdminSitesManage = field_a_managed_sites.options;
              }

              const field_a_active_registration = this.formConfig.questions.find(
                (q) => q.v_model === "field_a_active_registration"
              );
              if (field_a_active_registration) {
                this.formItems.itemsActiveRegistration =
                  field_a_active_registration.options;
              }

              const field_a_includes_tourism = this.formConfig.questions.find(
                (q) => q.v_model === "field_a_includes_tourism"
              );
              if (field_a_includes_tourism) {
                this.formItems.itemsIncludesTourism = field_a_includes_tourism.options;
              }

              const field_a_economic_classification = this.formConfig.questions.find(
                (q) => q.v_model === "field_a_economic_classification"
              );
              if (field_a_economic_classification) {
                this.formItems.itemsEconomicClassification =
                  field_a_economic_classification.options;
              }

              const field_a_estab_city = this.formConfig.questions.find(
                (q) => q.v_model === "field_a_estab_city"
              );
              if (field_a_estab_city) {
                this.formItems.itemsEstabCity = field_a_estab_city.options;
              }

              const field_a_operating_docs = this.formConfig.questions.find(
                (q) => q.v_model === "field_a_operating_docs"
              );
              if (field_a_operating_docs) {
                this.formItems.itemsOperatingDocs = field_a_operating_docs.options;
              }

              const field_a_rnt_category = this.formConfig.questions.find(
                (q) => q.v_model === "field_a_rnt_category"
              );
              if (field_a_rnt_category) {
                this.formItems.itemsRntCategory = field_a_rnt_category.options;
              }

              

              const field_a_registered_activities = this.formConfig.questions.find(
                (q) => q.v_model === "field_a_registered_activities"
              );
              if (field_a_registered_activities) {
                this.formItems.itemsRegisteredActivities =
                  field_a_registered_activities.options;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      } finally {
        this.showDeleteConfirmation = false;
      }
    },

    async goToNextStep() {
      if (this.nextStep) {
        //VALIDAR FORMULARIO ACTUAL EN REF formStep1,2,3... etc
        const { valid } = await this.$refs[
          "formStep" + this.stepFormServiceOrder
        ].validate();
        if (valid) {
          this.stepFormServiceOrder = this.nextStep.id;
        }
      }
    },

    async validateAllSteps() {
      let allValid = true;

      // Filtramos los pasos visibles y recorremos su longitud
      const visibleSteps = this.stepTitles.filter((step) => step.show);

      for (let i = 0; i < visibleSteps.length; i++) {
        this.stepFormServiceOrder = visibleSteps[i].id; // Navegar al paso correspondiente
        await this.$nextTick(); // Esperar a que el DOM se actualice y el formulario se monte

        const formRef = this.$refs[`formStep${visibleSteps[i].id}`];

        if (formRef && formRef.validate) {
          const { valid } = await formRef.validate();

          if (!valid) {
            allValid = false;

            // Resaltar los campos con errores
            this.$nextTick(() => {
              const firstErrorField = formRef.$el.querySelector(".v-input--error");
              if (firstErrorField) {
                firstErrorField.scrollIntoView({ behavior: "smooth" });
              }
            });

            break; // Detener la validación en el primer error encontrado
          }
        }
      }

      return allValid;
    },

    async saveForm() {
      this.loadingPageOverlay = true;
      let otherThis = this;
      await setTimeout(async function () {
        const allValid = await otherThis.validateAllSteps();

        if (allValid) {
          otherThis.formResponseStore();
        } else {
          otherThis.loadingPageOverlay = false;
          console.log("Hay errores en algunos de los formularios.");
        }
      }, 1000);
    },

    goToPreviousStep() {
      if (this.previousStep) {
        this.stepFormServiceOrder = this.previousStep.id;
      }
    },
    formResponseStore() {
      let url = process.env.VUE_APP_API_URL;

      axios
        .post(url + "/form-responses/store", {
          form: this.form,
          form_relations_question: this.form_relations_question,
          form_id: 1,
        })
        .then((response) => {
          this.dialogResponse = {
            title: "¡Formulario enviado con éxito!",
            message:
              "Tus respuestas han sido registradas correctamente. Gracias por tomarte el tiempo de completar el formulario. Puedes estar seguro de que tus datos han sido guardados de manera segura.",
            type: "success",
            color: "secondary",
            icon: "mdi-check",
            show: true,
            redirect: false,
          };
          console.log(response);
          this.loadingPageOverlay = false;
          //resetear todo el componente
          this.resetFormFields();
          this.stepFormServiceOrder = 1;
        })
        .catch((error) => {
          this.loadingPageOverlay = false;
          if (error.response.status == 422) {
            //ERROR RETORNADO POR LAS VALIDACIONES
            this.toast.error({
              component: ErrorLayoutComponent,
              props: {
                errorMessage: error.response.data,
              },
              timeout: 8000,
            });
          } else {
            this.dialogResponse = {
              title: "Error al enviar el formulario",
              message:
                "Ocurrió un problema al intentar guardar tus respuestas. Por favor, revisa los campos y vuelve a intentarlo. Si el problema persiste, contacta al soporte técnico.",
              type: "success",
              color: "red-app",
              icon: "mdi-cloud-alert",
              show: true,
              redirect: false,
            };
          }

          console.log(error);
        });
    },
    resetFormFields(){
      this.form = {
        field_a_your_age_range: null,
        field_a_role_activities_perform: [],

        field_a_rep_name: null,
        field_a_doc_type: null,
        field_a_doc_type_other: null,
        field_a_doc_number: null,
        field_d_authorize_sensitive_data_processing: null,
        field_authorize_whatsApp: null,
        field_a_rep_sex: null,
        field_a_pop_group: [],
        field_a_cultural_identity: [],
        field_a_personal_phones: [],
        field_a_personal_email: null,
        field_a_admin_tour_site: null,
        field_a_managed_sites: [],
        field_a_active_registration: null,
        field_a_includes_tourism: null,
        field_a_economic_classification: [],
        field_a_company_nit: null,
        field_a_company_dv: null,
        field_a_name_estab: null,
        field_a_estab_city: null,
        field_a_company_address: null,
        field_a_company_email: null,
        field_a_company_website: null,
        field_a_company_phones: [],
        field_a_estab_start_year: null,
        field_a_operating_docs: [],
        field_a_operating_docs_other: null,
        field_a_national_tourism_reg: null,
        field_a_rnt_category: null,
        field_a_rnt_subcategory: null,
        field_a_registered_activities: [],
      };
      this.stepFormServiceOrder = 1;
    },
    async cancelDialogResponse() {
      this.dialogResponse = null;
    },
  },

  
  beforeUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  },

  computed: {
    currentTitle() {
      const step = this.stepTitles[this.stepFormServiceOrder - 1];
      // Busca el label del grupo al que pertenece el step actual
      const groupLabel = this.stepTitles.find(
        (item) => item.group === step.group && item.label
      )?.label;

      return {
        title: step.title,
        label: groupLabel || "", // Si no se encuentra el label, se asigna una cadena vacía
      };
    },
    randomAnimationClass() {
      // Generar una clase de animación aleatoria
      return `bounce-${Math.floor(Math.random() * 2) + 1}`;
    },
    nextStep() {
      const currentIndex = this.stepTitles.findIndex(
        (step) => step.id === this.stepFormServiceOrder
      );
      return this.stepTitles.slice(currentIndex + 1).find((step) => step.show) || null;
    },
    // Encuentra el paso anterior con show: true
    previousStep() {
      const currentIndex = this.stepTitles.findIndex(
        (step) => step.id === this.stepFormServiceOrder
      );
      return (
        this.stepTitles
          .slice(0, currentIndex)
          .reverse()
          .find((step) => step.show) || null
      );
    },
  },
};
</script>
<style>
.v-field__input {
  padding-top: 9px;
}

/* #sky {
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    margin: 0;
    padding: 0;
} */

/* #shootingstars {
    margin: 0;
    padding: 0;
    width: 150vh;
    height: 100vw;
    position: fixed;
    overflow: hidden;
    transform: translatex(calc(50vw - 50%)) translatey(calc(50vh - 50%)) rotate(120deg);
} */

/* .wish {
    height: 2px;
    top: 300px;
    width: 100px;
    margin: 0;
    opacity: 0;
    padding: 0;
    background-color: white;
    position: absolute;
    background: linear-gradient(-45deg, white, rgba(0, 0, 255, 0));
    filter: drop-shadow(0 0 6px white);
    overflow: hidden;
} */

.v-row {
  margin: 0;
}
</style>

<template>
  <v-container
    class="pa-0"
    style="height: 100%; width: 100%; min-width: 100%; position: relative"
  >
    <NavBarAnimation1
      v-if="isMdOrLower"
      classCustom="animation-option-1 "
      title="Sostenibilidad de Prestadores"
      subTitle="La encuesta de Sostenibilidad prestadores está dirigida únicamente a los PST y administradores de atractivos del departamento, con el fin de tener información actualizada del estado interno de los mismos. Por lo anterior a continuación, se encontrarán preguntas en las temáticas de formalidad, sostenibilidad ambiental y social, accesibilidad, gestión del agua y residuos líquidos, de la energía, de los residuos sólidos, del manejo de la biodiversidad y de la adaptación al cambio climático, entre otras.
        Los datos que se solicitan en este formulario son estrictamente confidenciales y se utilizarán únicamente con fines estadísticos, por lo que no se compartiran con otras entidades como: DIAN, Camara y Comercio, Alcaldias, Autoridades ambientales, etc."
    ></NavBarAnimation1>
    <v-app-bar class="elevation-0" v-if="isMdOrLower">
      <v-slide-group
        v-model="stepFormServiceOrder"
        class="py-4"
        center-active
        show-arrows
      >
        <v-slide-group-item
          v-for="(item, index) in stepTitles.filter((step) => step.show)"
          :key="index"
          v-slot="{ isSelected }"
          :value="item.id"
        >
          <v-list-item
            :color="isSelected ? 'primary' : 'grey-lighten-1'"
            @click="stepFormServiceOrder = index + 1"
          >
            <template v-slot:prepend>
              <v-avatar
                :color="
                  isSelected || stepFormServiceOrder > index + 1
                    ? 'primary'
                    : 'grey-lighten-1'
                "
                class="text-white"
                size="x-small"
              >
                <v-icon size="x-small" v-if="stepFormServiceOrder == index + 1"
                  >mdi-pencil</v-icon
                >
                <span v-else>{{ index + 1 }}</span>
              </v-avatar>
            </template>
            <v-list-item-title
              class="text-caption"
              :class="
                isSelected || stepFormServiceOrder > index + 1 ? 'text-primary' : ''
              "
              >{{ item.title }}</v-list-item-title
            >
          </v-list-item>
        </v-slide-group-item>
      </v-slide-group>
    </v-app-bar>
    <v-row class="h-100 mh-100 auth">
      <v-col
        lg="5"
        xl="4"
        cols="12"
        class="d-lg-flex d-none align-center justify-center bg-primary pa-0"
        style="position: fixed; height: 100%"
      >
        <LeftPanelAnimation1
          classCustom="animation-option-1 "
          title="Sostenibilidad de"
          subTitle="La encuesta de Sostenibilidad prestadores está dirigida únicamente a los PST y administradores de atractivos del departamento, con el fin de tener información actualizada del estado interno de los mismos. Por lo anterior a continuación, se encontrarán preguntas en las temáticas de formalidad, sostenibilidad ambiental y social, accesibilidad, gestión del agua y residuos líquidos, de la energía, de los residuos sólidos, del manejo de la biodiversidad y de la adaptación al cambio climático, entre otras.
              Los datos que se solicitan en este formulario son estrictamente confidenciales y se utilizarán únicamente con fines estadísticos, por lo que no se compartiran con otras entidades como: DIAN, Camara y Comercio, Alcaldias, Autoridades ambientales, etc."
          :wordsAnimate="['Prestadores']"
        ></LeftPanelAnimation1>
      </v-col>
      <v-col lg="7" xl="8" cols="12" style="background: #fff; z-index: 9" class="ml-auto">
        <v-row class="h-100 mh-100">
          <v-col
            lg="5"
            xl="4"
            cols="12"
            class="d-lg-flex d-none align-center justify-center position-relative"
          >
            <div class="scroll-container-wrapper">
              <div
                class="scroll-container position-relative"
                ref="scrollContainer"
                @scroll="handleScroll"
              >
                <div class="item">
                  <div
                    class="mouse m-2"
                    :class="{
                      'animate__animated animate__fadeInDown': showIndicator,
                      'animate__animated animate__fadeOutDown': !showIndicator,
                    }"
                  ></div>
                </div>
                <v-timeline class="py-8" align="start" side="end" :line-thickness="1">
                  <v-timeline-item
                    v-for="(item, index) in stepTitles.filter((step) => step.show)"
                    :key="index"
                    :class="stepFormServiceOrder > item.id - 1 ? 'success-step' : ''"
                    :dot-color="
                      stepFormServiceOrder > item.id - 1 ? 'primary' : 'dot-customer'
                    "
                    size="small"
                  >
                    <template v-slot:opposite>
                      <p class="font-weight-medium text-end">
                        {{ showGroupLabel(item, index) ? item.label : null }}
                      </p>
                    </template>
                    <template v-slot:icon>
                      <v-btn
                        width="20"
                        height="20"
                        @click="stepFormServiceOrder = item.id"
                        size="x-small"
                        icon
                        variant="text"
                      >
                        <v-icon v-if="stepFormServiceOrder == item.id">mdi-pencil</v-icon>
                        <v-icon v-else-if="stepFormServiceOrder > item.id"
                          >mdi-check</v-icon
                        >
                        <span v-else>{{ index + 1 }}</span>
                      </v-btn>
                    </template>
                    <div class="d-flex">
                      <div :ref="'timelineItem' + item.id"></div>
                      <div
                        class="text-left text-body-2"
                        :class="{ 'text-primary': stepFormServiceOrder > item.id }"
                      >
                        {{ item.title }}
                      </div>
                    </div>
                  </v-timeline-item>
                </v-timeline>
              </div>
              <div v-if="showTopShadow" class="top-shadow"></div>
              <div v-if="showBottomShadow" class="bottom-shadow"></div>
            </div>
          </v-col>
          <v-col lg="7" xl="8" cols="12" class="d-flex align-center justify-center pr-6">
            <v-card width="100%" elevation="0">
              <v-card-text>
                <p class="text-h4 font-weight-bold text-primary">
                  {{ currentTitle.label }}
                </p>
                <p class="text-h5 font-weight-bold">
                  {{ currentTitle.title }}
                </p>
              </v-card-text>
              <v-col md="12" cols="12" class="py-1">
                <br />
              </v-col>
              <v-window
                :touch="false"
                direction="vertical"
                v-model="stepFormServiceOrder"
              >
                <v-window-item :value="1">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep1">
                      <v-row>
                        <v-col md="6" cols="12" class="py-1">
                          <span class="font-weight-bold">NIT</span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(50)]"
                            v-model="form.field_a_company_nit"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col md="6" cols="12" class="py-1">
                          <span class="font-weight-bold">DV</span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(50)]"
                            v-model="form.field_a_company_dv"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Cuál es el nombre del representante legal de la
                            empresa?</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.field_a_rep_name"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="2">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep2">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">Nombre del Establecimiento</span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.field_a_name_estab"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">Dirección</span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.field_a_company_address"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">Email </span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.emailFormat]"
                            v-model="form.field_a_company_email"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="3">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep3">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Registro Nacional de Turismo (RNT)</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.field_a_national_tourism_reg"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">Categoría de RNT</span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectSingleComponent
                            v-model="form.field_a_rnt_category"
                            :items="formItems.itemsRntCategory"
                            :validations="[validations.required]"
                          />
                         
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="4">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep4">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Señale las actividades económicas registradas en Cámara de
                            Comercio de este establecimiento</span
                          >
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.field_a_registered_activities"
                            :items="formItems.itemsRegisteredActivities"
                            :validations="[validations.checkboxRequired]"
                          />
                        
                          <!-- si en array selectActivities contiene el valor agregamos la clase selected-check al div -->
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="5">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep5">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >De los siguientes aspectos por favor seleccione los que en su
                            empresa/emprendimiento se hayan realizado, actualizado o
                            tenido en cuenta en el último año
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.impl_aspects_year"
                            :items="formItems.impl_aspects_year"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                        <v-col md="12" cols="12" class="py-1" v-if="form.impl_aspects_year.includes(657)">
                          <span class="font-weight-bold">
                            Seleccionar el tipo de aval o certificación vigente con la que
                            cuenta:
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.cert_type"
                            :items="formItems.cert_type"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="6">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep6">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >En su negocio frente al diseño de planes, paquetes o
                            productos turísticos que se ofrecen, comercializan o
                            implementan, ¿se tiene en cuenta las limitaciones de recursos
                            (agua, energía, alimentos, vías, etc.) que el destino pueda
                            tener para sus habitantes?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.res_limit_considered"
                          >
                            <v-radio
                              v-for="(item, index) in formItems.res_limit_considered"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.res_limit_considered == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1" v-if="form.res_limit_considered == 666">
                          <span class="font-weight-bold">
                            Indique cómo soporta la definición de las limitaciones o no
                            limitaciones de recursos frente al número de habitantes
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.res_support"
                            :items="formItems.res_support"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="7">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep7">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >En su negocio, frente al diseño de planes, paquetes o
                            productos turísticos que se ofrecen, comercializan,
                            implementan, u operan, ¿se verifica qué senderos y otros
                            espacios cuentan con el cálculo de la capacidad de carga?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.capacity_verify"
                          >
                            <v-radio
                              v-for="(item, index) in formItems.capacity_verify"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.capacity_verify == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1" v-if="form.capacity_verify == 671">
                          <span class="font-weight-bold">
                            Indique cómo se determinó la capacidad de carga en los
                            diferentes espacios
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.capacity_calc"
                            :items="formItems.capacity_calc"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="8">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep8">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Señale cuáles de los siguientes aspectos considera que le
                            aporta a su empresa/emprendimiento la implementación de buenas
                            prácticas en sostenibilidad
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.sustain_benefits"
                            :items="formItems.sustain_benefits"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
               
                <v-window-item :value="9">
                  <v-card-text class="text-body-2">
                    <span class="font-weight-bold">
                      Seleccione los tipos de residuos que su empresa/emprendimiento
                      genera y si mide las cantidades generadas.
                    </span>
                    <span class="font-weight-bold">*</span>
                    <v-form ref="formStep9">
                      <v-row>
                        <template v-for="(item, index) in itemsResiduos" :key="index">
                          <v-col
                            md="4"
                            cols="12"
                            class="justify-center align-center d-flex text-h6 text-center"
                          >
                            {{ item.group_name ? item.group_name : "" }}
                          </v-col>
                          <v-col md="8" cols="12">
                            <table style="width: 100%; table-layout: fixed">
                            <tbody>
                              <tr v-for="(itemCheck, indexCheck) in item.items" :key="indexCheck">
                                <td>
                                  <v-checkbox
                                    :label="itemCheck.text"
                                    color="primary"
                                    hide-details
                                    v-model="form[itemCheck.v_model]"
                                    false-value="No"
                                    true-value="Si"
                                  ></v-checkbox>
                                </td>
                                <td>
                                  
                                  <CustomSelectMultimpleComponent
                                    v-if="form[itemCheck.v_model] == 'Si'"
                                    :hidedetails="true"
                                    dens="1"
                                    v-model="form[itemCheck.list]"
                                    :items="formItems[itemCheck.list]"
                                    :validations="[validations.checkboxRequired]"
                                    customv="outlined" 
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          </v-col>
                          <v-divider v-if="index + 1 < itemsResiduos.length"></v-divider>  
                        </template>
                      </v-row>
                     
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="10">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep10">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            En su empresa/emprendimiento, ¿qué tipo de gestión realiza
                            sobre los residuos sólidos aprovechables generados (Cartón y
                            papel; Metales; Plástico; Vidrio; Madera; Textil)?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.recyclable_waste_mgmt"
                            :items="formItems.recyclable_waste_mgmt"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            En su empresa/emprendimiento, ¿qué tipo de gestión realiza
                            sobre los residuos sólidos orgánicos aprovechables generados
                            (Pérdidas de alimentos -por ejemplo, en agroturismo, cosechas
                            que no se recojan-; Residuos de comida; Podas de áreas
                            verdes/jardinería)?
                          </span>
                          <span class="font-weight-bold">*</span>
                          
                          <CustomSelectMultimpleComponent
                            v-model="form.organic_waste_mgmt"
                            :items="formItems.organic_waste_mgmt"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            "En su empresa/emprendimiento, ¿qué tipo de gestión realiza
                            sobre los residuos sólidos con gestión diferenciada generados
                            (Residuos de Aparatos y Equipos Eléctricos y Electrónicos;
                            Residuos de Construcción y Demolición; Bombillas
                            fluorescentes; Envases de plaguicidas; Baterías de plomo
                            ácido; Llantas usadas; Pilas usadas; Medicamentos vencidos),
                            así como el aceite de cocina usado?
                          </span>
                          <span class="font-weight-bold">*</span>
                          
                          <CustomSelectMultimpleComponent
                            v-model="form.special_waste_mgmt"
                            :items="formItems.special_waste_mgmt"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="11">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep11">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            En su empresa/emprendimiento adelanta campaña de
                            sensibilización o capacitación para promover un manejo
                            adecuado de residuos dirigidas a:
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.sensitization_recycling"
                            :items="formItems.sensitization_recycling"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Seleccione, según su empresa/emprendimiento encuentra barreras
                            o limitaciones para gestionar adecuadamente los residuos
                            generados
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.waste_mgmt_barriers"
                            :items="formItems.waste_mgmt_barriers"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="12">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep12">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            En su empresa/emprendimiento qué tipo de fuentes de energía
                            convencional usa:
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.energy_conv_sources"
                            :items="formItems.energy_conv_sources"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            En su empresa/emprendimiento qué tipo de fuentes de energía NO
                            convencional usa:
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.energy_alt_sources"
                            :items="formItems.energy_alt_sources"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            ¿En su empresa/emprendimiento lleva registros sobre los
                            consumos de energía de la operación del negocio?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.energy_tracking"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.energy_tracking"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.energy_tracking == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="13">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep13">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            ¿En su empresa/emprendimiento, ¿cuáles de las siguientes
                            acciones, han implementado para hacer un ahorro y uso
                            eficiente de la energía que se consume?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.energy_efficiency"
                            :items="formItems.energy_efficiency"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            En su empresa/emprendimiento adelanta campaña de
                            sensibilización o capacitación para promover el ahorro y uso
                            eficiente de la energía que consume dirigidas a
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.energy_sensitization"
                            :items="formItems.energy_sensitization"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            ¿En su empresa/emprendimiento, ¿qué barreras ha identificado
                            para hacer un ahorro y uso eficiente de energía en la
                            operación del negocio?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.energy_barriers"
                            :items="formItems.energy_barriers"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="14">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep14">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            En su empresa/emprendimiento, ¿cuáles son las fuentes de dónde
                            proviene el suministro de agua para la operación del negocio?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <div>
                            <CustomSelectMultimpleComponent
                            v-model="form.water_sources"
                            :items="formItems.water_sources"
                            :validations="[validations.checkboxRequired]"
                          />
                          </div>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            En su empresa/emprendimiento, ¿lleva registros sobre los
                            consumos de agua de la operación del negocio?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.water_tracking"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.water_tracking"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.water_tracking == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            En su empresa/emprendimiento, ¿cuáles de las siguientes
                            acciones han implementado para hacer un ahorro y uso eficiente
                            del agua que se consume en la operación del negocio?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <div>
                            <CustomSelectMultimpleComponent
                            v-model="form.water_efficiency"
                            :items="formItems.water_efficiency"
                            :validations="[validations.checkboxRequired]"
                          />
                          </div>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="15">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep15">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            En su empresa/emprendimiento adelanta campaña de
                            sensibilización o capacitación para promover el ahorro y uso
                            eficiente del agua que consume dirigidas a
                          </span>
                          <span class="font-weight-bold">*</span>
                          <div>
                            <CustomSelectMultimpleComponent
                            v-model="form.water_sensitization"
                            :items="formItems.water_sensitization"
                            :validations="[validations.checkboxRequired]"
                          />
                          </div>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            En su empresa/emprendimiento, ¿qué barreras ha identificado
                            para hacer un ahorro y uso eficiente de agua en la operación
                            del negocio?
                            </span>
                          <span class="font-weight-bold">*</span>
                          <div>
                            <CustomSelectMultimpleComponent
                            v-model="form.water_barriers"
                            :items="formItems.water_barriers"
                            :validations="[validations.checkboxRequired]"
                          />
                          </div>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="16">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep16">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            En su empresa/emprendimiento, ¿qué gestión realiza sobre las
                            aguas residuales o vertimientos (por ejemplo, descargas de
                            baños, de lavaplatos, etc.), que se generan?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <div>
                            <CustomSelectMultimpleComponent
                            v-model="form.wastewater_mgmt"
                            :items="formItems.wastewater_mgmt"
                            :validations="[validations.checkboxRequired]"
                          />
                          </div>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            En su empresa/emprendimiento, ¿se está informado respecto a la
                            legislación vigente relacionada con características
                            físico-químicas que se deben cumplir con las aguas residuales
                            antes de hacer cualquier tipo de vertimiento o reuso a
                            sistemas de alcantarillados, suelo o cuerpos de agua (por
                            ejemplo, Decreto 1076 de 2015; Resolución 0699 de 2021, etc.)?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.wastewater_knowledge"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.wastewater_knowledge"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.wastewater_knowledge == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="17">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep17">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            En su empresa/emprendimiento se adelantan campañas de
                            sensibilización o capacitación relacionados con: i) el manejo
                            adecuado de productos químicos y sus envases; ii) evitar el
                            uso de cremas bronceadoras, o champús con químicos; iii) hacer
                            una disposición adecuada del aceite de cocina usado; y/o iv)
                            cumplimiento de la normatividad vigente, para hacer un manejo
                            adecuado de las aguas residuales dirigido a
                          </span>
                          <span class="font-weight-bold">*</span>
                          <div>
                            <CustomSelectMultimpleComponent
                            v-model="form.wastewater_sensitization"
                            :items="formItems.wastewater_sensitization"
                            :validations="[validations.checkboxRequired]"
                          />
                          </div>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            En su empresa/emprendimiento, ¿qué barreras ha identificado
                            para hacer un manejo adecuado de aguas
                            residuales/vertimientos?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <div>
                            <CustomSelectMultimpleComponent
                            v-model="form.wastewater_barriers"
                            :items="formItems.wastewater_barriers"
                            :validations="[validations.checkboxRequired]"
                          />
                          </div>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="18">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep18">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Su empresa/emprendimiento, ¿combina la actividad turística con
                            actividades ganaderas y/o pecuarias?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.agri_tourism"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.agri_tourism"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.agri_tourism == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            ¿En su empresa/emprendimiento tienen conocimiento relacionado
                            con estimar/calcular las emisiones de Gases Efecto Invernadero
                            GEI (causantes del cambio climático) o la huella de carbono, a
                            partir de la operación del negocio?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.ghg_knowledge"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.ghg_knowledge"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.ghg_knowledge == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            ¿Cuáles son las principales fuentes de generación de GEI
                            identificadas en la operación de su negocio?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.ghg_sources"
                            :items="formItems.ghg_sources"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="19">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep19">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Bien sea de manera directa e indirecta, el transporte aéreo,
                            marítimo, fluvial y terrestre se relaciona con la operación de
                            todo tipo de prestador de servicios turísticos, haciendo que
                            la huella de carbono para la empresa o emprendimiento sea muy
                            alta. Por lo tanto, de las siguientes opciones cuáles se
                            implementan desde su empresa o emprendimiento, para compensar
                            las emisiones de GEI de los turistas, empleados/colaboradores,
                            proveedores, o para disminuir o mitigar en general los GEI de
                            toda la operación del negocio?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.ghg_mitigation"
                            :items="formItems.ghg_mitigation"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Teniendo en cuenta que a pesar de los esfuerzos globales, el
                            cambio climático es una realidad, ¿su empresa/emprendimiento
                            conoce medidas que le permiten adaptarse a las nuevas
                            circunstancias que la variabilidad climática implica para el
                            negocio turístico?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.climate_adapt"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.climate_adapt"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.climate_adapt == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            En su empresa/emprendimiento, ¿qué barreras ha identificado
                            para medir, controlar, e implementar programas de reducción
                            y/o compensación de GEI?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.ghg_barriers"
                            :items="formItems.ghg_barriers"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="20">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep20">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Seleccione las fuentes de contaminación de aire que ha
                            identificado en el territorio, que pueden estar afectando la
                            operación de su negocio
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.air_pollution_sources"
                            :items="formItems.air_pollution_sources"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Señale cuales de las siguientes fuentes de contaminación del
                            aire se podrían estar dando desde la operación del negocio, o
                            desde la operación de otros prestadores de servicios
                            turísticos con los que usted trabaja
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.air_pollution_ops"
                            :items="formItems.air_pollution_ops"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="21">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep21">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            La empresa/emprendimiento está ubicado o promueve, o tiene
                            operación en sitios de interés, que se encuentran en áreas de
                            importancia para la conservación de la biodiversidad (Parques
                            Nacional/Regionales Naturales, Reservas Naturales, Reservas
                            Naturales de la Sociedad Civil, Distritos de Manejo Integrado,
                            y otras Áreas protegidas declaradas)
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.biodiv_location"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.biodiv_location"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.biodiv_location == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            En su empresa/emprendimiento adelanta campaña de
                            sensibilización o capacitación relacionados con la
                            conservación de la biodiversidad (por ejemplo, jornadas de
                            siembras y restauración, prevención del tráfico de fauna y
                            flora, apoyo a programas de educación ambiental, etc.),
                            dirigidas a
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.biodiv_campaign"
                            :items="formItems.biodiv_campaign"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            De las siguientes prácticas relacionadas con el manejo de la
                            biodiversidad de fauna, bien sea de manera directa e
                            indirecta, por favor seleccione cuáles se implementan en su
                            empresa/emprendimiento, o por parte de los proveedores de
                            servicios turísticos con los cuales trabaja para la operación
                            del negocio
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.biodiv_practices"
                            :items="formItems.biodiv_practices"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="22">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep22">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Si desde su negocio se trabaja con el aprovechamiento
                            sostenible de especies de flora nativa (por ejemplo frutos
                            amazónicos), ¿se atienden las recomendaciones y normatividad
                            de las instituciones que trabajan en el territorio?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.flora_use"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.flora_use"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.flora_use == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Como parte del producto y/o servicio turístico que su
                            empresa/emprendimiento ofrece, ¿inolucra directamente al
                            visitante en alguna de las siguientes actividades?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.tourist_involvement"
                            :items="formItems.tourist_involvement"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Su empresa /emprendimiento y/o otros prestadores de servicios
                            turísticos con los que trabaja, ¿cuentan con senderos marcados
                            y delimitados (peatonal y vehicular) para evitar afectación a
                            la capa vegetal del suelo, o la afectación a la biodiversidad?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.conservation_trails"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.conservation_trails"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.conservation_trails == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="23">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep23">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            En su empresa/emprendimiento, qué barreras ha identificado
                            para hacer una gestión adecuada de la biodiversidad de fauna?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.animal_mgmt_barriers"
                            :items="formItems.animal_mgmt_barriers"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            En su empresa/emprendimiento, ¿qué barreras ha identificado
                            para hacer una gestión adecuada de la biodiversidad de flora?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.plant_mgmt_barriers"
                            :items="formItems.plant_mgmt_barriers"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="24">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep24">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Señale las fuentes de contaminación de ruido que ha
                            identificado en el territorio, que pueden estar afectando la
                            operación de su negocio
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.noise_sources"
                            :items="formItems.noise_sources"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Señale cuales de las siguientes fuentes de contaminación de
                            ruido se podrían estar generando desde la operación del
                            negocio, o desde la operación de otros prestadores de
                            servicios turísticos con los que usted trabaja
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.noise_ops"
                            :items="formItems.noise_ops"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="25">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep25">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            De las siguientes acciones por favor seleccione las que hayan
                            sido implementadas por su negocio turístico en el último año
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.actions_last_year"
                            :items="formItems.actions_last_year"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Desde su empresa/emprendimiento se realizan inversiones
                            voluntarias en las comunidades del área de operación de mi
                            negocio
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.community_investments"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.community_investments"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.community_investments == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Desde su empresa/emprendimiento se realizan actividades
                            individuales o en conjunto con otras empresas o entidades,
                            para fomentar proyectos de desarrollo local (mejorar las
                            capacidades empresariales, facilitar el acceso al crédito,
                            fomentar emprendimientos familiares independientes, entre
                            otras).
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.collective_activities"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.collective_activities"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.collective_activities == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="26">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep26">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Considera que existe un consenso entre los intereses
                            comerciales de los prestadores de servicios turísticos y los
                            intereses sociales y económicos del destino donde el negocio
                            tiene operación?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.community_programs"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.community_programs"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.community_programs == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            En su empresa/emprendimiento, ¿qué barreras ha identificado
                            para hacer una mejor implementación de prácticas de comercio
                            justo?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.feedback_practices"
                            :items="formItems.feedback_practices"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="27">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep27">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Desde su empresa/emprendimiento, ¿se trabaja de manera directa
                            o indirecta con una organización de turismo comunitario del
                            territorio?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.community_benefits"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.community_benefits"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.community_benefits == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Desde su empresa/emprendimiento, ¿conoce empresas privadas que
                            hayan desarrollado proyectos o que acompañen y apoyen
                            organizaciones de turismo comunitario en su territorio?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.gov_projects"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.gov_projects"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.gov_projects == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Desde su empresa/emprendimiento, ¿se considera que en el
                            territorio se garantiza el desarrollo de un turismo que
                            salvaguarde saberes ancestrales y proteja las culturas de los
                            pueblos étnicos presentes?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.ecotour_partners"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.ecotour_partners"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.ecotour_partners == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="28">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep28">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Desde su empresa/emprendimiento, ¿se considera que se
                            garantiza el acceso a diferentes instancias, así como los
                            mecanismos de participación frente al desarrollo turístico del
                            territorio?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.artisan_support"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.artisan_support"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.artisan_support == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Señale desde su empresa/emprendimiento, ¿se tiene conocimiento
                            como desde los siguientes instrumentos de planificación de
                            actividad turística del territorio?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.trad_knowledge"
                            :items="formItems.trad_knowledge"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Desde su empresa/emprendimiento, ¿se tiene conocimiento o
                            participa en Mesas, Comités u otros espacios donde la
                            institucionalidad del territorio haga parte, y en los cuales
                            se trate el desarrollo turístico y otros temas con los cuales
                            se relaciona (por ejemplo, mesas de planificación turística,
                            mesa de economía circular, nodos de cambio climático, etc.)?
                          </span>
                          <span class="font-weight-bold">*</span>
                           <CustomSelectSingleComponent
                            v-model="form.awareness_programs"
                            :items="formItems.awareness_programs"
                            :validations="[validations.required]"
                          />
                          
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="29">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep29">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Desde su empresa/emprendimiento, ¿tiene conocimiento o
                            participa en reuniones periodicas o espacios establecidos,
                            conformados por las organizaciones de base comunitaria, para
                            tratar temas relacionados con el desarrollo turístico u otros
                            temas con los cuales se relaciona el sector?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectSingleComponent
                            v-model="form.sustainability_training"
                            :items="formItems.sustainability_training"
                            :validations="[validations.required]"
                          />
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            En el marco de la planificación turística del territorio en su
                            empresa/emprendimiento, ¿se tiene conocimiento o ha
                            participado en espacios, mesas, comités, etc., donde las
                            instituciones del orden nacional tienen presencia o
                            representación?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectSingleComponent
                            v-model="form.ecosystem_restoration"
                            :items="formItems.ecosystem_restoration"
                            :validations="[validations.required]"
                          />
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Desde su empresa/emprendimiento por favor señale cuales de los
                            siguientes aspectos son tenidos en cuenta en la planificación
                            turística del territorio
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.fair_trade"
                            :items="formItems.fair_trade"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="30">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep30">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Desde su empresa/emprendimiento, ¿tiene conocimiento sobre
                            cómo generar valor agregado en su negocio y ser más
                            competitivo a través de la implementación de un turismo
                            sostenible (ambiental, socio-cultural y económicamente)?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.local_biz_support"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.local_biz_support"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.local_biz_support == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Desde su empresa/emprendimiento, ¿se considera que en el
                            territorio se tiene acceso a un mayor conocimiento frente a
                            las oportunidades de la innovación y sus alternativas de
                            inversión?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.edu_initiatives"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.edu_initiatives"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.edu_initiatives == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            Desde su empresa/emprendimiento, ¿se considera que se tiene
                            conocimiento frente a las principales tendencias y perfil de
                            visitantes/clientes que se puede atraer con la implementación
                            de un turismo sostenible?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.ethical_tourism"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.ethical_tourism"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.ethical_tourism == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="31">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep31">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">
                            De los siguientes aspectos por favor seleccione los que en su
                            empresa/emprendimiento se hayan realizado, actualizado o
                            tenido en cuenta en el último año
                          </span>
                          <span class="font-weight-bold">*</span>
                          <CustomSelectMultimpleComponent
                            v-model="form.aspects_last_year"
                            :items="formItems.aspects_last_year"
                            :validations="[validations.checkboxRequired]"
                          />
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Considera que ofertar productos o servicios turísticos
                            dirigidos a personas con discapacidad es una oportunidad de
                            mercado para su empresa/emprendimiento
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.disability_opportunity"
                          >
                            <v-radio
                              v-for="(item, index) in formItems.disability_opportunity"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.disability_opportunity == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="32">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep32">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >En el último año ¿Atendió turistas en condición de
                            discapacidad?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.disabled_tourists"
                          >
                            <v-radio
                              v-for="(item, index) in formItems.disabled_tourists"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.disabled_tourists == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.disabled_tourists == 690"
                        >
                          <span class="font-weight-bold">
                            En el último año ¿Cuántos turistas atendió con discapacidad
                            física en el último año?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.disabled_vis_phys"
                            v-numbers-only-app
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col
                          v-if="form.disabled_tourists == 690"
                          md="12"
                          cols="12"
                          class="py-1"
                        >
                          <span class="font-weight-bold">
                            En el último año ¿Cuántos turistas atendió con discapacidad
                            auditiva en el último año?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.disabled_vis_hear"
                            v-numbers-only-app
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.disabled_tourists == 690"
                        >
                          <span class="font-weight-bold">
                            En el último año ¿Cuántos turistas atendió con discapacidad
                            visual en el último año?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.disabled_vis_vis"
                            v-numbers-only-app
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.disabled_tourists == 690"
                        >
                          <span class="font-weight-bold">
                            En el último año ¿Cuántos turistas ha atendido con otras
                            discapacidades?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.disabled_vis_other"
                            v-numbers-only-app
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="33">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep33">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >En el último año ¿tuvo colaboradores/empleados o contrató con
                            personas en condición de discapacidad?
                          </span>
                          <span class="font-weight-bold">*</span>

                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.empl_disabled_last_year"
                          >
                            <v-radio
                              v-for="(item, index) in formItems.empl_disabled_last_year"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.empl_disabled_last_year == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.empl_disabled_last_year == 692"
                        >
                          <span class="font-weight-bold">
                            ¿Cuántos empleados/colaboradores con discapacidad física
                            contrató en el último año?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.empl_phys"
                            v-numbers-only-app
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.empl_disabled_last_year == 692"
                        >
                          <span class="font-weight-bold">
                            ¿Cuántos empleados/colaboradores con discapacidad auditiva
                            contrató en el último año?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.empl_hear"
                            v-numbers-only-app
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.empl_disabled_last_year == 692"
                        >
                          <span class="font-weight-bold">
                            ¿Cuántos empleados/colaboradores con discapacidad visual
                            contrató en el último año?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.empl_vis"
                            v-numbers-only-app
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="form.empl_disabled_last_year == 692"
                        >
                          <span class="font-weight-bold">
                            ¿Cuántos empleados/colaboradores con otra discapacidad
                            contrató en el último año?
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.empl_other"
                            v-numbers-only-app
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

              </v-window>
              <v-card-actions>
                <br />
              </v-card-actions>
              <v-card-actions class="mb-9">
                <v-spacer></v-spacer>
                <v-btn
                  class="text-capitalize"
                  :disabled="stepFormServiceOrder > 1 ? false : true"
                  variant="text"
                  @click="goToPreviousStep"
                >
                  Anterior
                </v-btn>
                <v-btn
                  class="text-capitalize"
                  v-if="
                    stepFormServiceOrder <
                    stepTitles.filter((step) => step.show)[
                      stepTitles.filter((step) => step.show).length - 1
                    ].id
                  "
                  color="primary"
                  variant="text"
                  @click="goToNextStep"
                >
                  Siguiente
                </v-btn>
                <v-btn
                  @click="saveForm"
                  class="text-capitalize"
                  v-else
                  color="primary"
                  variant="flat"
                >
                  Finalizar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <!-- <v-col cols="12" class="d-flex align-center justify-center"></v-col> -->
        </v-row>
      </v-col>
    </v-row>
    <v-overlay
      contained
      persistent
      :close-on-back="false"
      :close-on-content-click="false"
      class="align-center justify-center position-fixed"
      scrim="#FFF"
      :opacity="1"
      v-model="loadingPageOverlay"
    >
      <div class="text-center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <h4 class="text-h4 mt-4 font-weight-medium text-primary">Enviando formulario</h4>
        <p class="text-h6 font-weight-medium mt-2">
          Por favor, espera mientras procesamos tu solicitud.
        </p>
      </div>
    </v-overlay>
    <UserExperience></UserExperience>
    <DialogResponse
      @cancel="cancelDialogResponse"
      v-if="dialogResponse"
      :dialog="dialogResponse"
    ></DialogResponse>
  </v-container>
</template>
<script>
import { useDisplay } from "vuetify";
import validationFields from "../functions/validationFields";
import LeftPanelAnimation1 from "./includes/LeftPanelAnimation1.vue";
import NavBarAnimation1 from "./includes/NavBarAnimation1.vue";
import UserExperience from "./includes/UserExperience.vue";
import axios from "axios";
import ErrorLayoutComponent from "@/components/error-request/ErrorLayoutComponent.vue";
import DialogResponse from "@/components/dialog/DialogResponse.vue";

import CustomSelectMultimpleComponent from "@/components/forms/CustomSelectMultimpleComponent.vue";
import CustomSelectSingleComponent from "@/components/forms/CustomSelectSingleComponent.vue";

export default {
  name: "FormSustainability",
  mixins: [validationFields],
  components: {
    LeftPanelAnimation1,
    NavBarAnimation1,
    UserExperience,
    DialogResponse,
    CustomSelectMultimpleComponent,
    CustomSelectSingleComponent
  },

  setup() {
    const { mdAndDown } = useDisplay();

    return {
      isMdOrLower: mdAndDown,
    };
  },

  data() {
    return {
      itemsResiduos: [
        {
          group_name: "Residuos sólidos aprovechables",
          items: [
            {
              text: "Cartón y papel",
              v_model: "waste_paper_type",
              list: "waste_paper_weight",
            },
            {
              text: "Metal",
              v_model: "waste_metal_type",
              list: "waste_metal_weight",
            },
            {
              text: "Plástico",
              v_model: "waste_plastic_type",
              list: "waste_plastic_weight",
            },
            {
              text: "Vidrio",
              v_model: "waste_glass_type",
              list: "waste_glass_weight",
            },
            {
              text: "Madera",
              v_model: "waste_wood_type",
              list: "waste_wood_weight",
            },
            {
              text: "Textil",
              v_model: "waste_textile_type",
              list: "waste_textile_weight",
            },
          ],
        },
        {
          items: [
            {
              text: "Residuos sólidos no aprovechables",
              description:
                "(por ejemplo, elementos sanitarios, residuos de barrido, colillas de cigarrillo, etc.)",
              v_model: "waste_nonrec_type",
              list: "waste_nonrec_weight",
            },
          ],
        },
        {
          group_name: "Residuos sólidos orgánicos",
          items: [
            {
              text: "Pérdida de alimentos",
              v_model: "waste_food_loss_type",
              list: "waste_food_loss_weight",
            },
            {
              text: "Residuos de comida",
              v_model: "waste_food_type",
              list: "waste_food_weight",
            },
            {
              text: "Podas de áreas verdes/jardinería",
              v_model: "waste_green_type",
              list: "waste_green_weight",
            },
          ],
        },
        {
          group_name: "Residuos sólidos con gestión diferenciada/programas posconsumo",
          items: [
            {
              text: "Residuos de Aparatos y Equipos Eléctricos y Electrónicos",
              v_model: "waste_elec_type",
              list: "waste_elec_weight",
            },
            {
              text: "Residuos de Construcción y Demolición",
              v_model: "waste_constr_type",
              list: "waste_constr_weight",
            },
            {
              text: "Bombillas fluorescentes",
              v_model: "waste_fluor_type",
              list: "waste_fluor_weight",
            },
            {
              text: "Envases de plaguicidas",
              v_model: "waste_pesticide_type",
              list: "waste_pesticide_weight",
            },
            {
              text: "Baterías de plomo ácido",
              v_model: "waste_lead_batt_type",
              list: "waste_lead_batt_weight",
            },
            {
              text: "Llantas usadas",
              v_model: "waste_tires_type",
              list: "waste_tires_weight",
            },
            {
              text: "Pilas usadas",
              v_model: "waste_batt_type",
              list: "waste_batt_weight",
            },
            {
              text: "Medicamentos vencidos",
              v_model: "waste_med_type",
              list: "waste_med_weight",
            },
          ],
        },
        {
          items: [
            {
              text: "Aceite de cocina usado",
              v_model: "waste_cook_oil_type",
              list: "waste_cook_oil_weight",
            },
          ],
        },
      ],
      showTopShadow: false,
      showBottomShadow: true,
      dialogResponse: null,
      loadingPageOverlay: false,
      formItems: {
        itemsRntCategory: [],
        itemsRegisteredActivities: [],
        impl_aspects_year: [],
        cert_type: [],
        res_limit_considered: [],
        res_support: [],
        capacity_verify: [],
        capacity_calc: [],
        sustain_benefits: [],
        aspects_last_year: [],
        disability_opportunity: [],
        disabled_tourists: [],
        empl_disabled_last_year: [],
        waste_paper_weight: [],
        waste_metal_weight: [],
        waste_plastic_weight: [],
        waste_glass_weight: [],
        waste_wood_weight: [],
        waste_textile_weight: [],
        waste_nonrec_weight: [],
        waste_food_loss_weight: [],
        waste_food_weight: [],
        waste_green_weight: [],
        waste_elec_weight: [],
        waste_constr_weight: [],
        waste_fluor_weight: [],
        waste_pesticide_weight: [],
        waste_lead_batt_weight: [],
        waste_tires_weight: [],
        waste_batt_weight: [],
        waste_med_weight: [],
        waste_cook_oil_weight: [],
        recyclable_waste_mgmt: [],
        organic_waste_mgmt: [],
        special_waste_mgmt: [],
        sensitization_recycling: [],
        waste_mgmt_barriers: [],
        energy_conv_sources: [],
        energy_alt_sources: [],
        energy_tracking: [],
        energy_efficiency: [],
        energy_sensitization: [],
        energy_barriers: [],
        water_sources: [],
        water_tracking: [],
        water_efficiency: [],
        water_sensitization: [],
        water_barriers: [],
        wastewater_mgmt: [],
        wastewater_knowledge: [],
        wastewater_sensitization: [],
        wastewater_barriers: [],
        agri_tourism: [],
        ghg_knowledge: [],
        ghg_sources: [],
        ghg_mitigation: [],
        climate_adapt: [],
        ghg_barriers: [],
        air_pollution_sources: [],
        air_pollution_ops: [],
        biodiv_location: [],
        biodiv_campaign: [],
        biodiv_practices: [],
        flora_use: [],
        tourist_involvement: [],
        conservation_trails: [],
        animal_mgmt_barriers: [],
        plant_mgmt_barriers: [],
        noise_sources: [],
        noise_ops: [],
        actions_last_year: [],
        community_investments: [],
        collective_activities: [],
        community_programs: [],
        feedback_practices: [],
        community_benefits: [],
        gov_projects: [],
        ecotour_partners: [],
        artisan_support: [],
        trad_knowledge: [],
        awareness_programs: [],
        sustainability_training: [],
        ecosystem_restoration: [],
        fair_trade: [],
        local_biz_support: [],
        edu_initiatives: [],
        ethical_tourism: [],
      },
      stepFormServiceOrder: 1,
      form: {
        field_a_company_nit: null,
        field_a_company_dv: null,
        field_a_rep_name: null,
        field_a_name_estab: null,
        field_a_company_address: null,
        field_a_company_email: null,
        field_a_national_tourism_reg: null,
        field_a_rnt_category: null,
        field_a_registered_activities: [],
        impl_aspects_year: [],
        cert_type: [],
        res_limit_considered: null,
        res_support: [],
        capacity_verify: null,
        capacity_calc: [],
        sustain_benefits: [],
        aspects_last_year: [],
        disability_opportunity: null,
        disabled_tourists: null,
        disabled_vis_phys: null,
        disabled_vis_hear: null,
        disabled_vis_vis: null,
        disabled_vis_other: null,
        empl_disabled_last_year: null,
        empl_phys: null,
        empl_hear: null,
        empl_vis: null,
        empl_other: null,
        waste_paper_type: "No",
        waste_paper_weight: [],
        waste_metal_type: "No",
        waste_metal_weight: [],
        waste_plastic_type: "No",
        waste_plastic_weight: [],
        waste_glass_type: "No",
        waste_glass_weight: [],
        waste_wood_type: "No",
        waste_wood_weight: [],
        waste_textile_type: "No",
        waste_textile_weight: [],
        waste_nonrec_type: "No",
        waste_nonrec_weight: [],
        waste_food_loss_type: "No",
        waste_food_loss_weight: [],
        waste_food_type: "No",
        waste_food_weight: [],
        waste_green_type: "No",
        waste_green_weight: [],
        waste_elec_type: "No",
        waste_elec_weight: [],
        waste_constr_type: "No",
        waste_constr_weight: [],
        waste_fluor_type: "No",
        waste_fluor_weight: [],
        waste_pesticide_type: "No",
        waste_pesticide_weight: [],
        waste_lead_batt_type: "No",
        waste_lead_batt_weight: [],
        waste_tires_type: "No",
        waste_tires_weight: [],
        waste_batt_type: "No",
        waste_batt_weight: [],
        waste_med_type: "No",
        waste_med_weight: [],
        waste_cook_oil_type: "No",
        waste_cook_oil_weight: [],
        recyclable_waste_mgmt: [],
        organic_waste_mgmt: [],
        special_waste_mgmt: [],
        sensitization_recycling: [],
        waste_mgmt_barriers: [],
        energy_conv_sources: [],
        energy_alt_sources: [],
        energy_tracking: null,
        energy_efficiency: [],
        energy_sensitization: [],
        energy_barriers: [],
        water_sources: [],
        water_tracking: null,
        water_efficiency: [],
        water_sensitization: [],
        water_barriers: [],
        wastewater_mgmt: [],
        wastewater_knowledge: null,
        wastewater_sensitization: [],
        wastewater_barriers: [],
        agri_tourism: null,
        ghg_knowledge: null,
        ghg_sources: [],
        ghg_mitigation: [],
        climate_adapt: null,
        ghg_barriers: [],
        air_pollution_sources: [],
        air_pollution_ops: [],
        biodiv_location: null,
        biodiv_campaign: [],
        biodiv_practices: [],
        flora_use: null,
        tourist_involvement: [],
        conservation_trails: null,
        animal_mgmt_barriers: [],
        plant_mgmt_barriers: [],
        noise_sources: [],
        noise_ops: [],
        actions_last_year: [],
        community_investments: null,
        collective_activities: null,
        community_programs: null,
        feedback_practices: [],
        community_benefits: null,
        gov_projects: null,
        ecotour_partners: null,
        artisan_support: null,
        trad_knowledge: [],
        awareness_programs: null,
        sustainability_training: null,
        ecosystem_restoration: null,
        fair_trade: [],
        local_biz_support: null,
        edu_initiatives: null,
        ethical_tourism: null,
      },

      isMobile: false,

      num: 120,
      vw: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
      vh: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),

    
      drawerStep: true,
      marketplace: "",
      stepTitles: [
        {
          group: 1,
          label: "Datos de la Empresa",
          id: 1,
          title: "Datos Básicos",
          show: true,
        },
        {
          group: 1,
          label: "Datos de la Empresa",
          id: 2,
          title: "Ubicación y contacto",
          show: true,
        },
        {
          group: 1,
          label: "Datos de la Empresa",
          id: 3,
          title: "Registro Nacional de Turismo",
          show: true,
        },
        {
          group: 1,
          label: "Datos de la Empresa",
          id: 4,
          title: "Cámara de Comercio",
          show: true,
        },
        {
          group: 2,
          id: 5,
          label: "Sostenibilidad y Gestión en Turismo",
          title: "Actualizaciones y Avales",
          show: true,
        },
        {
          group: 2,
          id: 6,
          label: "Sostenibilidad y Gestión en Turismo",
          title: "Gestión de Recursos en Productos Turísticos",
          show: true,
        },
        {
          group: 2,
          id: 7,
          label: "Sostenibilidad y Gestión en Turismo",
          title: "Verificación de Capacidad de Carga en Espacios Turísticos",
          show: true,
        },
        {
          group: 2,
          id: 8,
          label: "Sostenibilidad y Gestión en Turismo",
          title: "Impacto de Buenas Prácticas en Sostenibilidad",
          show: true,
        },
// aqui estab los 9, 10 y 11
        
        {
          group: 4,
          id: 9,
          label: "Gestión Sobre Residuos",
          title: "Residuos en la Empresa",
          show: true,
        },
        {
          group: 4,
          id: 10,
          label: "Gestión Sobre Residuos",
          title: "Manejo de Residuos",
          show: true,
        },
        {
          group: 4,
          id: 11,
          label: "Gestión Sobre Residuos",
          title: "Capacitación y Barreras en Gestión",
          show: true,
        },
        {
          group: 5,
          id: 12,
          label: "Ahorro y Uso Eficiente de Energía",
          title: "Información sobre energía en su empresa",
          show: true,
        },
        {
          group: 5,
          id: 13,
          label: "Ahorro y Uso Eficiente de Energía",
          title: "Medidas Implementadas y Desafíos en Eficiencia Energética",
          show: true,
        },

        {
          group: 6,
          id: 14,
          label: "Uso Eficiente de Agua",
          title: "Fuentes de Abastecimiento, Registro y Ahorro de Agua",
          show: true,
        },
        {
          group: 6,
          id: 15,
          label: "Uso Eficiente de Agua",
          title: "Capacitación y Desafíos en Ahorro de Agua",
          show: true,
        },
        {
          group: 7,
          id: 16,
          label: "Gestión y Manejo de Aguas Residuales",
          title: "Manejo y Normativa de Aguas Residuales",
          show: true,
        },
        {
          group: 7,
          id: 17,
          label: "Gestión y Manejo de Aguas Residuales",
          title: "Sensibilización y Retos",
          show: true,
        },
        {
          group: 8,
          id: 18,
          label: "Gases Efecto Invernadero",
          title: "Garantías de Seguridad de Empleados y Clientes",
          show: true,
        },
        {
          group: 8,
          id: 19,
          label: "Gases Efecto Invernadero",
          title: "Medidas y Desafíos en la Reducción de Emisiones GEI",
          show: true,
        },
        {
          group: 9,
          id: 20,
          label: "Contaminación en el Aire",
          title: "Fuentes de Contaminación del Aire",
          show: true,
        },
        {
          group: 10,
          id: 21,
          label: "Cuidado de la Biodiversidad",
          title: "Gestión y Sensibilización Ambiental",
          show: true,
        },
        {
          group: 10,
          id: 22,
          label: "Cuidado de la Biodiversidad",
          title: "Actividades y Normativas Ambientales",
          show: true,
        },
        {
          group: 10,
          id: 23,
          label: "Cuidado de la Biodiversidad",
          title: "Identificación de Obstáculos",
          show: true,
        },
        {
          group: 11,
          id: 24,
          label: "Contaminación Acústica",
          title: "Identificación de Obstáculos",
          show: true,
        },
        {
          group: 12,
          id: 25,
          label: "Comercio Justo",
          title: "Fomento del Desarrollo Local",
          show: true,
        },
        {
          group: 12,
          id: 26,
          label: "Comercio Justo",
          title: "Desafíos para el Comercio Justo",
          show: true,
        },
        {
          group: 13,
          id: 27,
          label: "Turismo Comunitario",
          title: "Apoyo y Protección de la Cultura Local",
          show: true,
        },
        {
          group: 14,
          id: 28,
          label: "Gobernanza",
          title: "Acceso a Instancias y Espacios de Desarrollo",
          show: true,
        },
        {
          group: 14,
          id: 29,
          label: "Gobernanza",
          title: "Conocimiento y Colaboración en Espacios Comunitarios y Nacionales",
          show: true,
        },
        {
          group: 14,
          id: 30,
          label: "Gobernanza",
          title: "Conocimiento de Innovación, Valor Agregado y Tendencias de Mercado",
          show: true,
        },

        
        {
          group: 3,
          id: 31,
          label: "Inclusión y Accesibilidad Empresarial",
          title: "Aspectos Empresariales y Oportunidades Inclusivas",
          show: true,
        },
        {
          group: 3,
          id: 32,
          label: "Inclusión y Accesibilidad Empresarial",
          title: "Atención a Turistas con Discapacidad",
          show: true,
        },
        {
          group: 3,
          id: 33,
          label: "Inclusión y Accesibilidad Empresarial",
          title: "Contratación de Colaboradores con Discapacidad",
          show: true,
        },

      ],

      showIndicator: true, // Initially show the indicator
    };
  },
  watch: {
    stepFormServiceOrder(newVal) {
      if (newVal < this.stepTitles.length) {
        this.scrollToItem(newVal);
      }
    },

    "form.impl_aspects_year"(){
      if (!this.form.impl_aspects_year.includes(657)) {
        this.form.cert_type = [];
      }
    },
    "form.res_limit_considered"(){
      if(this.form.res_limit_considered != 666){
        this.form.res_support = [];
      }
    },
    "form.capacity_verify"(){
      if(this.form.capacity_verify != 671){
        this.form.capacity_calc = [];
      }
    },
    "form.disabled_tourists"(){
      if(this.form.disabled_tourists != 690){
        this.form.disabled_vis_phys = null;
        this.form.disabled_vis_hear = null;
        this.form.disabled_vis_vis = null;
        this.form.disabled_vis_other = null;
      }
    },
    "form.empl_disabled_last_year"(){
      if(this.form.empl_disabled_last_year != 692){
        this.form.empl_phys = null;
        this.form.empl_hear = null;
        this.form.empl_vis = null;
        this.form.empl_other = null;
      }
    },
    "form.waste_paper_type"(){
      if(this.form.waste_paper_type != "Si"){
        this.form.waste_paper_weight = [];
      }
    },
    "form.waste_metal_type"(){
      if(this.form.waste_metal_type != "Si"){
        this.form.waste_metal_weight = [];
      }
    },
    "form.waste_plastic_type"(){
      if(this.form.waste_plastic_type != "Si"){
        this.form.waste_plastic_weight = [];
      }
    },
    "form.waste_glass_type"(){
      if(this.form.waste_glass_type != "Si"){
        this.form.waste_glass_weight = [];
      }
    },
    "form.waste_wood_type"(){
      if(this.form.waste_wood_type != "Si"){
        this.form.waste_wood_weight = [];
      }
    },
    "form.waste_textile_type"(){
      if(this.form.waste_textile_type != "Si"){
        this.form.waste_textile_weight = [];
      }
    },
    "form.waste_nonrec_type"(){
      if(this.form.waste_nonrec_type != "Si"){
        this.form.waste_nonrec_weight = [];
      }
    },
    "form.waste_food_loss_type"(){
      if(this.form.waste_food_loss_type != "Si"){
        this.form.waste_food_loss_weight = [];
      }
    },
    "form.waste_food_type"(){
      if(this.form.waste_food_type != "Si"){
        this.form.waste_food_weight = [];
      }
    },
    "form.waste_green_type"(){
      if(this.form.waste_green_type != "Si"){
        this.form.waste_green_weight = [];
      }
    },
    "form.waste_elec_type"(){
      if(this.form.waste_elec_type != "Si"){
        this.form.waste_elec_weight = [];
      }
    },
    "form.waste_constr_type"(){
      if(this.form.waste_constr_type != "Si"){
        this.form.waste_constr_weight = [];
      }
    },
    "form.waste_fluor_type"(){
      if(this.form.waste_fluor_type != "Si"){
        this.form.waste_fluor_weight = [];
      }
    },
    "form.waste_pesticide_type"(){
      if(this.form.waste_pesticide_type != "Si"){
        this.form.waste_pesticide_weight = [];
      }
    },
    "form.waste_lead_batt_type"(){
      if(this.form.waste_lead_batt_type != "Si"){
        this.form.waste_lead_batt_weight = [];
      }
    },
    "form.waste_tires_type"(){
      if(this.form.waste_tires_type != "Si"){
        this.form.waste_tires_weight = [];
      }
    },
    "form.waste_batt_type"(){
      if(this.form.waste_batt_type != "Si"){
        this.form.waste_batt_weight = [];
      }
    },
    "form.waste_med_type"(){
      if(this.form.waste_med_type != "Si"){
        this.form.waste_med_weight = [];
      }
    },
    "form.waste_cook_oil_type"(){
      if(this.form.waste_cook_oil_type != "Si"){
        this.form.waste_cook_oil_weight = [];
      }
    },

  },
  methods: {
    async saveForm() {
      this.loadingPageOverlay = true;
      let otherThis = this;
      await setTimeout(async function () {
        const allValid = await otherThis.validateAllSteps();

        if (allValid) {
          otherThis.formResponseStore();
        } else {
          otherThis.loadingPageOverlay = false;
          console.log("Hay errores en algunos de los formularios.");
        }
      }, 1000);
    },
    async validateAllSteps() {
      let allValid = true;

      // Filtramos los pasos visibles y recorremos su longitud
      const visibleSteps = this.stepTitles.filter((step) => step.show);

      for (let i = 0; i < visibleSteps.length; i++) {
        this.stepFormServiceOrder = visibleSteps[i].id; // Navegar al paso correspondiente
        await this.$nextTick(); // Esperar a que el DOM se actualice y el formulario se monte

        const formRef = this.$refs[`formStep${visibleSteps[i].id}`];

        if (formRef && formRef.validate) {
          const { valid } = await formRef.validate();

          if (!valid) {
            allValid = false;

            // Resaltar los campos con errores
            this.$nextTick(() => {
              const firstErrorField = formRef.$el.querySelector(".v-input--error");
              if (firstErrorField) {
                firstErrorField.scrollIntoView({ behavior: "smooth" });
              }
            });

            break; // Detener la validación en el primer error encontrado
          }
        }
      }

      return allValid;
    },
    formResponseStore() {
      let url = process.env.VUE_APP_API_URL;

      axios
        .post(url + "/form-responses/store", {
          form: this.form,
          form_relations_question: this.form_relations_question,
          form_id: 11,
          user_id: this.$store.state.user.id,
          season_id: this.season_id,
        })
        .then((response) => {
          this.dialogResponse = {
            title: "¡Formulario enviado con éxito!",
            message:
              "Tus respuestas han sido registradas correctamente. Gracias por tomarte el tiempo de completar el formulario. Puedes estar seguro de que tus datos han sido guardados de manera segura.",
            type: "success",
            color: "secondary",
            icon: "mdi-check",
            show: true,
            redirect: false,
          };
          console.log(response);
          this.loadingPageOverlay = false;
          //resetear todo el componente
          this.resetFormFields();
          this.stepFormServiceOrder = 1;
        })
        .catch((error) => {
          this.loadingPageOverlay = false;
          if (
            error &&
            error.response &&
            error.response.status &&
            error.response.status == 422
          ) {
            //ERROR RETORNADO POR LAS VALIDACIONES
            this.toast.error({
              component: ErrorLayoutComponent,
              props: {
                errorMessage: error.response.data,
              },
              timeout: 8000,
            });
          } else {
            this.dialogResponse = {
              title: "Error al enviar el formulario",
              message:
                "Ocurrió un problema al intentar guardar tus respuestas. Por favor, revisa los campos y vuelve a intentarlo. Si el problema persiste, contacta al soporte técnico.",
              type: "success",
              color: "red-app",
              icon: "mdi-cloud-alert",
              show: true,
              redirect: false,
            };
          }

          console.log(error);
        });
    },
    resetFormFields() {
      //restablecer valores por defecto
      this.form = {
        field_a_company_nit: null,
        field_a_company_dv: null,
        field_a_rep_name: null,
        field_a_name_estab: null,
        field_a_company_address: null,
        field_a_company_email: null,
        field_a_national_tourism_reg: null,
        field_a_rnt_category: null,
        field_a_registered_activities: [],
        impl_aspects_year: [],
        cert_type: [],
        res_limit_considered: null,
        res_support: [],
        capacity_verify: null,
        capacity_calc: [],
        sustain_benefits: [],
        aspects_last_year: [],
        disability_opportunity: null,
        disabled_tourists: null,
        disabled_vis_phys: null,
        disabled_vis_hear: null,
        disabled_vis_vis: null,
        disabled_vis_other: null,
        empl_disabled_last_year: null,
        empl_phys: null,
        empl_hear: null,
        empl_vis: null,
        empl_other: null,
        waste_paper_type: "No",
        waste_paper_weight: [],
        waste_metal_type: "No",
        waste_metal_weight: [],
        waste_plastic_type: "No",
        waste_plastic_weight: [],
        waste_glass_type: "No",
        waste_glass_weight: [],
        waste_wood_type: "No",
        waste_wood_weight: [],
        waste_textile_type: "No",
        waste_textile_weight: [],
        waste_nonrec_type: "No",
        waste_nonrec_weight: [],
        waste_food_loss_type: "No",
        waste_food_loss_weight: [],
        waste_food_type: "No",
        waste_food_weight: [],
        waste_green_type: "No",
        waste_green_weight: [],
        waste_elec_type: "No",
        waste_elec_weight: [],
        waste_constr_type: "No",
        waste_constr_weight: [],
        waste_fluor_type: "No",
        waste_fluor_weight: [],
        waste_pesticide_type: "No",
        waste_pesticide_weight: [],
        waste_lead_batt_type: "No",
        waste_lead_batt_weight: [],
        waste_tires_type: "No",
        waste_tires_weight: [],
        waste_batt_type: "No",
        waste_batt_weight: [],
        waste_med_type: "No",
        waste_med_weight: [],
        waste_cook_oil_type: "No",
        waste_cook_oil_weight: [],
        recyclable_waste_mgmt: [],
        organic_waste_mgmt: [],
        special_waste_mgmt: [],
        sensitization_recycling: [],
        waste_mgmt_barriers: [],
        energy_conv_sources: [],
        energy_alt_sources: [],
        energy_tracking: null,
        energy_efficiency: [],
        energy_sensitization: [],
        energy_barriers: [],
        water_sources: [],
        water_tracking: null,
        water_efficiency: [],
        water_sensitization: [],
        water_barriers: [],
        wastewater_mgmt: [],
        wastewater_knowledge: null,
        wastewater_sensitization: [],
        wastewater_barriers: [],
        agri_tourism: null,
        ghg_knowledge: null,
        ghg_sources: [],
        ghg_mitigation: [],
        climate_adapt: null,
        ghg_barriers: [],
        air_pollution_sources: [],
        air_pollution_ops: [],
        biodiv_location: null,
        biodiv_campaign: [],
        biodiv_practices: [],
        flora_use: null,
        tourist_involvement: [],
        conservation_trails: null,
        animal_mgmt_barriers: [],
        plant_mgmt_barriers: [],
        noise_sources: [],
        noise_ops: [],
        actions_last_year: [],
        community_investments: null,
        collective_activities: null,
        community_programs: null,
        feedback_practices: [],
        community_benefits: null,
        gov_projects: null,
        ecotour_partners: null,
        artisan_support: null,
        trad_knowledge: [],
        awareness_programs: null,
        sustainability_training: null,
        ecosystem_restoration: null,
        fair_trade: [],
        local_biz_support: null,
        edu_initiatives: null,
        ethical_tourism: null,
      };

      this.stepFormServiceOrder = 1;
    },

    calculateRowSuma(models) {
      console.log(models);

      let suma = 0;
      models.forEach((model) => {
        suma += parseInt(this.form[model.model]);
      });
      return suma;
    },

    calculateColumnSuma(rows, index) {
      console.log(rows, index);
      //en esta funcion lo que quiero es realizar la suma en columna de los valores de los campos de la tabla
      //para ello recibo el objeto row que contiene los campos de la tabla

      let suma = 0;
      rows.forEach((row) => {
        if (row.fields) {
          suma += parseInt(this.form[row.fields[index].model]);
        }
      });

      return suma;
    },

    handleScroll() {
      const container = this.$refs.scrollContainer;
      if (container) {
        this.showIndicator = container.scrollTop === 0; // Muestra el indicador si está en la parte superior

        // Control de las sombras
        this.showTopShadow = container.scrollTop > 0;
        this.showBottomShadow =
          container.scrollTop < container.scrollHeight - container.clientHeight;
      }
    },
    scrollToItem(index) {
      const item = this.$refs["timelineItem" + index][0]; // Acceso directo al primer elemento
      if (item) {
        const container = this.$refs.scrollContainer;
        const itemRect = item.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        // Ajuste adicional para el margen superior
        const offsetTop = itemRect.top + container.scrollTop - containerRect.top - 300; // El valor negativo asegura que el elemento no quede oculto

        container.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
        console.log(
          "Item Rect:",
          itemRect,
          "Container Rect:",
          containerRect,
          "Offset Top:",
          offsetTop
        );
      }
    },

    checkMobile() {
      this.isMobile = window.innerWidth < 600;
    },

    showGroupLabel(item, index) {
      // Verifica si es el primer paso del grupo visible
      return index === 0 || item.group !== this.visibleSteps[index - 1].group;
    },

    goToPreviousStep() {
      if (this.previousStep) {
        this.stepFormServiceOrder = this.previousStep.id;
      }
    },
    async goToNextStep() {
      if (this.nextStep) {
        //VALIDAR FORMULARIO ACTUAL EN REF formStep1,2,3... etc
        const { valid } = await this.$refs[
          "formStep" + this.stepFormServiceOrder
        ].validate();
        if (valid) {
          this.stepFormServiceOrder = this.nextStep.id;
        }
      }
    },

    async getInfoForm() {
      try {
        let url = process.env.VUE_APP_API_URL;
        const token = this.$store.state.token;

        axios
          .get(url + `/forms/show/11`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            this.formConfig = response.data.form_config;
            this.form_relations_question = response.data.form_relations_question;

            if (this.formConfig && this.formConfig.questions) {
              const field_a_rnt_category = this.formConfig.questions.find(
                (q) => q.v_model === "field_a_rnt_category"
              );
              if (field_a_rnt_category) {
                this.formItems.itemsRntCategory = field_a_rnt_category.options;
              }

              const field_a_registered_activities = this.formConfig.questions.find(
                (q) => q.v_model === "field_a_registered_activities"
              );
              if (field_a_registered_activities) {
                this.formItems.itemsRegisteredActivities =
                  field_a_registered_activities.options;
              }

              const impl_aspects_year = this.formConfig.questions.find(
                (q) => q.v_model === "impl_aspects_year"
              );
              if (impl_aspects_year) {
                this.formItems.impl_aspects_year = impl_aspects_year.options;
              }

              const cert_type = this.formConfig.questions.find(
                (q) => q.v_model === "cert_type"
              );
              if (cert_type) {
                this.formItems.cert_type = cert_type.options;
              }

              const res_limit_considered = this.formConfig.questions.find(
                (q) => q.v_model === "res_limit_considered"
              );
              if (res_limit_considered) {
                this.formItems.res_limit_considered = res_limit_considered.options;
              }

              const res_support = this.formConfig.questions.find(
                (q) => q.v_model === "res_support"
              );
              if (res_support) {
                this.formItems.res_support = res_support.options;
              }

              const capacity_verify = this.formConfig.questions.find(
                (q) => q.v_model === "capacity_verify"
              );
              if (capacity_verify) {
                this.formItems.capacity_verify = capacity_verify.options;
              }

              const capacity_calc = this.formConfig.questions.find(
                (q) => q.v_model === "capacity_calc"
              );
              if (capacity_calc) {
                this.formItems.capacity_calc = capacity_calc.options;
              }

              const sustain_benefits = this.formConfig.questions.find(
                (q) => q.v_model === "sustain_benefits"
              );
              if (sustain_benefits) {
                this.formItems.sustain_benefits = sustain_benefits.options;
              }

              const aspects_last_year = this.formConfig.questions.find(
                (q) => q.v_model === "aspects_last_year"
              );
              if (aspects_last_year) {
                this.formItems.aspects_last_year = aspects_last_year.options;
              }

              const disability_opportunity = this.formConfig.questions.find(
                (q) => q.v_model === "disability_opportunity"
              );
              if (disability_opportunity) {
                this.formItems.disability_opportunity = disability_opportunity.options;
              }

              const disabled_tourists = this.formConfig.questions.find(
                (q) => q.v_model === "disabled_tourists"
              );
              if (disabled_tourists) {
                this.formItems.disabled_tourists = disabled_tourists.options;
              }

              const empl_disabled_last_year = this.formConfig.questions.find(
                (q) => q.v_model === "empl_disabled_last_year"
              );
              if (empl_disabled_last_year) {
                this.formItems.empl_disabled_last_year = empl_disabled_last_year.options;
              }

              const waste_paper_weight = this.formConfig.questions.find(
                (q) => q.v_model === "waste_paper_weight"
              );
              if (waste_paper_weight) {
                this.formItems.waste_paper_weight = waste_paper_weight.options;
              }

              const waste_metal_weight = this.formConfig.questions.find(
                (q) => q.v_model === "waste_metal_weight"
              );
              if (waste_metal_weight) {
                this.formItems.waste_metal_weight = waste_metal_weight.options;
              }

              const waste_plastic_weight = this.formConfig.questions.find(
                (q) => q.v_model === "waste_plastic_weight"
              );
              if (waste_plastic_weight) {
                this.formItems.waste_plastic_weight = waste_plastic_weight.options;
              }

              const waste_glass_weight = this.formConfig.questions.find(
                (q) => q.v_model === "waste_glass_weight"
              );
              if (waste_glass_weight) {
                this.formItems.waste_glass_weight = waste_glass_weight.options;
              }

              const waste_wood_weight = this.formConfig.questions.find(
                (q) => q.v_model === "waste_wood_weight"
              );
              if (waste_wood_weight) {
                this.formItems.waste_wood_weight = waste_wood_weight.options;
              }

              const waste_textile_weight = this.formConfig.questions.find(
                (q) => q.v_model === "waste_textile_weight"
              );
              if (waste_textile_weight) {
                this.formItems.waste_textile_weight = waste_textile_weight.options;
              }

              const waste_nonrec_weight = this.formConfig.questions.find(
                (q) => q.v_model === "waste_nonrec_weight"
              );
              if (waste_nonrec_weight) {
                this.formItems.waste_nonrec_weight = waste_nonrec_weight.options;
              }

              const waste_food_loss_weight = this.formConfig.questions.find(
                (q) => q.v_model === "waste_food_loss_weight"
              );
              if (waste_food_loss_weight) {
                this.formItems.waste_food_loss_weight = waste_food_loss_weight.options;
              }

              const waste_food_weight = this.formConfig.questions.find(
                (q) => q.v_model === "waste_food_weight"
              );
              if (waste_food_weight) {
                this.formItems.waste_food_weight = waste_food_weight.options;
              }

              const waste_green_weight = this.formConfig.questions.find(
                (q) => q.v_model === "waste_green_weight"
              );
              if (waste_green_weight) {
                this.formItems.waste_green_weight = waste_green_weight.options;
              }

              const waste_elec_weight = this.formConfig.questions.find(
                (q) => q.v_model === "waste_elec_weight"
              );
              if (waste_elec_weight) {
                this.formItems.waste_elec_weight = waste_elec_weight.options;
              }

              const waste_constr_weight = this.formConfig.questions.find(
                (q) => q.v_model === "waste_constr_weight"
              );
              if (waste_constr_weight) {
                this.formItems.waste_constr_weight = waste_constr_weight.options;
              }

              const waste_fluor_weight = this.formConfig.questions.find(
                (q) => q.v_model === "waste_fluor_weight"
              );
              if (waste_fluor_weight) {
                this.formItems.waste_fluor_weight = waste_fluor_weight.options;
              }

              const waste_pesticide_weight = this.formConfig.questions.find(
                (q) => q.v_model === "waste_pesticide_weight"
              );
              if (waste_pesticide_weight) {
                this.formItems.waste_pesticide_weight = waste_pesticide_weight.options;
              }

              const waste_lead_batt_weight = this.formConfig.questions.find(
                (q) => q.v_model === "waste_lead_batt_weight"
              );
              if (waste_lead_batt_weight) {
                this.formItems.waste_lead_batt_weight = waste_lead_batt_weight.options;
              }

              const waste_tires_weight = this.formConfig.questions.find(
                (q) => q.v_model === "waste_tires_weight"
              );
              if (waste_tires_weight) {
                this.formItems.waste_tires_weight = waste_tires_weight.options;
              }

              const waste_batt_weight = this.formConfig.questions.find(
                (q) => q.v_model === "waste_batt_weight"
              );
              if (waste_batt_weight) {
                this.formItems.waste_batt_weight = waste_batt_weight.options;
              }

              const waste_med_weight = this.formConfig.questions.find(
                (q) => q.v_model === "waste_med_weight"
              );
              if (waste_med_weight) {
                this.formItems.waste_med_weight = waste_med_weight.options;
              }

              const waste_cook_oil_weight = this.formConfig.questions.find(
                (q) => q.v_model === "waste_cook_oil_weight"
              );
              if (waste_cook_oil_weight) {
                this.formItems.waste_cook_oil_weight = waste_cook_oil_weight.options;
              }

              const recyclable_waste_mgmt = this.formConfig.questions.find(
                (q) => q.v_model === "recyclable_waste_mgmt"
              );
              if (recyclable_waste_mgmt) {
                this.formItems.recyclable_waste_mgmt = recyclable_waste_mgmt.options;
              }

              const organic_waste_mgmt = this.formConfig.questions.find(
                (q) => q.v_model === "organic_waste_mgmt"
              );
              if (organic_waste_mgmt) {
                this.formItems.organic_waste_mgmt = organic_waste_mgmt.options;
              }

              const special_waste_mgmt = this.formConfig.questions.find(
                (q) => q.v_model === "special_waste_mgmt"
              );
              if (special_waste_mgmt) {
                this.formItems.special_waste_mgmt = special_waste_mgmt.options;
              }

              const sensitization_recycling = this.formConfig.questions.find(
                (q) => q.v_model === "sensitization_recycling"
              );
              if (sensitization_recycling) {
                this.formItems.sensitization_recycling = sensitization_recycling.options;
              }

              const waste_mgmt_barriers = this.formConfig.questions.find(
                (q) => q.v_model === "waste_mgmt_barriers"
              );
              if (waste_mgmt_barriers) {
                this.formItems.waste_mgmt_barriers = waste_mgmt_barriers.options;
              }

              const energy_conv_sources = this.formConfig.questions.find(
                (q) => q.v_model === "energy_conv_sources"
              );
              if (energy_conv_sources) {
                this.formItems.energy_conv_sources = energy_conv_sources.options;
              }

              const energy_alt_sources = this.formConfig.questions.find(
                (q) => q.v_model === "energy_alt_sources"
              );
              if (energy_alt_sources) {
                this.formItems.energy_alt_sources = energy_alt_sources.options;
              }

              const energy_tracking = this.formConfig.questions.find(
                (q) => q.v_model === "energy_tracking"
              );
              if (energy_tracking) {
                this.formItems.energy_tracking = energy_tracking.options;
              }

              const energy_efficiency = this.formConfig.questions.find(
                (q) => q.v_model === "energy_efficiency"
              );
              if (energy_efficiency) {
                this.formItems.energy_efficiency = energy_efficiency.options;
              }

              const energy_sensitization = this.formConfig.questions.find(
                (q) => q.v_model === "energy_sensitization"
              );
              if (energy_sensitization) {
                this.formItems.energy_sensitization = energy_sensitization.options;
              }

              const energy_barriers = this.formConfig.questions.find(
                (q) => q.v_model === "energy_barriers"
              );
              if (energy_barriers) {
                this.formItems.energy_barriers = energy_barriers.options;
              }

              const water_sources = this.formConfig.questions.find(
                (q) => q.v_model === "water_sources"
              );
              if (water_sources) {
                this.formItems.water_sources = water_sources.options;
              }

              const water_tracking = this.formConfig.questions.find(
                (q) => q.v_model === "water_tracking"
              );
              if (water_tracking) {
                this.formItems.water_tracking = water_tracking.options;
              }

              const water_efficiency = this.formConfig.questions.find(
                (q) => q.v_model === "water_efficiency"
              );
              if (water_efficiency) {
                this.formItems.water_efficiency = water_efficiency.options;
              }

              const water_sensitization = this.formConfig.questions.find(
                (q) => q.v_model === "water_sensitization"
              );
              if (water_sensitization) {
                this.formItems.water_sensitization = water_sensitization.options;
              }

              const water_barriers = this.formConfig.questions.find(
                (q) => q.v_model === "water_barriers"
              );
              if (water_barriers) {
                this.formItems.water_barriers = water_barriers.options;
              }

              const wastewater_mgmt = this.formConfig.questions.find(
                (q) => q.v_model === "wastewater_mgmt"
              );
              if (wastewater_mgmt) {
                this.formItems.wastewater_mgmt = wastewater_mgmt.options;
              }

              const wastewater_knowledge = this.formConfig.questions.find(
                (q) => q.v_model === "wastewater_knowledge"
              );
              if (wastewater_knowledge) {
                this.formItems.wastewater_knowledge = wastewater_knowledge.options;
              }

              const wastewater_sensitization = this.formConfig.questions.find(
                (q) => q.v_model === "wastewater_sensitization"
              );
              if (wastewater_sensitization) {
                this.formItems.wastewater_sensitization =
                  wastewater_sensitization.options;
              }

              const wastewater_barriers = this.formConfig.questions.find(
                (q) => q.v_model === "wastewater_barriers"
              );
              if (wastewater_barriers) {
                this.formItems.wastewater_barriers = wastewater_barriers.options;
              }

              const agri_tourism = this.formConfig.questions.find(
                (q) => q.v_model === "agri_tourism"
              );
              if (agri_tourism) {
                this.formItems.agri_tourism = agri_tourism.options;
              }

              const ghg_knowledge = this.formConfig.questions.find(
                (q) => q.v_model === "ghg_knowledge"
              );
              if (ghg_knowledge) {
                this.formItems.ghg_knowledge = ghg_knowledge.options;
              }

              const ghg_sources = this.formConfig.questions.find(
                (q) => q.v_model === "ghg_sources"
              );
              if (ghg_sources) {
                this.formItems.ghg_sources = ghg_sources.options;
              }

              const ghg_mitigation = this.formConfig.questions.find(
                (q) => q.v_model === "ghg_mitigation"
              );
              if (ghg_mitigation) {
                this.formItems.ghg_mitigation = ghg_mitigation.options;
              }

              const climate_adapt = this.formConfig.questions.find(
                (q) => q.v_model === "climate_adapt"
              );
              if (climate_adapt) {
                this.formItems.climate_adapt = climate_adapt.options;
              }

              const ghg_barriers = this.formConfig.questions.find(
                (q) => q.v_model === "ghg_barriers"
              );
              if (ghg_barriers) {
                this.formItems.ghg_barriers = ghg_barriers.options;
              }

              const air_pollution_sources = this.formConfig.questions.find(
                (q) => q.v_model === "air_pollution_sources"
              );
              if (air_pollution_sources) {
                this.formItems.air_pollution_sources = air_pollution_sources.options;
              }

              const air_pollution_ops = this.formConfig.questions.find(
                (q) => q.v_model === "air_pollution_ops"
              );
              if (air_pollution_ops) {
                this.formItems.air_pollution_ops = air_pollution_ops.options;
              }

              const biodiv_location = this.formConfig.questions.find(
                (q) => q.v_model === "biodiv_location"
              );
              if (biodiv_location) {
                this.formItems.biodiv_location = biodiv_location.options;
              }

              const biodiv_campaign = this.formConfig.questions.find(
                (q) => q.v_model === "biodiv_campaign"
              );
              if (biodiv_campaign) {
                this.formItems.biodiv_campaign = biodiv_campaign.options;
              }

              const biodiv_practices = this.formConfig.questions.find(
                (q) => q.v_model === "biodiv_practices"
              );
              if (biodiv_practices) {
                this.formItems.biodiv_practices = biodiv_practices.options;
              }

              const flora_use = this.formConfig.questions.find(
                (q) => q.v_model === "flora_use"
              );
              if (flora_use) {
                this.formItems.flora_use = flora_use.options;
              }

              const tourist_involvement = this.formConfig.questions.find(
                (q) => q.v_model === "tourist_involvement"
              );
              if (tourist_involvement) {
                this.formItems.tourist_involvement = tourist_involvement.options;
              }

              const conservation_trails = this.formConfig.questions.find(
                (q) => q.v_model === "conservation_trails"
              );
              if (conservation_trails) {
                this.formItems.conservation_trails = conservation_trails.options;
              }

              const animal_mgmt_barriers = this.formConfig.questions.find(
                (q) => q.v_model === "animal_mgmt_barriers"
              );
              if (animal_mgmt_barriers) {
                this.formItems.animal_mgmt_barriers = animal_mgmt_barriers.options;
              }

              const plant_mgmt_barriers = this.formConfig.questions.find(
                (q) => q.v_model === "plant_mgmt_barriers"
              );
              if (plant_mgmt_barriers) {
                this.formItems.plant_mgmt_barriers = plant_mgmt_barriers.options;
              }

              const noise_sources = this.formConfig.questions.find(
                (q) => q.v_model === "noise_sources"
              );
              if (noise_sources) {
                this.formItems.noise_sources = noise_sources.options;
              }

              const noise_ops = this.formConfig.questions.find(
                (q) => q.v_model === "noise_ops"
              );
              if (noise_ops) {
                this.formItems.noise_ops = noise_ops.options;
              }

              const actions_last_year = this.formConfig.questions.find(
                (q) => q.v_model === "actions_last_year"
              );
              if (actions_last_year) {
                this.formItems.actions_last_year = actions_last_year.options;
              }

              const community_investments = this.formConfig.questions.find(
                (q) => q.v_model === "community_investments"
              );
              if (community_investments) {
                this.formItems.community_investments = community_investments.options;
              }

              const collective_activities = this.formConfig.questions.find(
                (q) => q.v_model === "collective_activities"
              );
              if (collective_activities) {
                this.formItems.collective_activities = collective_activities.options;
              }

              const community_programs = this.formConfig.questions.find(
                (q) => q.v_model === "community_programs"
              );
              if (community_programs) {
                this.formItems.community_programs = community_programs.options;
              }

              const feedback_practices = this.formConfig.questions.find(
                (q) => q.v_model === "feedback_practices"
              );
              if (feedback_practices) {
                this.formItems.feedback_practices = feedback_practices.options;
              }

              const community_benefits = this.formConfig.questions.find(
                (q) => q.v_model === "community_benefits"
              );
              if (community_benefits) {
                this.formItems.community_benefits = community_benefits.options;
              }

              const gov_projects = this.formConfig.questions.find(
                (q) => q.v_model === "gov_projects"
              );
              if (gov_projects) {
                this.formItems.gov_projects = gov_projects.options;
              }

              const ecotour_partners = this.formConfig.questions.find(
                (q) => q.v_model === "ecotour_partners"
              );
              if (ecotour_partners) {
                this.formItems.ecotour_partners = ecotour_partners.options;
              }

              const artisan_support = this.formConfig.questions.find(
                (q) => q.v_model === "artisan_support"
              );
              if (artisan_support) {
                this.formItems.artisan_support = artisan_support.options;
              }

              const trad_knowledge = this.formConfig.questions.find(
                (q) => q.v_model === "trad_knowledge"
              );
              if (trad_knowledge) {
                this.formItems.trad_knowledge = trad_knowledge.options;
              }

              const awareness_programs = this.formConfig.questions.find(
                (q) => q.v_model === "awareness_programs"
              );
              if (awareness_programs) {
                this.formItems.awareness_programs = awareness_programs.options;
              }

              const sustainability_training = this.formConfig.questions.find(
                (q) => q.v_model === "sustainability_training"
              );
              if (sustainability_training) {
                this.formItems.sustainability_training = sustainability_training.options;
              }

              const ecosystem_restoration = this.formConfig.questions.find(
                (q) => q.v_model === "ecosystem_restoration"
              );
              if (ecosystem_restoration) {
                this.formItems.ecosystem_restoration = ecosystem_restoration.options;
              }

              const fair_trade = this.formConfig.questions.find(
                (q) => q.v_model === "fair_trade"
              );
              if (fair_trade) {
                this.formItems.fair_trade = fair_trade.options;
              }

              const local_biz_support = this.formConfig.questions.find(
                (q) => q.v_model === "local_biz_support"
              );
              if (local_biz_support) {
                this.formItems.local_biz_support = local_biz_support.options;
              }

              const edu_initiatives = this.formConfig.questions.find(
                (q) => q.v_model === "edu_initiatives"
              );
              if (edu_initiatives) {
                this.formItems.edu_initiatives = edu_initiatives.options;
              }

              const ethical_tourism = this.formConfig.questions.find(
                (q) => q.v_model === "ethical_tourism"
              );
              if (ethical_tourism) {
                this.formItems.ethical_tourism = ethical_tourism.options;
              }
            }

            if (response.data.autocomplete) {
              // this.activeWatch = false;
              response.data.autocomplete.forEach((item) => {
                // Verifica si el campo existe en el formulario de manera segura
                if (Object.prototype.hasOwnProperty.call(this.form, item.field)) {
                  // Actualiza el campo del formulario con el valor correspondiente
                  this.form[item.field] = item.text_response || item.selected_options;
                }
              });
            }

            //   setTimeout(() => {
            //     this.activeWatch = true;
            //   }, 1000);

            // }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      } finally {
        this.showDeleteConfirmation = false;
      }
    },
  },

  mounted() {
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
    this.getInfoForm();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  },

  computed: {
    visibleSteps() {
      // Filtrar los pasos visibles
      return this.stepTitles.filter((item) => item.show);
    },
    currentTitle() {
      const step = this.stepTitles[this.stepFormServiceOrder - 1];
      // Busca el label del grupo al que pertenece el step actual
      const groupLabel = this.stepTitles.find(
        (item) => item.group === step.group && item.label
      )?.label;

      return {
        title: step.title,
        label: groupLabel || "", // Si no se encuentra el label, se asigna una cadena vacía
      };
    },

    nextStep() {
      const currentIndex = this.stepTitles.findIndex(
        (step) => step.id === this.stepFormServiceOrder
      );
      return this.stepTitles.slice(currentIndex + 1).find((step) => step.show) || null;
    },

    previousStep() {
      const currentIndex = this.stepTitles.findIndex(
        (step) => step.id === this.stepFormServiceOrder
      );
      return (
        this.stepTitles
          .slice(0, currentIndex)
          .reverse()
          .find((step) => step.show) || null
      );
    },
  },
};
</script>
<style>
.v-field__input {
  padding-top: 8px;
}
.v-row {
  margin: 0;
}
</style>

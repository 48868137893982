<template>
  <UserExperience></UserExperience>
  <v-navigation-drawer
    v-model="drawer"
    :elevation="0"
    border="1"
    color="white"
    class="navigation-border-0"
    width="300"
  >
    <v-list>
      <v-list-item>
        <v-list-item-title class="text-h6 font-weight-bold">
          <v-img
            class="ma-5"
            :cover="false"
            :src="require('@/assets/img/logo-guaviare-color.png')"
          ></v-img>
        </v-list-item-title>
      </v-list-item>
    </v-list>

    <v-list
      class="text-primary menu-admin mt-16"
      active-class="text-white bg-transparent menu-admin-active list-remove-overlay"
      density="compact"
      nav
    >
      <template v-for="(menu, index) in itemsMenu" :key="index">
        <v-list-item :to="menu.path" rounded="xl" class="overflow-hidden">
          <template v-slot:default="">
            <span class="text-body-2"
              >{{ menu.title }} {{ $router.currentRoute["fullPath"] }}
            </span>
            <div v-if="$route.path == menu.path" class="bg"></div>
            <div v-if="$route.path == menu.path" class="bg bg2"></div>
            <div v-if="$route.path == menu.path" class="bg bg3"></div>
          </template>
          <template v-slot:prepend="{ isActive }">
            <v-list-item-action end>
              <v-icon v-if="menu.isIcon">{{ menu.icon }}</v-icon>
              <v-icon v-else>
                <img :src="isActive ? menu.iconActive : menu.icon" />
              </v-icon>
            </v-list-item-action>
          </template>

          <!-- <v-list-item-title>
                        <span class="text-body-2">{{ menu.title }} {{ $router.currentRoute['fullPath'] }} </span>
   
                    </v-list-item-title> -->
          <!--  -->
        </v-list-item>
      </template>
    </v-list>

    <template v-slot:append>
      <div class="pa-2 mb-4">
        <v-btn
          prepend-icon="mdi-information-outline"
          variant="text"
          color="primary"
          rounded
          block
          class="text-capitalize mb-2 justify-start"
        >
          Información & Ayuda
        </v-btn>
        <v-btn
          @click="logout"
          prepend-icon="mdi-logout"
          variant="text"
          color="primary"
          rounded
          block
          class="text-capitalize justify-start"
        >
          Cerrar Sesión
        </v-btn>
      </div>
      <div class="pa-2 text-center text-caption"></div>
    </template>
    <v-divider vertical></v-divider>
  </v-navigation-drawer>

  <v-main class="" id="main-content" ref="mainContent">
    <v-row no-gutters class="position-relative">
      <v-col md="5" class="d-md-block d-none border-e-sm">
        <v-list class="sticky-sidebar px-5">
          <v-list-item>
            <v-list-item-title class="text-h5 font-weight-bold">
              Configuración y privacidad
            </v-list-item-title>
          </v-list-item>
          <template v-for="(item, index) in settingsMenu" :key="index">
            <v-list-item v-if="item.head && item.show" class="pb-4 mt-5">
              <v-list-item-title class="font-weight-bold text-body-1">{{
                item.title
              }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-else-if="item.title && item.show"
              :to="item.path"
              color="white"
              :disabled="item.disabled"
            >
              <template v-slot:append="{ isActive }">
                <v-icon :color="isActive ? 'primary' : null"> mdi-chevron-right </v-icon>
              </template>
              <template v-slot:default="{ isActive }">
                <v-list-item-title
                  class="font-weight-medium text-body-2"
                  :class="{ 'text-primary': isActive }"
                  >{{ item.title }}</v-list-item-title
                >
              </template>
            </v-list-item>
            <v-divider v-else-if="item.show"></v-divider>
          </template>
        </v-list>
      </v-col>
      <v-col md="7" class="ml-auto">
        <v-card
          light
          elevation="0"
          height="100%"
          rounded
          class="pa-0"
          :color="$route.name == 'ProfileAdmin' ? '#FFF' : 'rgb(248 248 248)'"
        >
          <!-- Si el nombre del componente es ProfileAdmin se aplica un estilo al v-card-text -->
          <v-card-text height="100%" style="height: 100%">
            <TopBar
              v-if="false"
              :showTitle="false"
              :countNotifications="countNotifications"
            />

            <div
              class="text-body-2 pa-4"
              :class="{ 'animate__animated animate__fadeIn': showAnimationTitle }"
              style="height: 100%"
            >
              <router-view />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-main>

  <!-- <v-navigation-drawer v-model="drawer" :elevation="0" border="0" color="primary">

        <v-list>
            <v-list-item>

        

                <v-list-item-title class="text-h6 font-weight-bold">
                    <v-img :cover="false"
                    :src="require('@/assets/img/logo-guaviare-white.png')"></v-img>
                </v-list-item-title>
            </v-list-item>
        </v-list>



        <v-list class="text-white menu-admin mt-16"
            active-class="text-white bg-secondary menu-admin-active" density="compact" nav>

            <template v-for="(menu, index) in itemsMenu" :key="index">

                <v-list-item :to="menu.path" rounded="xl">

                    <template v-slot:prepend="{ isActive }">
                        <v-list-item-action end>
                            <v-icon v-if="menu.isIcon">{{ menu.icon }}</v-icon>
                            <v-icon v-else>
                                <img :src="isActive ? menu.iconActive : menu.icon">
                            </v-icon>
                        </v-list-item-action>
                    </template>

                    <v-list-item-title>
                       <span class="text-body-2">{{ menu.title }}</span> 
                    </v-list-item-title>
                </v-list-item>

            </template>


        </v-list>

        <template v-slot:append>
            <div class="pa-2 mb-4">
                <v-btn @click="logout" prepend-icon="mdi-logout" variant="text" block class="text-capitalize"> Cerrar
                    Sesión </v-btn>
            </div>
            <div class="pa-2 text-center text-caption">


            </div>
        </template>

    </v-navigation-drawer>

    <v-main class="bg-primary">
        <v-card light elevation="0" height="100%" rounded class="pa-5" style="background-color: #00679F;">
            <v-card-text height="100%"
                style="background: #FFF 0% 0% no-repeat padding-box; border-radius: 20px; height: 100%;">

                <v-layout>
                    <v-app-bar :elevation="0" color="transparent">



                        <template v-slot:prepend>
                            <p>
                                <span class="text-h4 font-weight-bold">
                                    {{ $route.meta.title }}
                                </span>
                                <br>

                            </p>
                        </template>
                        <template v-slot:append>
                            <div style="width: 300px;" class="text-subtitle-2 d-flex align-center mr-0">
                                <span class="text-right mr-2" style="    width: 100%; height: 20px;">
                                    {{ formattedDate }}
                                </span>

                                <v-avatar class="mr-4 bg-grey-lighten-3">
                                    <v-icon color="">
                                        mdi-calendar-clock-outline
                                    </v-icon>
                                </v-avatar>
                            </div>
                          
                            <v-avatar class="mr-4" color="#FFE300">
                                <v-icon color="white">
                                    mdi-bell
                                </v-icon>
                            </v-avatar>


                            <v-list-item lines="two" append-avatar="https://randomuser.me/api/portraits/men/81.jpg"
                                subtitle="Empresario" title="Empresa JMC S.A."></v-list-item>
                        </template>
                    </v-app-bar>
                    <v-main class="text-body-2">
                        <div class="mx-2 mt-5">
                            <router-view />
                        </div>
                    </v-main>
                </v-layout>
            </v-card-text>

        </v-card>
    </v-main> -->
</template>

<script>
import axios from "axios";
import { useDisplay } from "vuetify";

import UserExperience from "../views/forms/includes/UserExperience.vue";
import TopBar from "./includes/TopBar.vue";

export default {
  name: "AccountConfigLayout",
  components: {
    UserExperience,
    TopBar,
  },
  props: {
    countNotifications: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const { mdAndDown } = useDisplay();

    return {
      isMdOrLower: mdAndDown,
    };
  },
  data: () => ({
    drawer: null,
    settingsMenu: [
      {
        id: 1,
        title: "Información Personal y de Contacto",
        head: true,
        icon: "mdi-account-circle-outline",
        show: true,
      },
      {
        id: 2,
        title: "Información Personal",
        path: "informacion-personal",
        icon: "mdi-account",
        show: true,
      },
      {
        id: 3,
        divider: true,
        show: true,
      },
      {
        id: 4,
        title: "Foto de Perfil",
        path: "foto-perfil",
        icon: "mdi-camera",
        show: true,
      },
      {
        id: 5,
        divider: true,
        show: true,
      },
      {
        id: 6,
        title: "Información de Contacto",
        path: "informacion-contacto",
        icon: "mdi-phone",
        show: true,
      },
      {
        id: 7,
        divider: true,
        show: true,
      },
      {
        id: 8,
        disabled: true,
        title: "Dirección de Residencia",
        path: "/settings/address",
        icon: "mdi-home",
        show: true,
      },
      {
        id: 9,
        divider: true,
        show: true,
      },
      {
        id: 10,
        title: "Contraseña y Seguridad",
        path: "cambiar-contrasena",
        icon: "mdi-lock",
        show: true,
      },
      {
        id: 11,
        divider: true,
        show: true,
      },
      {
        id: 12,
        title: "Configuración de Notificaciones",
        path: "/settings/notifications",
        icon: "mdi-bell",
        disabled: true,
        show: true,
      },
      {
        id: 13,
        divider: true,
        show: true,
      },
      {
        id: 14,
        title: "Administración de Sitios Turísticos",
        head: true,
        icon: "mdi-map",
        show: true,
      },
      {
        id: 15,
        divider: true,
        show: true,
      },
      {
        id: 16,
        title: "Sitio Registrado",
        path: "sitio-turistico-registrado",
        icon: "mdi-eye",
        show: true,
      },
      {
        id: 17,
        divider: true,
        show: true,
      },
      {
        id: 18,
        title: "Actividades Económicas",
        head: true,
        icon: "mdi-chart-bar",
        show: true,
      },
      {
        id: 19,
        title: "Registro de Actividades Económicas",
        path: "registro-actividades-economicas",
        icon: "mdi-file-document",
        show: true,
      },
      {
        id: 20,
        divider: true,
        show: true,
      },
      {
        id: 21,
        title: "Información de la Empresa o Establecimiento",
        head: true,
        icon: "mdi-store",
        show: false,
      },
      {
        id: 22,
        title: "Detalles de la Empresa",
        path: "company-details",
        icon: "mdi-information",
        show: false,
      },
      {
        id: 23,
        divider: true,
        show: false,
      },
      {
        id: 24,
        title: "Documentos del Establecimiento",
        path: "documentos-establecimiento",
        icon: "mdi-upload",
        show: false,
      },
      {
        id: 25,
        divider: true,
        show: false,
      },
     
      {
        id: 26,
        title: "Registro y Actividades Comerciales",
        head: true,
        icon: "mdi-briefcase",
        show: false,
      },
      {
        id: 27,
        title: "Actividad Comercial",
        path: "actividad-comercial",
        icon: "mdi-pencil",
        show: false,
      },
      
    ],
    itemsMenu: [
      {
        path: "/empresario",
        title: "Tablero de Datos",
        isIcon: true,
        icon: "mdi-view-dashboard-outline",
      },
      {
        path: "/empresario/encuestas",
        title: "Encuestas",
        isIcon: true,
        icon: "mdi-clipboard-text",
      },
      // {
      //     path: '/administrador/encuentas',
      //     title: 'Encuestas',
      //     isIcon: true,
      //     icon: 'mdi-form-textbox',
      // },
      // {
      //     path: '/administrador/roles-permisos',
      //     title: 'Roles y Permisos',
      //     isIcon: true,
      //     icon: 'mdi-account-key-outline',
      // },
    ],
    showAnimationTitle: true,
  }),
  watch: {
    $route(to, from) {
      console.log(to, from);
      this.showAnimationTitle = false;
      setTimeout(() => {
        this.showAnimationTitle = true;
      }, 1);
    },
  },
  mounted() {
    if (this.$store.state.business_info) {
      console.log(this.$store.state.business_info);

      const businessInfo = this.$store.state.business_info;

      //buscar question_id : 11 en el array de business_info
      const question11 = businessInfo.find((item) => item.question_id === 11);
      
      if (question11) {
        if (question11.selected_options && question11.selected_options.id == 45) {
          // cambiar show a true del item 21
          const item = this.settingsMenu.find((item) => item.id === 21);
          if (item) {
            item.show = true;
          }

          // cambiar show a true del item 22
          const item2 = this.settingsMenu.find((item) => item.id === 22);
          if (item2) {
            item2.show = true;
          }

          // cambiar show a true del item 23
          const item3 = this.settingsMenu.find((item) => item.id === 23);
          if (item3) {
            item3.show = true;
          }

          // cambiar show a true del item 24
          const item4 = this.settingsMenu.find((item) => item.id === 24);
          if (item4) {
            item4.show = true;
          }

          // cambiar show a true del item 25
          const item5 = this.settingsMenu.find((item) => item.id === 25);
          if (item5) {
            item5.show = true;
          }


        }
      }

      const question23 = businessInfo.find((item) => item.question_id === 23);
      console.log(question23);
      //selected_options es un array, buscar si tiene id 64 o 67
      if(question23){
        if (question23.selected_options && question23.selected_options.length > 0 ){

          const selectedOptions = question23.selected_options;
          const selectedOptionsIds = selectedOptions.map((item) => item.id);

          if(selectedOptionsIds.includes(64) || selectedOptionsIds.includes(67)){
            // cambiar show a true del item 26
            const item = this.settingsMenu.find((item) => item.id === 26);
            if (item) {
              item.show = true;
            }

            // cambiar show a true del item 27
            const item2 = this.settingsMenu.find((item) => item.id === 27);
            if (item2) {
              item2.show = true;
            }
          }

        }
      }
      
      // if (question23) {
      //   if (question23.selected_options && question23.selected_options.id == 45) {
      //     // cambiar show a true del item 26
      //     const item = this.settingsMenu.find((item) => item.id === 26);
      //     if (item) {
      //       item.show = true;
      //     }

      //     // cambiar show a true del item 27
      //     const item2 = this.settingsMenu.find((item) => item.id === 27);
      //     if (item2) {
      //       item2.show = true;
      //     }
      //   }
      // }

    }
  },
  methods: {
    async logout() {
      try {
        let url = process.env.VUE_APP_API_URL;

        // Obtener el token de localStorage
        const token = this.$store.state.token;

        console.log(token);

        const response = await axios.post(
          url + "/logout",
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response) {
          // Limpiar el token de localStorage y redirigir al usuario a la página de inicio
          localStorage.removeItem("token");
          this.$router.push({ path: "/" }); // Redirigir a la página de inicio
        }
      } catch (error) {
        console.error("Error logging out:", error);
      }
    },
  },
};
</script>

<style>
body {
  font-family: "Be Vietnam Pro", sans-serif !important;
}

* {
  font-family: "Be Vietnam Pro", sans-serif !important;
}

.list-remove-overlay .v-list-item__overlay {
  display: none;
}

.badge-avatar .v-badge__badge::after {
  box-shadow: 0px 0px 6px 0px #0000004a;
  border-width: 3px;
}

.custom-line-height {
  line-height: 1.2 !important;
}

.navigation-border-1::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 1px;
  background: linear-gradient(
    to bottom,
    rgba(219, 219, 219, 0) 0%,
    #9e9e9e 50%,
    rgba(219, 219, 219, 0) 100%
  );
}

.overflow-visible-app-bar .v-toolbar__content {
  overflow: visible !important;
}

.Vue-Toastification__toast--success {
  background-color: #00a551 !important;
  color: #fff;
}

.sticky-sidebar {
  position: sticky;
  top: 0;
  height: 100vh; /* Esto asegura que el v-list ocupe todo el alto de la ventana */
  overflow-y: auto; /* Permite hacer scroll dentro de la lista si es necesario */
}
</style>

<template>
  <v-container fluid>
    <v-card color="transparent" elevation="0" class="overflow-visible">
      <v-card-title class="d-flex text-body-1 font-weight-bold py-3 px-0">
        <v-btn
          :disabled="currentGraph == 1 ? true : false"
          density="comfortable"
          icon
          @click="currentGraph--"
          variant="flat"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          :disabled="currentGraph == titlesGraph.length ? true : false"
          class="ml-2"
          density="comfortable"
          icon
          @click="currentGraph++"
          variant="flat"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <span class="ml-3 text-h5">
          {{ titlesGraph.find((title) => title.id === currentGraph).title }}
        </span>
      </v-card-title>
      <v-card-text class="px-0">
        <v-container fluid class="px-0">
          <v-window v-model="currentGraph" :show-arrows="false">
            <v-window-item :key="1" :value="1">
              <v-row>
                <v-col md="4" cols="12">
                  <Graph1Form1
                    :question_id="40"
                    :form_id="2"
                    graph_id="actividades1"
                    title="Respuestas sobre actividades que ofrece el Establecimiento Turístico"
                  />
                </v-col>
                <v-col md="4" cols="12">
                  <Graph2Form1
                    :question_id="46"
                    :form_id="2"
                    graph_id="cant_huesp1"
                    title="Cantidad máxima de huéspedes que puede alojar"
                    :replace="[
                      {
                        from:
                          ' - Indique la cantidad máxima de huéspedes que puede alojar',
                        to: '',
                      },
                    ]"
                  />
                </v-col>
                <v-col md="4" cols="12">
                  <Graph2Form1
                    :question_id="53"
                    :form_id="2"
                    graph_id="aforo_estab1"
                    title="Aforo máximo de clientes en el establecimiento"
                    :replace="[
                      {
                        from:
                          ' - Indique el aforo máximo de clientes en el establecimiento',
                        to: '',
                      },
                    ]"
                  />
                </v-col>
                <v-col md="4" cols="12">
                  <Graph2Form1
                    :question_id="54"
                    :form_id="2"
                    graph_id="aforo_espacio_cult1"
                    title="Aforo máximo de clientes en el espacio cultural"
                    :replace="[
                      {
                        from:
                          ' - Indique la aforo máximo de clientes en el espacio cultural',
                        to: '',
                      },
                    ]"
                  />
                </v-col>
                <v-col md="4" cols="12">
                  <Graph1Form1
                    :question_id="60"
                    :form_id="2"
                    graph_id="gremios1"
                    title="Respuestas sobre pertenencia a gremios/asociaciones/colectivos"
                  />
                </v-col>
                <v-col md="4" cols="12">
                  <Graph1Form1
                    :question_id="61"
                    :form_id="2"
                    graph_id="conocimiento_escnna1"
                    title="Conocimiento estrategia contra la ESCNNA"
                  />
                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item :key="2" :value="2">
              <v-row>
                <v-col md="4" cols="12">
                  <Graph1Form1
                    :question_id="41"
                    :form_id="2"
                    graph_id="porcentaje_ocupacion2"
                    title="Porcentaje de ocupación"
                  />
                </v-col>
                <v-col md="4" cols="12">
                  <Graph1Form1
                    :question_id="42"
                    :form_id="2"
                    graph_id="valor_promedio2"
                    title="Valor promedio de los servicios de alojamiento"
                  />
                </v-col>
                <v-col md="4" cols="12">
                  <Graph2Form1
                    :question_id="[44, 45]"
                    :form_id="2"
                    graph_id="habitaciones_camas2"
                    title="Cantidad de habitaciones y camas disponibles"
                    :replace="[
                      {
                        from:
                          ' - Indique la cantidad de habitaciones disponibles',
                        to: ' - Habitaciones',
                      },
                      {
                        from: ' - Indique la cantidad de camas disponibles',
                        to: ' - Camas',
                      },
                    ]"
                  />
                </v-col>
                <v-col md="4" cols="12">
                  <Graph1Form1
                    :question_id="48"
                    :form_id="2"
                    graph_id="promedio_platos2"
                    title="Precio promedio de los platos"
                  />
                </v-col>
                <v-col md="4" cols="12">
                  <Graph2Form1
                    :question_id="[49, 50]"
                    :form_id="2"
                    graph_id="mesas_sillas_restaurante2"
                    title="Cantidad de mesas y sillas disponibles del restaurante"
                    :replace="[
                      {
                        from:
                          ' - Indique la cantidad de mesas disponibles del restaurante',
                        to: ' - Mesas',
                      },
                      {
                        from: ' - Indique la cantidad de sillas disponibles del restaurante',
                        to: ' - Sillas',
                      },
                    ]"
                  />
                </v-col>
                <v-col md="4" cols="12">
                  <Graph2Form1
                    :question_id="[51, 52]"
                    :form_id="2"
                    graph_id="mesas_sillas_bar2"
                    title="Cantidad de mesas y sillas del bar"
                    :replace="[
                      {
                        from:
                          ' - Indique la cantidad de mesas disponibles del bar',
                        to: ' - Mesas',
                      },
                      {
                        from: ' - Indique la cantidad de sillas disponibles del bar',
                        to: ' - Sillas',
                      },
                    ]"
                  />
                </v-col>
                <v-col md="4" cols="12">
                  <Graph2Form1
                    :question_id="55"
                    :form_id="2"
                    graph_id="vehiculos_dispo_bar2"
                    title=" Cantidad de vehículos disponibles"
                    :replace="[
                      {
                        from:
                          ' - Indique la cantidad de vehículos disponibles',
                        to: '',
                      }
                    ]"
                  />
                </v-col>
                <v-col md="4" cols="12">
                  <Graph2Form1
                    :question_id="[56, 57]"
                    :form_id="2"
                    graph_id="turistas_atendidos_bar2"
                    title="Turistas atendidos (nacionales y extranjeros) en la temporada"
                    :replace="[
                      {
                        from:
                          ' - Indique cuántos turistas atendió en esta temporada',
                        to: ' - Turistas atendidos',
                      },
                      {
                        from:
                          ' - ¿Qué porcentaje de los turistas atendidos en la temporada eran extranjeros?',
                        to: ' - Turistas extranjeros atendidos',
                      },
                    ]"
                  />
                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item :key="3" :value="3">
              <v-row>
                <v-col md="4" cols="12">
                  <Graph1Form1
                    :question_id="66"
                    :form_id="3"
                    graph_id="ejerce_gianza_turistica3"
                    title="Ejerce actualmente la actividad de guianza turística"
                  />
                </v-col>
                <v-col md="4" cols="12">
                  <Graph1Form1
                    :question_id="73"
                    :form_id="3"
                    graph_id="especialidad_guianza3"
                    title="Respuestas especialidad en la guianza turística"
                  />
                </v-col>
                <v-col md="4" cols="12">
                  <Graph1Form1
                    :question_id="84"
                    :form_id="3"
                    graph_id="quien_contrata3"
                    title="Respuestas sobre quién contrata sus servicios principalmente"
                  />
                </v-col>
                <v-col md="4" cols="12">
                  <Graph1Form1
                    :question_id="85"
                    :form_id="3"
                    graph_id="dias_semana_labora3"
                    title="Respuestas sobre días de la semana en los que labora principalmente"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <Graph2Form1
                    :question_id="[82, 83]"
                    :form_id="3"
                    graph_id="tarifa_promedio_hora2"
                    title="Tarifa promedio por hora de la prestación de sus servicios en idioma Español y otro diferente"
                    position_labels="top"
                    :replace="[
                      {
                        from:
                          ' - Indique la tarifa promedio por hora de la prestación de sus servicios en idioma Español',
                        to: ' - Español',
                      },
                      {
                        from:
                          ' - Indique la tarifa promedio por hora de la prestación de sus servicios en idioma diferente al Español',
                        to: ' - Otro idioma',
                      },
                    ]"
                  />
                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item :key="4" :value="4">
              <v-row>
                <v-col md="6" cols="12">
                  <Graph2Form1
                    position_labels="right"
                    :question_id="[
                      359,
                      360,
                      361,
                      362,
                      363,
                      364,
                      365,
                      366,
                      367,
                      368,
                      369,
                      370,
                      371,
                      372,
                      373,
                    ]"
                    :form_id="10"
                    graph_id="estado_elementos10"
                    title="Promedio estado de los elementos e infraestructura"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <Graph2Form1
                    position_labels="right"
                    :question_id="[379, 380, 381, 382, 383]"
                    :form_id="10"
                    graph_id="precios_entrada10"
                    title="Precios de entrada"
                  />
                </v-col>
                <v-col md="4" cols="12">
                  <Graph1Form1
                    :question_id="358"
                    :form_id="10"
                    graph_id="elementos_infra_atrac10"
                    title="Respuestas de elementos e infraestructura que tiene su atractivo"
                  />
                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item :key="5" :value="5">
              <v-row>
                <v-col md="4" cols="12">
                  <graph3DetailForm1
                    :form_id="5"
                    graph_id="grupo_viaje5"
                    title="Grupos de viaje"
                    endpoint="getCountForm"
                  />
                </v-col>
                <v-col md="4" cols="12">
                  <graph3DetailForm1
                    :form_id="5"
                    graph_id="dias_viaje5"
                    title="Días de duración en el destino del grupo de viaje"
                    endpoint="countTravelDuration"
                  />
                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item :key="6" :value="6">
              <v-row>
                <v-col md="4" cols="12">
                  <Graph1Form1
                    :form_id="6"
                    :question_id="248"
                    graph_id="programo_visita6"
                    title="Con quién programó la visita"
                  />
                </v-col>
                <v-col md="4" cols="12">
                  <graph3DetailForm1
                    :form_id="6"
                    graph_id="grupo_viaje6"
                    title="Grupos de viaje"
                    endpoint="getCountForm"
                  />
                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item :key="7" :value="7">
              <v-row>
                <v-col md="4" cols="12">
                  <Graph2Form1
                    :form_id="7"
                    :question_id="[256, 257, 258, 259, 260, 261, 262, 263, 264, 265]"
                    graph_id="programo_visita7"
                    title="Nivel de percepción características del atractivo"
                  />
                </v-col>
                <v-col md="4" cols="12">
                  <Graph2Form1
                    :form_id="7"
                    :question_id="[269, 270, 271, 272]"
                    graph_id="programo_guia7"
                    title="Nivel de percepción caracteristicas del guia o intérprete local"
                  />
                </v-col>
                <v-col md="4" cols="12">
                  <Graph2Form1
                    :form_id="7"
                    :question_id="[274, 275, 276, 277, 278, 279]"
                    graph_id="programo_comida7"
                    title="Nivel de percepción caracteristicas respecto a la comida adquirida"
                  />
                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item :key="8" :value="8">
              <v-row>
                <v-col md="4" cols="12">
                  <Graph2Form1
                    :form_id="8"
                    :question_id="[280, 281, 282, 283, 284, 285, 286, 287, 288, 289, 290]"
                    graph_id="nivel_alojamiento8"
                    title="Nivel de percepción caracteristicas del alojamiento"
                  />
                </v-col>
                <v-col md="4" cols="12">
                  <Graph2Form1
                    :form_id="8"
                    :question_id="[
                      291,
                      292,
                      293,
                      294,
                      295,
                      296,
                      297,
                      298,
                      299,
                      300,
                      301,
                      302,
                    ]"
                    graph_id="nivel_satis_alojamiento8"
                    title="Nivel de satisfacción general de los servicios del alojamiento"
                  />
                </v-col>
                <v-col md="4" cols="12">
                  <Graph2Form1
                    :form_id="8"
                    :question_id="[310]"
                    graph_id="nivel_edif8"
                    title="Nivel de percepción respecto a la condición de los edificios e instalaciones del  alojamiento"
                  />
                </v-col>
                <v-col md="4" cols="12">
                  <Graph2Form1
                    :form_id="8"
                    :question_id="[311]"
                    graph_id="nivel_precio8"
                    title="Nivel de percepción de la relación calidad precio del  alojamiento"
                  />
                </v-col>
                <v-col md="4" cols="12">
                  <Graph2Form1
                    :form_id="8"
                    :question_id="[312]"
                    graph_id="nivel_satis_general8"
                    title="Nivel de percepción de satisfacción general del alojamiento"
                  />
                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item :key="9" :value="9">
              <v-row>
                <v-col md="4" cols="12">
                  <Graph2Form1
                    :form_id="9"
                    :question_id="[323, 324, 325, 326, 327, 328]"
                    graph_id="nivel_alimentos9"
                    title="Nivel de percepción de los establecimientos de alimentación"
                  />
                </v-col>
                <v-col md="4" cols="12">
                  <Graph2Form1
                    :form_id="9"
                    :question_id="[338, 339, 340, 341]"
                    graph_id="nivel_depto_general9"
                    title="Nivel de percepción del Departamento en general"
                  />
                </v-col>
                <v-col md="4" cols="12">
                  <Graph2Form1
                    :form_id="9"
                    :question_id="[344]"
                    graph_id="nivel_acceso_prods9"
                    title="Nivel de percepción respecto al acceso a los productos turísticos del destino"
                  />
                </v-col>
              </v-row>
            </v-window-item>
          </v-window>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { useDisplay } from "vuetify";
import Graph1Form1 from "./includes/chart/graph1Form1.vue";
import Graph2Form1 from "./includes/chart/graph2Form1.vue";
import graph3DetailForm1 from "./includes/chart/graph3DetailForm1.vue";

export default {
  name: "DashboardAdmin",
  components: {
    Graph1Form1,
    Graph2Form1,
    graph3DetailForm1,
  },
  setup() {
    const { smAndDown } = useDisplay();

    return {
      smAndDown: smAndDown,
    };
  },
  data() {
    return {
      currentGraph: 1,
      titlesGraph: [
        {
          id: 1,
          title: "Resumen de Capacidades y Actividades del Establecimiento Turístico",
        },
        {
          id: 2,
          title: "Estadísticas de Capacidad, Servicios y Atención",
        },
        {
          id: 3,
          title: "Características y Condiciones de la Actividad de Guianza",
        },
        {
          id: 4,
          title: "Condiciones y Costos de Infraestructura y Servicios",
        },
        {
          id: 5,
          title: "Características y Duración de los Grupos de Viaje",
        },
        {
          id: 6,
          title: "Programación y Organización de Visitas y Grupos de Viaje",
        },
        {
          id: 7,
          title: "Percepción de la Experiencia",
        },
        {
          id: 8,
          title:
            "Percepción y Satisfacción General de los Servicios e Instalaciones del Alojamiento",
        },
        {
          id: 9,
          title:
            "Percepción General de los Establecimientos, el Destino y sus Productos Turísticos",
        },
      ],
    };
  },
  computed: {},

  mounted() {},

  methods: {},
};
</script>

<style>
table.calendar {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.calendar th,
.calendar td {
  text-align: center;
  /* Borde izquierdo entre días */
  padding: 8px;
}

th.current-day,
td.current-day {
  border: none !important;
}

th.current-day {
  border-radius: 15px 15px 0 0;
}

td.current-day {
  border-radius: 0 0 15px 15px;
}

.current-day {
  background-color: #00a551;
  color: #fff;
  font-weight: bold;
  /* border: 2px solid #4caf50; */
  /* Bordes más destacados para el día actual */
}

.two-columns-card {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Dos columnas de igual ancho */
  gap: 20px; /* Espacio igual entre todos los elementos */
  grid-auto-rows: minmax(135px, auto);
}

.column-item {
  padding: 5px;
  box-sizing: border-box; /* Incluye padding y border en el tamaño total del elemento */
}

/* cuando el tamaño de la pagina sea menor a 600px two-columns-card cambia */
@media (max-width: 600px) {
  .two-columns-card {
    grid-template-columns: 1fr; /* Una sola columna */
  }
}
</style>

<template>

    <div class="bg" :class="classCustom"></div>
    <div class="bg bg2" :class="classCustom"></div>
    <div class="bg bg3" :class="classCustom"></div>
    <div class="content-animation ">
        <div class="font-weight-bold text-center text-h3">
            <!-- Formulario gestión de&nbsp; -->
            {{ title }}
            <span v-for="(word, wordIndex) in words" :key="wordIndex" class="word"
                :style="{ opacity: currentWord === wordIndex ? 1 : 0 }">
                <span v-for="(letter, letterIndex) in wordArray[wordIndex]" :key="letterIndex"
                    :class="letter.className">
                    {{ letter.character === ' ' ? '&nbsp;' : letter.character }}
                </span>
            </span>
        </div>
        <p class="font-weight-regular text-body-1 text-justify pt-4">
        <span v-html="subTitle"> </span>
            <!--sección registrará los
            datos de empleabilidad de su empresa. Recuerde que estos datos no serán utilizados
            en ningún momento para realizar control fiscal o de impuestos, sino que nos ayudan a
            caracterizar la empleabilidad generada en el sector turismo en temporadas altas y
            bajas. -->
        </p>

    </div>

</template>

<script>
import { ref, onMounted } from 'vue';

export default {
    name: 'LeftPanelAnimation1',
    props: {
        title: {
            type: String,
            required: true
        },
        subTitle: {
            type: String,
            required: true
        },
        wordsAnimate: {
            type: Array,
            required: true
        },
        classCustom: {
            type: String,
            default: ''
        }
      
      
    },
    setup(props) {
        const words = ref(props.wordsAnimate);
        const wordArray = ref([]);
        const currentWord = ref(0);

        onMounted(() => {
            words.value.forEach((word, index) => {
                splitLetters(word, index);
            });

            changeWord();
        });

        function changeWord() {
            const cw = wordArray.value[currentWord.value];
            const nw = currentWord.value === words.value.length - 1 ? wordArray.value[0] : wordArray.value[currentWord.value + 1];

            cw.forEach((letter, index) => {
                animateLetterOut(cw, index);
            });

            nw.forEach((letter, index) => {
                letter.className = 'letter behind';
                animateLetterIn(nw, index);
            });

            currentWord.value = (currentWord.value === wordArray.value.length - 1) ? 0 : currentWord.value + 1;
        }

        function animateLetterOut(cw, i) {
            setTimeout(() => {
                cw[i].className = 'letter out';
            }, i * 80);
        }

        function animateLetterIn(nw, i) {
            setTimeout(() => {
                nw[i].className = 'letter in';
            }, 340 + (i * 80));
        }

        function splitLetters(word) {
            const letters = [];
            for (let i = 0; i < word.length; i++) {
                letters.push({ character: word[i], className: 'letter' });
            }
            wordArray.value.push(letters);
            
        }

        return {
            words,
            currentWord,
            wordArray,
        }
    },
}
</script>

<style>

.word {
    position: relative;
    white-space: nowrap;
    transition: opacity 0.5s ease-in-out;
}

.letter {
    display: inline-block;
    transition: all 0.3s ease;
}

.letter.out {
    transform: translateY(-100%);
    opacity: 0;
}

.letter.behind {
    transform: translateY(100%);
    opacity: 0;
}

.letter.in {
    transform: translateY(0);
    opacity: 1;
}


.letter2 {
    font-family: 'Be Vietnam Pro', sans-serif;
    display: inline-block;
    position: relative;
    color: #00679F;
    transform-style: preserve-3d;
    perspective: 400;
    z-index: 1;
}

.letter2:before,
.letter2:after {
    position: absolute;
    content: attr(data-letter);
    transform-origin: top left;
    top: 0;
    left: 0;
}

.letter2,
.letter2:before,
.letter2:after {
    transition: all 0.3s ease-in-out;
}

.letter2:before {
    color: #212121;

    z-index: 3;
    transform:
        rotateX(0deg) rotateY(-15deg) rotateZ(0deg);
}

.letter2:after {
    color: rgba(0, 0, 0, .11);
    z-index: 2;
    transform:
        scale(1.08, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 1deg);
}

.letter2:hover:before {
    color: #212121;
    transform:
        rotateX(0deg) rotateY(-40deg) rotateZ(0deg);
}

.letter2:hover:after {
    transform:
        scale(1.08, 1) rotateX(0deg) rotateY(40deg) rotateZ(0deg) skew(0deg, 22deg);
}
</style>

<template>
  <v-container fluid class="px-0" style="height: 100%">
    <v-card-text class="px-0">
      <v-row style="height: 100%">
        <v-col cols="12" md="12" class="px-0">
          <v-tabs v-model="tab" align-tabs="start" hide-slider class="">
            <v-tab
              :class="tab == 1 ? 'bg-secondary text-white' : 'bg-grey-app-2 text-black'"
              class="rounded-t-lg text-capitalize"
              :value="1"
              >Crear Registro</v-tab
            >
            <v-tab
              :class="tab == 2 ? 'bg-secondary text-white' : 'bg-grey-app-2 text-black'"
              class="rounded-t-lg text-capitalize"
              :value="2"
              >Ver Registro</v-tab
            >
          </v-tabs>
          <v-divider class="mr-3"></v-divider>

          <v-tabs-window v-model="tab">
            <v-tabs-window-item :value="1">
              <v-card-text class="px-0">
                <v-row>
                  <v-col md="12">
                    <div class="d-flex">
                      <v-spacer></v-spacer>
                      <v-spacer></v-spacer>
                      <v-text-field
                        v-model="searchTerm"
                        variant="outlined"
                        bg-color="white"
                        placeholder="Buscar encuesta"
                        density="compact"
                      >
                        <template v-slot:append-inner>
                          <v-icon class="opacity-100" color="primary">mdi-magnify</v-icon>
                        </template>
                      </v-text-field>
                    </div>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                    v-for="(item, index) in filteredSurveys"
                    :key="index"
                  >
                    <v-hover>
                      <template v-slot:default="{ isHovering, props }">
                        <div
                          v-bind="props"
                          class="survey-card rounded-lg"
                          :class="combineClasses(isHovering, item, index)"
                          :style="getAnimationStyle(index + 1, 200)"
                        >
                          <div
                            class="survey-image overflow-hidden"
                            style="background-position: center; background-size: cover"
                            :style="{ 'background-image': 'url(' + item.imageUrl + ')' }"
                          >
                            <!-- <v-img
                                                            :class="{ 'animate__animated animate__pulse': isHovering && item.path }"
                                                            position="center" cover height="237px" :src="item.imageUrl">
                                                        </v-img> -->
                          </div>
                          <div class="survey-content py-2">
                            <v-list-item class="px-6" height="60">
                              <template v-slot:title>
                                <span class="text-wrap font-weight-bold">
                                  {{ item.title }}
                                </span>
                              </template>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-card-text class="text-medium-emphasis pa-6">
                              <div>
                                {{
                                  item.description.length > 90
                                    ? item.description.substring(0, 90) + "..."
                                    : item.description
                                }}
                              </div>
                            </v-card-text>
                            <v-card-actions
                              class="d-flex justify-center align-center px-8"
                            >
                              <v-spacer></v-spacer>
                              <router-link
                                v-if="item.path"
                                target="_blank"
                                class="text-decoration-none text-primary font-weight-medium"
                                :to="item.path ? item.path : '#'"
                              >
                                Ir a Formulario <v-icon end>mdi-arrow-right</v-icon>
                              </router-link>

                              <span v-else class="text-grey-lighten-1">
                                Ir a Formulario <v-icon end>mdi-arrow-right</v-icon>
                              </span>
                            </v-card-actions>
                          </div>
                        </div>
                      </template>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-tabs-window-item>

            <v-tabs-window-item :value="2">
              <v-card-text class="px-0">
                <ViewData v-if="tab == 2"></ViewData>
              </v-card-text>
            </v-tabs-window-item>
          </v-tabs-window>
        </v-col>
      </v-row>
    </v-card-text>
  </v-container>
</template>
<script>
import validationFields from "../functions/validationFields";
import ViewData from "./includes/ViewData.vue";
export default {
  mixins: [validationFields],
  name: "SurveysAdmin",
  components: {
    ViewData,
  },
  data() {
    return {
      searchTerm: "", // Termino de búsqueda

      tab: null,

      surveys: [
        {
                    title: 'Formulario Registro de Empresarios',
                    description: 'Hola apreciado/a empresario/a, en esta sección podrás actualizar los datos de ubicación y contacto de tu empresa. Recuerda que para el departamento es importante contar con tu información actualizada ya que es útil para realizar planificación de políticas públicas del departamento, incluirte en proyectos o eventos que realicemos, así como en gestiones para las intervenciones de cooperación internacional. Los datos que se solicitan en este formulario son estrictamente confidenciales y se utilizarán únicamente con fines estadísticos, por lo que no se compartirán con otras entidades como: DIAN, Cámara y Comercio, Alcaldías, etc.',
                    icon: 'mdi-emoticon-happy-outline',
                    path: '/formularios/registro-empresarios',
                    imageUrl: require('@/assets/img/forms/1.jpg'),
                },
                {
                    title: 'Formulario de Oferta PST',
                    description: '¡Hola! Apreciado empresario, en esta sección podrá registrar los datos de oferta de su empresa. Recuerde que estos datos no serán utilizados en ningún momento para realizar control fiscal o de impuestos, los datos que nos suministra ayudan a caracterizar la oferta de servicios turísticos a nivel departamental. Esta información es estrictamente confidencial y se utilizará únicamente con fines estadísticos, por lo que no se compartirán con otras entidades como: DIAN, Cámara de Comercio, Alcaldías, etc.',
                    icon: 'mdi-map-marker-outline',
                    path: '/formularios/oferta-pst',
                    imageUrl: require('@/assets/img/forms/2.jpg'),
                },
                {
                    title: 'Formulario Oferta Para Guías',
                    description: 'Hola apreciado/a guía de turismo, en esta sección podrás registrar tus datos. Recuerda que para el departamento es importante contar con esta información actualizada, ya que es útil para realizar planificación de políticas públicas del departamento, planificar proyectos o realizar gestiones para la mejora de nuestro destino turístico. Los datos que se solicitan en este formulario son estrictamente confidenciales y se utilizarán únicamente con fines estadísticos, por lo que no se compartirán con otras entidades como: DIAN, Cámara de Comercio, Alcaldías, etc.',
                    icon: 'mdi-map-marker-outline',
                    path: '/formularios/ofertas-guias',
                    imageUrl: require('@/assets/img/forms/3.jpg'),
                },
                {
                    title: 'Formulario de Empleabilidad',
                    description: 'Apreciado empresario, en esta sección registrará los datos de empleabilidad de su empresa. Recuerde que estos datos no serán utilizados en ningún momento para realizar control fiscal o de impuestos, sino que nos ayudan a caracterizar la empleabilidad generada en el sector turismo en temporadas altas y bajas.',
                    icon: 'mdi-hotel',
                    path: '/formularios/empleabilidad',
                    imageUrl: require('@/assets/img/forms/4.jpg'),
                },
                {
                    title: 'Encuesta Sostenibilidad de Prestadores',
                    description: 'La encuesta de Sostenibilidad prestadores está dirigida únicamente a los PST y administradores de atractivos del departamento, con el fin de tener información actualizada del estado interno de los mismos. Por lo anterior a continuación, se encontrarán preguntas en las temáticas de formalidad, sostenibilidad ambiental y social, accesibilidad, gestión del agua y residuos líquidos, de la energía, de los residuos sólidos, del manejo de la biodiversidad y de la adaptación al cambio climático, entre otras. Los datos que se solicitan en este formulario son estrictamente confidenciales y se utilizarán únicamente con fines estadísticos, por lo que no se compartiran con otras entidades como: DIAN, Camara y Comercio, Alcaldias, Autoridades ambientales, etc.',
                    icon: 'mdi-bus',
                    path: '/formularios/sostenibilidad',
                    imageUrl: require('@/assets/img/forms/5.jpg'),
                },
                {
                    title: 'Formulario Registro de Visitantes',
                    description: 'En este formulario se realiza el registro del (los) turista (s) según su grupo de viaje. Si se registra la información de manera correcta, sus datos quedarán guardados efectivamente en el sistema y será más fácil realizar los registros posteriores en los atractivos que visiten, así como evaluar la percepción del servicio de alojamiento, actividades turísticas y del destino en general.',
                    icon: 'mdi-bus',
                    path: '/formularios/registro-visitantes',
                    imageUrl: require('@/assets/img/forms/6.jpg'),
                },
                {
                    title: 'Formulario Ingreso de Visitantes',
                    description: 'En este formulario se realiza el registro del (los) turista (s) que ingresen a los atractivos. Si se registraron previamente bien sea en el hotel o por la agencia encargada del grupo, solamente debe diligenciar el tipo y número de documento de cada viajero, en caso contrario, el sistema le pedirá otros datos adicionales de cada uno.',
                    icon: 'mdi-bus',
                    path: '/formularios/ingreso-visitantes',
                    imageUrl: require('@/assets/img/forms/7.jpg'),
                },
                {
                    title: 'Percepción Turista Atractivos',
                    description: '¡Gracias por apostarle al turismo en el Guaviare! Su retroalimentación es muy importante para seguir construyendo un destino turístico sostenible. Recuerde que digitar bien su número de identificación para que el sistema identifique si ya está registrado en el sistema, en caso contrario, le haremos unas preguntas adicionales sobre su llegada al departamento.',
                    icon: 'mdi-bus',
                    path: '/formularios/percepcion-turista-atractivo',
                    imageUrl: require('@/assets/img/forms/8.jpg'),
                },
                {
                    title: 'Percepción Turista Alojamiento',
                    description: '¡Gracias por apostarle al turismo en el Guaviare! Su retroalimentación es muy importante para seguir construyendo un destino turístico sostenible. Recuerde que digitar bien su número de identificación para que el sistema identifique si ya está registrado en el sistema, en caso contrario, le haremos unas preguntas adicionales sobre su llegada al departamento.',
                    icon: 'mdi-bus',
                    path: '/formularios/percepcion-turista-alojamiento',
                    imageUrl: require('@/assets/img/forms/9.jpg'),
                },
                {
                    title: 'Percepción del Destino',
                    description: '¡Gracias por apostarle al turismo en el Guaviare! Su retroalimentación es muy importante para seguir construyendo un destino turístico sostenible. Recuerde que digitar bien su número de identificación para que el sistema identifique si ya está registrado en el sistema, en caso contrario, le haremos unas preguntas adicionales sobre su llegada al departamento.',
                    icon: 'mdi-bus',
                    path: '/formularios/percepcion-destino',
                    imageUrl: require('@/assets/img/forms/10.jpg'),
                }
        // {
        //     title: 'Percepción Turista Atractivos',
        //     description: 'Enlaces para copiar y codigo QR para escanear, Enlaces para copiar y codigo QR para escanear',
        //     icon: 'mdi-bus',
        //     path: '/formularios/percepcion-turista-atractivo',
        //     imageUrl: 'https://img.freepik.com/fotos-premium/mujer-cara-viejas-fotos_223509-12696.jpg',
        // },
        // {
        //     title: 'Percepción Turista Alojamiento',
        //     description: 'Enlaces para copiar y codigo QR para escanear, Enlaces para copiar y codigo QR para escanear',
        //     icon: 'mdi-bus',
        //     path: '/formularios/percepcion-turista-alojamiento',
        //     imageUrl: 'https://img.freepik.com/foto-gratis/resumen-borroso-dormitorio-sala-estar-interior-fondo-borroso-foto_74190-9910.jpg',
        // },
      ],
      //   filteredSurveys: [],
    };
  },

  computed: {
    filteredSurveys() {
      const roleId = this.$store.state.user.roles[0]; // ID del rol actual del usuario
      const isGuide = this.$store.state.is_guide;

      // Definir qué roles pueden ver qué formularios
      const roleToSurveysMap = {
        1: [
          // Rol "Atractivo"
          "/formularios/empleabilidad",
          "/formularios/sostenibilidad",
          "/formularios/atractivos",
          "/formularios/ingreso-visitantes",
          "/formularios/oferta-pst",
        ],
        2: [
          // Rol "Alojamiento"
          "/formularios/oferta-pst",
          "/formularios/empleabilidad",
          "/formularios/sostenibilidad",
          "/formularios/registro-visitantes",
        ],
        6: [
          // Rol "Agencia de viaje"
          "/formularios/oferta-pst",
          "/formularios/empleabilidad",
          "/formularios/sostenibilidad",
          "/formularios/registro-visitantes",
        ],
        7: [
          // Rol "Agencia de viajes + alojamiento"
          "/formularios/oferta-pst",
          "/formularios/empleabilidad",
          "/formularios/sostenibilidad",
          "/formularios/registro-visitantes",
        ],
        8: [
          // Rol "Atractivo + Alojamiento"
          "/formularios/oferta-pst",
          "/formularios/empleabilidad",
          "/formularios/sostenibilidad",
          "/formularios/atractivos",
          "/formularios/registro-visitantes",
          "/formularios/ingreso-visitantes",
        ],
      };

      // Obtener las rutas permitidas para el rol
      const allowedPaths = roleToSurveysMap[roleId] || [];

      // Agregar el formulario de guías si el usuario es guía de turismo
      if (isGuide) {
        allowedPaths.push("/formularios/ofertas-guias");
      }

      // Filtrar formularios según el rol y la condición de guía
      return this.surveys.filter((survey) => allowedPaths.includes(survey.path));
    },
  },

  watch: {
    searchTerm: {
      immediate: false,
      handler() {
        if (this.filteredSurveys.length > 0) {
          const interval = setInterval(() => {
            if (this.filteredSurveys.length > 0) {
              // Encuentra el último índice del elemento que no incluye searchTerm
              const indexToRemove = this.filteredSurveys
                .map((survey, index) => ({ survey, index }))
                .reverse() // Revertir el array para empezar desde el final
                .find(
                  ({ survey }) =>
                    !survey.title.toLowerCase().includes(this.searchTerm.toLowerCase())
                )?.index;

              if (indexToRemove !== undefined) {
                this.filteredSurveys.splice(indexToRemove, 1); // Elimina el elemento en el índice encontrado
              } else {
                clearInterval(interval); // Detiene el intervalo cuando todos los elementos han sido revisados
                // Cuando el array esté vacío, repoblamos filteredSurveys con los elementos filtrados
                this.filteredSurveys = this.surveys.filter((survey) =>
                  survey.title.toLowerCase().includes(this.searchTerm.toLowerCase())
                );
              }
            } else {
              clearInterval(interval); // Detiene el intervalo cuando el array está vacío
              // Cuando el array esté vacío, repoblamos filteredSurveys con los elementos filtrados
              this.filteredSurveys = this.surveys.filter((survey) =>
                survey.title.toLowerCase().includes(this.searchTerm.toLowerCase())
              );
            }
          }, 200); // Ajusta el intervalo según tus necesidades
        } else {
          // Si filteredSurveys ya está vacío, repoblamos directamente
          this.filteredSurveys = this.surveys.filter((survey) =>
            survey.title.toLowerCase().includes(this.searchTerm.toLowerCase())
          );
        }
      },
    },
  },
  mounted() {
    // this.filteredSurveys = JSON.parse(JSON.stringify(this.surveys));
  },
  created() {},
  methods: {
    combineClasses(isHovering, item) {
      // Obtén las clases de getAnimationClasses
      const animationClasses = this.getAnimationClasses();

      // Combina las clases condicionales con las clases de animación
      return [
        {
          "elevation-3": isHovering && item.path,
          "elevation-1": !isHovering || !item.path,
          "opacity-40": !item.path,
        },
        animationClasses, // Aquí se agrega la cadena de clases de getAnimationClasses
      ];
    },
  },
};
</script>

<style>
.survey-card {
  display: flex;
  background-color: white;
  overflow: hidden;
}

.survey-card .survey-image {
  flex: 1;
}

.survey-card .survey-content {
  flex: 1.5;
}
</style>

<template>
  <v-container
    class="pa-0"
    style="height: 100%; width: 100%; min-width: 100%; position: relative"
  >
    <NavBarAnimation2
      v-if="isMdOrLower"
      title="Formulario de Oferta PST"
      subTitle="¡Hola! Apreciado empresario, en esta sección podrá registrar los datos de
oferta de su empresa. Recuerde que estos datos no serán utilizados en ningún momento para realizar control fiscal o de impuestos,
los datos que nos suministra ayudan a caracterizar la oferta de servicios turísticos a nivel departamental. Esta información es estrictamente
confidencial y se utilizará únicamente con fines estadísticos, por lo que no se compartirán con otras entidades como: DIAN, Cámara de Comercio,
Alcaldías, etc."
    >
    </NavBarAnimation2>

    <v-app-bar class="elevation-0" v-if="isMdOrLower">
      <v-slide-group
        v-model="stepFormServiceOrder"
        class="py-4"
        center-active
        show-arrows
      >
        <v-slide-group-item
          v-for="(item, index) in stepTitles.filter((step) => step.show)"
          :key="index"
          v-slot="{ isSelected }"
          :value="item.id"
        >
          <v-list-item
            :color="isSelected ? 'primary' : 'grey-lighten-1'"
            @click="stepFormServiceOrder = index + 1"
          >
            <template v-slot:prepend>
              <v-avatar
                :color="
                  isSelected || stepFormServiceOrder > index + 1
                    ? 'primary'
                    : 'grey-lighten-1'
                "
                class="text-white"
                size="x-small"
              >
                <v-icon size="x-small" v-if="stepFormServiceOrder == index + 1"
                  >mdi-pencil</v-icon
                >
                <span v-else>{{ index + 1 }}</span>
              </v-avatar>
            </template>
            <v-list-item-title
              class="text-caption"
              :class="
                isSelected || stepFormServiceOrder > index + 1 ? 'text-primary' : ''
              "
              >{{ item.title }}</v-list-item-title
            >
          </v-list-item>
        </v-slide-group-item>
      </v-slide-group>
    </v-app-bar>

    <v-row class="h-100 mh-100 auth">
      <v-col
        lg="5"
        xl="4"
        cols="12"
        class="d-lg-flex d-none align-center justify-center bg-secondary pa-0"
        style="position: sticky"
      >
        <LeftPanelAnimation2
          title="Formulario de"
          subTitle="¡Hola! Apreciado empresario, en esta sección podrá registrar los datos de
oferta de su empresa. Recuerde que estos datos no serán utilizados en ningún momento para realizar control fiscal o de impuestos,
los datos que nos suministra ayudan a caracterizar la oferta de servicios turísticos a nivel departamental. Esta información es estrictamente
confidencial y se utilizará únicamente con fines estadísticos, por lo que no se compartirán con otras entidades como: DIAN, Cámara de Comercio,
Alcaldías, etc."
          :wordsAnimate="['Oferta PST']"
        ></LeftPanelAnimation2>
      </v-col>
      <v-col lg="7" xl="8" cols="12" style="background: #fff; z-index: 9">
        <v-row class="h-100 mh-100">
          <v-col
            lg="5"
            xl="4"
            cols="12"
            class="d-lg-flex d-none align-center justify-center position-relative"
          >
            <div class="scroll-container-wrapper">
              <div
                class="scroll-container position-relative"
                ref="scrollContainer"
                @scroll="handleScroll"
              >
                <div class="item">
                  <div
                    class="mouse m-2"
                    :class="{
                      'animate__animated animate__fadeInDown': showIndicator,
                      'animate__animated animate__fadeOutDown': !showIndicator,
                    }"
                  ></div>
                </div>
                <v-timeline class="py-8" align="start" side="end" :line-thickness="1">
                  <v-timeline-item
                    v-for="(item, index) in stepTitles.filter((step) => step.show)"
                    :key="index"
                    :class="stepFormServiceOrder > item.id - 1 ? 'success-step' : ''"
                    :dot-color="
                      stepFormServiceOrder > item.id - 1 ? 'primary' : 'dot-customer'
                    "
                    size="small"
                  >
                    <template v-slot:opposite>
                      <p class="font-weight-medium text-end">
                        {{ showGroupLabel(item, index) ? item.label : null }}
                      </p>
                    </template>
                    <template v-slot:icon>
                      <v-btn
                        width="20"
                        height="20"
                        @click="stepFormServiceOrder = item.id"
                        size="x-small"
                        icon
                        variant="text"
                      >
                        <v-icon v-if="stepFormServiceOrder == item.id">mdi-pencil</v-icon>
                        <v-icon v-else-if="stepFormServiceOrder > item.id"
                          >mdi-check</v-icon
                        >
                        <span v-else>{{ index + 1 }}</span>
                      </v-btn>
                    </template>
                    <div class="d-flex">
                      <div :ref="'timelineItem' + item.id"></div>

                      <div
                        class="text-left text-body-2"
                        :class="{ 'text-primary': stepFormServiceOrder > item.id }"
                      >
                        {{ item.title }}
                      </div>
                    </div>
                  </v-timeline-item>
                </v-timeline>
              </div>
              <div v-if="showTopShadow" class="top-shadow"></div>
              <div v-if="showBottomShadow" class="bottom-shadow"></div>
            </div>
          </v-col>

          <v-col lg="7" xl="8" cols="12" class="d-flex align-center justify-center pr-6">
            <v-card width="100%" elevation="0">
              <v-card-text>
                <p class="text-h4 font-weight-bold text-primary">
                  {{ currentTitle.label }}
                </p>
                <p class="text-h5 font-weight-bold">
                  {{ currentTitle.title }}
                </p>
              </v-card-text>
              <v-col md="12" cols="12" class="py-1">
                <br />
              </v-col>

              <v-window
                :touch="false"
                direction="vertical"
                v-model="stepFormServiceOrder"
              >
                <v-window-item :value="1">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep1">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Cuál es el nombre del representante legal de la
                            empresa?</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-model="form.field_b_legal_rep_name"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col md="6" cols="12" class="py-1">
                          <span class="font-weight-bold">NIT</span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(50)]"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                            v-model="form.field_b_nit"
                          ></v-text-field>
                        </v-col>
                        <v-col md="6" cols="12" class="py-1">
                          <span class="font-weight-bold">Nombre del Establecimiento</span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                            v-model="form.field_b_establishment_name"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="2">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep2">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">Dirección</span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                            v-model="form.field_b_address"
                          ></v-text-field>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">Email</span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.emailFormat]"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                            v-model="form.field_b_email"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="3">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep3">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Registro Nacional de Turismo (RNT)</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                            v-model="form.field_b_rnt"
                          ></v-text-field>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold">Categoría de RNT</span>
                          <span class="font-weight-bold">*</span>

                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_b_rnt_category"
                            :items="formItems.itemsRntCategory"
                            item-value="id"
                            item-title="text"
                            :rules="[validations.required]"
                            variant="underlined"
                          >
                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-radio
                                      color="primary"
                                      :true-value="item.raw.id"
                                      :model-value="isActive"
                                      v-model="form.field_b_rnt_category"
                                    ></v-radio>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="index + 1 < formItems.itemsRntCategory.length"
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="4">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep4">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Señale las actividades económicas registradas en Cámara de
                            Comercio de este establecimiento
                          </span>
                          <span class="font-weight-bold">*</span>

                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_b_economic_activities"
                            :items="formItems.itemsEconomicActivities"
                            item-value="id"
                            item-title="text"
                            :rules="[validations.checkboxRequired]"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_b_economic_activities.length - 2 }}
                                {{
                                  form.field_b_economic_activities.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsEconomicActivities.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="5">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep5">
                      <v-row>
                        <v-col
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="
                            form.field_b_rnt_category == 135 ||
                            form.field_b_economic_activities.includes(154)
                          "
                        >
                          <span class="font-weight-bold">
                            ¿En qué tipología de turismo se clasifica su empresa?
                          </span>
                          <span class="font-weight-bold">*</span>

                          <div class="mt-4">
                            <template
                              v-for="(item, index) in formItems.itemsTourismType"
                              :key="index"
                            >
                              <v-divider class="my-0"></v-divider>

                              <div v-if="item.length > 1">
                                <v-list-item @click="item.show = !item.show">
                                  <template v-slot:prepend>
                                    <v-icon v-if="item.show">mdi-chevron-down</v-icon>
                                    <v-icon v-else>mdi-chevron-right</v-icon>
                                  </template>

                                  <v-list-item-title class="text-wrap">{{
                                    index
                                  }}</v-list-item-title>
                                </v-list-item>

                                <template v-if="item.show">
                                  <div class="list-container-documents px-4">
                                    <div
                                      v-for="(itemSub, indexSub) in item"
                                      :key="indexSub"
                                    >
                                      <v-checkbox
                                        color="primary"
                                        hide-details
                                        v-model="form.field_b_tourism_type"
                                        :label="itemSub.text"
                                        :value="itemSub.id"
                                        :rules="[validations.checkboxRequired]"
                                      >
                                      </v-checkbox>
                                    </div>
                                  </div>
                                </template>
                              </div>

                              <v-list-item v-if="item.length == 1">
                                <v-checkbox
                                  v-model="form.field_b_tourism_type"
                                  color="primary"
                                  hide-details
                                  :label="item[0].text"
                                  :value="item[0].id"
                                  :rules="[validations.checkboxRequired]"
                                >
                                </v-checkbox>
                              </v-list-item>
                            </template>
                          </div>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="6">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep6">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >La empresa realiza o promueve actividades de turismo de base
                            comunitaria? (comunidad como gestor y/o protagonista)</span
                          >
                          <span class="font-weight-bold">*</span>

                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_b_community_tourism"
                          >
                            <v-radio
                              v-for="(item, index) in formItems.itemsCommunityTourism"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_b_community_tourism == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="7">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep7">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Indique cuáles de las siguientes actividades ofrece su
                            empresa</span
                          >
                          <span class="font-weight-bold">*</span>

                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_b_offered_activities"
                            :items="formItems.itemsOfferedActivities"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_b_offered_activities.length - 2 }}
                                {{
                                  form.field_b_offered_activities.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="index + 1 < formItems.itemsOfferedActivities.length"
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="8">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep8">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Durante la temporada de <b>{{ label_season }}</b> cuál es el
                            porcentaje de ocupación?</span
                          >
                          <span class="font-weight-bold">*</span>

                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2 two-columns"
                            v-model="form.field_b_occupancy_rate"
                          >
                            <v-radio
                              v-for="(item, index) in formItems.itemsOccupancyRate"
                              :key="index"
                              :label="item.text"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_b_occupancy_rate == item.id,
                              }"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Cuál es el valor promedio de los servicios de alojamiento
                            que ofrece?</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2 two-columns"
                            v-model="form.field_b_avg_accommodation_price"
                          >
                            <v-radio
                              v-for="(
                                item, index
                              ) in formItems.itemsAvgAccommodationPrice"
                              :key="index"
                              :label="item.text"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_b_avg_accommodation_price == item.id,
                              }"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="9">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep9">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Cuál es la clasificación de su alojamiento?</span
                          >
                          <span class="font-weight-bold">*</span>

                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2 two-columns"
                            v-model="form.field_b_accommodation_class"
                          >
                            <v-radio
                              v-for="(item, index) in formItems.itemsAccommodationClass"
                              :key="index"
                              :label="item.text"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_b_accommodation_class == item.id,
                              }"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="10">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep10">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Indique la cantidad de habitaciones disponibles</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-numbers-only-app
                            color="primary"
                            variant="underlined"
                            v-model="form.field_b_room_count"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Indique la cantidad de camas disponibles</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-numbers-only-app
                            color="primary"
                            variant="underlined"
                            v-model="form.field_b_bed_count"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >Indique la cantidad máxima de huéspedes que puede
                            alojar</span
                          >
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-numbers-only-app
                            color="primary"
                            variant="underlined"
                            v-model="form.field_b_max_guests"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="11">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep11">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Su establecimiento brinda algún servicio adicional?</span
                          >
                          <span class="font-weight-bold">*</span>

                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_b_additional_services"
                            :items="formItems.itemsAdditionalServices"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_b_additional_services.length - 2 }}
                                {{
                                  form.field_b_additional_services.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  index + 1 < formItems.itemsAdditionalServices.length
                                "
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="12">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep12">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Cuál es el precio promedio de los platos?</span
                          >
                          <span class="font-weight-bold">*</span>

                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2 two-columns"
                            v-model="form.field_b_avg_dish_price"
                          >
                            <v-radio
                              v-for="(item, index) in formItems.itemsAvgDishPrice"
                              :key="index"
                              :label="item.text"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_b_avg_dish_price == item,
                              }"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="13">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep13">
                      <v-row>
                        <v-col
                          sm="12"
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="
                            form.field_b_rnt_category == 144 ||
                            form.field_b_economic_activities.includes(150)
                          "
                        >
                          <span class="font-weight-bold"
                            >Indique la cantidad de mesas disponibles del restaurante
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-numbers-only-app
                            v-model="form.field_b_restaurant_tables"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col
                          sm="12"
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="
                            form.field_b_rnt_category == 144 ||
                            form.field_b_economic_activities.includes(150)
                          "
                        >
                          <span class="font-weight-bold"
                            >Indique la cantidad de sillas disponibles del restaurante
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-numbers-only-app
                            v-model="form.field_b_restaurant_chairs"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col
                          sm="12"
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="
                            form.field_b_rnt_category == 137 ||
                            form.field_b_economic_activities.includes(149)
                          "
                        >
                          <span class="font-weight-bold"
                            >Indique la cantidad de mesas disponibles del bar
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-numbers-only-app
                            v-model="form.field_b_bar_tables"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col
                          sm="12"
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="
                            form.field_b_rnt_category == 137 ||
                            form.field_b_economic_activities.includes(149)
                          "
                        >
                          <span class="font-weight-bold"
                            >Indique la cantidad de sillas disponibles del bar
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-numbers-only-app
                            v-model="form.field_b_bar_chairs"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col
                          sm="12"
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="
                            form.field_b_rnt_category == 137 ||
                            form.field_b_economic_activities.includes(149)
                          "
                        >
                          <span class="font-weight-bold"
                            >Indique el aforo máximo de clientes en el establecimiento
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-numbers-only-app
                            v-model="form.field_b_max_capacity"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="14">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep14">
                      <v-row>
                        <v-col
                          sm="12"
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="
                            form.field_b_rnt_category == 139 ||
                            form.field_b_rnt_category == 147 ||
                            form.field_b_rnt_category == 247 ||
                            form.field_b_economic_activities.includes(154)
                          "
                        >
                          <span class="font-weight-bold"
                            >Indique la aforo máximo de clientes en el espacio cultural
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-numbers-only-app
                            v-model="form.field_b_cultural_space_capacity"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col
                          sm="12"
                          md="12"
                          cols="12"
                          class="py-1"
                          v-if="
                            form.field_b_rnt_category == 136 ||
                            form.field_b_rnt_category == 141 ||
                            form.field_b_economic_activities.includes(153)
                          "
                        >
                          <span class="font-weight-bold"
                            >Indique la cantidad de vehículos disponibles
                          </span>
                          <span class="font-weight-bold">*</span>
                          <v-text-field
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-numbers-only-app
                            v-model="form.field_b_vehicle_count"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="15">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep15">
                      <v-row>
                        <v-col sm="12" md="12" cols="12" class="py-1 align-center">
                          <span class="font-weight-bold"
                            >Indique cuántos turistas atendió durante la temporada de
                            <b>{{ label_season }}</b>
                          </span>
                          <v-text-field
                            min-width="200px"
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-numbers-only-app
                            v-model="form.field_b_tourists_served"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                        <v-col sm="12" md="12" cols="12" class="py-1 align-center">
                          <span class="font-weight-bold"
                            >¿Qué porcentaje de los turistas atendidos en la temporada de
                            <b>{{ label_season }}</b>
                            eran extranjeros? *
                          </span>
                          <v-text-field
                            min-width="200px"
                            :rules="[validations.required, validations.maxLength(255)]"
                            v-numbers-only-app
                            v-model="form.field_b_foreign_tourists_pct"
                            color="primary"
                            variant="underlined"
                            placeholder=""
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
                <v-window-item :value="16">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep16">
                      <v-row>
                        <v-col sm="12" md="12" cols="12" class="py-1 align-center">
                          <span class="font-weight-bold"
                            >Indique el porcentaje de ventas según la forma de
                            comercialización *
                          </span>
                          <v-row>
                            <v-col
                              sm="12"
                              md="12"
                              cols="12"
                              class="py-1 d-md-flex align-center"
                            >
                              <span>% Venta directa al Cliente (B2C) </span>
                              <v-text-field
                                min-width="200"
                                max-width="200"
                                class="ml-auto"
                                :rules="[
                                  validations.required,
                                  validations.maxLength(255),
                                ]"
                                v-numbers-only-app
                                v-model="form.field_b_sales_b2c_pct"
                                color="primary"
                                variant="underlined"
                                placeholder=""
                              ></v-text-field>
                            </v-col>
                            <v-col
                              sm="12"
                              md="12"
                              cols="12"
                              class="py-1 d-md-flex align-center"
                            >
                              <span class=""
                                >% Venta a través de empresas aliadas (B2B)
                              </span>
                              <v-text-field
                                min-width="200"
                                max-width="200"
                                class="ml-auto"
                                :rules="[
                                  validations.required,
                                  validations.maxLength(255),
                                ]"
                                v-numbers-only-app
                                v-model="form.field_b_sales_b2b_pct"
                                color="primary"
                                variant="underlined"
                                placeholder=""
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>

                <v-window-item :value="17">
                  <v-card-text class="text-body-2">
                    <v-form ref="formStep17">
                      <v-row>
                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Pertenece a alguno de los siguientes
                            gremios/asociaciones/colectivos?</span
                          >
                          <span class="font-weight-bold">*</span>

                          <v-select
                            :menu-props="{ 'max-width': '100%' }"
                            v-model="form.field_b_associations"
                            :items="formItems.itemsAssociations"
                            :rules="[validations.checkboxRequired]"
                            item-value="id"
                            item-title="text"
                            multiple
                            variant="underlined"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip v-if="index < 2">
                                <span>{{
                                  item.title && item.title.length > 20
                                    ? item.title.substring(0, 20) + "..."
                                    : item.title
                                }}</span>
                              </v-chip>
                              <span
                                v-if="index === 2"
                                class="text-grey text-caption align-self-center"
                              >
                                (+{{ form.field_b_associations.length - 2 }}
                                {{
                                  form.field_b_associations.length - 2 == 1
                                    ? "opción"
                                    : "opciones"
                                }})
                              </span>
                            </template>

                            <template v-slot:item="{ props, item, index }">
                              <v-list-item v-bind="props" class="text-caption-font">
                                <template v-slot:prepend="{ isActive }">
                                  <v-list-item-action start>
                                    <v-checkbox-btn
                                      color="primary"
                                      :true-value="true"
                                      :model-value="isActive"
                                    ></v-checkbox-btn>
                                  </v-list-item-action>
                                </template>

                                <v-list-item-title class="text-wrap text-caption">
                                  <span class="text-caption">{{ item.raw.title }} </span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider
                                v-if="index + 1 < formItems.itemsAssociations.length"
                              ></v-divider>
                            </template>
                          </v-select>
                        </v-col>

                        <v-col md="12" cols="12" class="py-1">
                          <span class="font-weight-bold"
                            >¿Conocen en la empresa de la estrategia contra la
                            ESCNNA?</span
                          >
                          <span class="font-weight-bold">*</span>

                          <v-radio-group
                            :rules="[validations.required]"
                            color="primary"
                            class="mt-2"
                            v-model="form.field_b_escnna_strategy"
                          >
                            <v-radio
                              v-for="(item, index) in formItems.itemsEscnnaStrategy"
                              :key="index"
                              :value="item.id"
                              class="pa-1 my-1"
                              :class="{
                                ' rounded-lg animate__animated animate__headShake bg-primary-second':
                                  form.field_b_escnna_strategy == item.id,
                              }"
                            >
                              <template v-slot:label="">
                                <div>
                                  {{ item.text }}
                                  <p
                                    class="text-caption font-italic"
                                    v-if="item.description"
                                  >
                                    {{ item.description }}
                                  </p>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-window-item>
              </v-window>
              <v-card-actions>
                <br />
              </v-card-actions>
              <v-card-actions class="mb-9">
                <v-spacer></v-spacer>

                <v-btn
                  class="text-capitalize"
                  :disabled="stepFormServiceOrder > 1 ? false : true"
                  variant="text"
                  @click="goToPreviousStep"
                >
                  Anterior
                </v-btn>
                <v-btn
                  class="text-capitalize"
                  v-if="
                    stepFormServiceOrder <
                    stepTitles.filter((step) => step.show)[
                      stepTitles.filter((step) => step.show).length - 1
                    ].id
                  "
                  color="primary"
                  variant="text"
                  @click="goToNextStep"
                >
                  Siguiente
                </v-btn>
                <v-btn
                  @click="saveForm"
                  class="text-capitalize"
                  v-else
                  color="primary"
                  variant="flat"
                >
                  Finalizar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <!-- <v-col cols="12" class="d-flex align-center justify-center"></v-col> -->
        </v-row>
      </v-col>
    </v-row>
    <v-overlay
      contained
      persistent
      :close-on-back="false"
      :close-on-content-click="false"
      class="align-center justify-center"
      scrim="#FFF"
      :opacity="1"
      v-model="loadingPageOverlay"
    >
      <div class="text-center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <h4 class="text-h4 mt-4 font-weight-medium text-primary">Enviando formulario</h4>
        <p class="text-h6 font-weight-medium mt-2">
          Por favor, espera mientras procesamos tu solicitud.
        </p>
      </div>
    </v-overlay>
    <UserExperience></UserExperience>
    <DialogResponse
      @cancel="cancelDialogResponse"
      v-if="dialogResponse"
      :dialog="dialogResponse"
    ></DialogResponse>
  </v-container>

  <v-dialog persistent v-model="dialogSeason" max-width="600">
    <v-card>
      <v-card-title class="text-h5 mt-8 font-weight-bold text-center"> Formulario no disponible </v-card-title>
      <v-card-text class="px-6 mb-8 text-center">
        Sabemos que deseas responder, pero el periodo para este formulario ha finalizado.
        Por favor, consulta las próximas fechas disponibles.
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- <div style="    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    max-width: 100%;
    min-height: 100vh;
    min-height: 100dvh;
    position: relative;">
       
    </div> -->
</template>

<script>
import anime from "animejs/lib/anime.es.js";
import { useDisplay } from "vuetify";
import validationFields from "../functions/validationFields";
import LeftPanelAnimation2 from "./includes/LeftPanelAnimation2.vue";
import NavBarAnimation2 from "./includes/NavBarAnimation2.vue";
import UserExperience from "./includes/UserExperience.vue";
import axios from "axios";
import { useToast } from "vue-toastification";
import ErrorLayoutComponent from "@/components/error-request/ErrorLayoutComponent.vue";
import DialogResponse from "@/components/dialog/DialogResponse.vue";

export default {
  name: "FormOfferPst",
  mixins: [validationFields],
  components: {
    LeftPanelAnimation2,
    NavBarAnimation2,
    UserExperience,
    DialogResponse,
  },

  setup() {
    const { mdAndDown } = useDisplay();

    return {
      isMdOrLower: mdAndDown,
    };
  },

  data() {
    return {
      dialogSeason: false,
      label_season: null,
      season_id: null,
      showTopShadow: false,
      showBottomShadow: true,
      formConfig: null,
      toast: useToast(),
      loadingPageOverlay: false,
      dialogResponse: null,
      formItems: {
        itemsRntCategory: [],
        itemsEconomicActivities: [],
        itemsTourismType: [],
        itemsCommunityTourism: [],
        itemsOfferedActivities: [],
        itemsOccupancyRate: [],
        itemsAvgAccommodationPrice: [],
        itemsAccommodationClass: [],
        itemsAdditionalServices: [],
        itemsAvgDishPrice: [],
        itemsAssociations: [],
        itemsEscnnaStrategy: [],
      },

      form: {
        field_b_legal_rep_name: null,
        field_b_nit: null,
        field_b_establishment_name: null,
        field_b_address: null,
        field_b_email: null,
        field_b_rnt: null,
        field_b_rnt_category: null,
        field_b_economic_activities: [],
        field_b_tourism_type: [],
        field_b_community_tourism: null,
        field_b_offered_activities: [],
        field_b_occupancy_rate: null,
        field_b_avg_accommodation_price: null,
        field_b_accommodation_class: null,
        field_b_room_count: null,
        field_b_bed_count: null,
        field_b_max_guests: null,
        field_b_additional_services: [],
        field_b_avg_dish_price: null,
        field_b_restaurant_tables: null,
        field_b_restaurant_chairs: null,
        field_b_bar_tables: null,
        field_b_bar_chairs: null,
        field_b_max_capacity: null,
        field_b_cultural_space_capacity: null,
        field_b_vehicle_count: null,
        field_b_tourists_served: null,
        field_b_foreign_tourists_pct: null,
        field_b_sales_b2c_pct: null,
        field_b_sales_b2b_pct: null,
        field_b_associations: [],
        field_b_escnna_strategy: null,
      },

      form_relations_question: null,

      isMobile: false,

      vw: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
      vh: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),

      stepFormServiceOrder: 1,
      stepTitles: [
        {
          group: 1,
          label: "Datos de la Empresa",
          id: 1,
          title: "Información Legal",
          show: true,
        },
        {
          group: 1,
          id: 2,
          title: "Información de Contacto",
          show: true,
        },
        {
          group: 1,
          id: 3,
          title: "Registro y Categoría",
          show: true,
        },
        {
          group: 1,
          id: 4,
          title: "Actividades Económicas",
          show: true,
        },
        {
          group: 2,
          id: 5,
          label: "Servicios del Turismo y Alojamiento",
          title: "Clasificación de Turismo",
          show: false,
        },
        {
          group: 2,
          id: 6,
          label: "Servicios del Turismo y Alojamiento",
          title: "Turismo de Base Comunitaria",
          show: false,
        },
        {
          group: 2,
          id: 7,
          label: "Servicios del Turismo y Alojamiento",
          title: "Actividades Ofrecidas",
          show: false,
        },
        {
          group: 2,
          id: 8,
          label: "Servicios del Turismo y Alojamiento",
          title: "Ocupación y Alojamiento",
          show: false,
        },
        {
          group: 2,
          id: 9,
          label: "Servicios del Turismo y Alojamiento",
          title: "Clasificación del Alojamiento",
          show: false,
        },
        {
          group: 2,
          id: 10,
          label: "Servicios del Turismo y Alojamiento",
          title: "Capacidad de Alojamiento",
          show: false,
        },
        {
          group: 2,
          id: 11,
          label: "Servicios del Turismo y Alojamiento",
          title: "Servicios Adicionales",
          show: false,
        },
        {
          group: 2,
          id: 12,
          label: "Servicios del Turismo y Alojamiento",
          title: "Precio Promedio de los Platos",
          show: false,
        },
        {
          group: 2,
          id: 13,
          label: "Servicios del Turismo y Alojamiento",
          title: "Restaurantes y Bar",
          show: false,
        },
        {
          group: 2,
          id: 14,
          label: "Servicios del Turismo y Alojamiento",
          title: "Aforo y Vehículos",
          show: false,
        },
        {
          group: 3,
          id: 15,
          label: "Datos Comerciales y de Turistas",
          title: "Información de Turistas",
          show: true,
        },
        {
          group: 3,
          id: 16,
          label: "Datos Comerciales y de Turistas",
          title: "Información de Ventas",
          show: true,
        },
        {
          group: 3,
          id: 17,
          label: "Datos Comerciales y de Turistas",
          title: "Asociaciones y Estrategias",
          show: true,
        },
      ],

      activeWatch: true,

      showIndicator: true, // Initially show the indicator
    };
  },
  watch: {
    stepFormServiceOrder(newVal) {
      if (newVal < this.stepTitles.length) {
        // this.scrollToItem(newVal);
      }
    },
    "form.field_b_rnt_category"() {
      this.showStepForms();
    },
    "form.field_b_economic_activities"() {
      this.showStepForms();
    },
  },
  mounted() {
    this.starryNight();
    this.shootingStars();

    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);

    this.getInfoForm();

    //ocultar pasos y habilitar pasos 6, 15, 16, 17
    if (
      this.$store &&
      this.$store.state &&
      this.$store.state.user &&
      this.$store.state.user.roles &&
      this.$store.state.user.roles[0] == 1
    ) {
      const form1 = this.stepTitles.findIndex((step) => step.id === 1);
      this.stepTitles[form1].show = false;

      const form2 = this.stepTitles.findIndex((step) => step.id === 2);
      this.stepTitles[form2].show = false;

      const form3 = this.stepTitles.findIndex((step) => step.id === 3);
      this.stepTitles[form3].show = false;

      const form4 = this.stepTitles.findIndex((step) => step.id === 4);
      this.stepTitles[form4].show = false;

      const form5 = this.stepTitles.findIndex((step) => step.id === 5);
      this.stepTitles[form5].show = false;

      const form6 = this.stepTitles.findIndex((step) => step.id === 6);
      this.stepTitles[form6].show = true;

      const form7 = this.stepTitles.findIndex((step) => step.id === 7);
      this.stepTitles[form7].show = false;

      const form8 = this.stepTitles.findIndex((step) => step.id === 8);
      this.stepTitles[form8].show = false;

      const form9 = this.stepTitles.findIndex((step) => step.id === 9);
      this.stepTitles[form9].show = false;

      const form10 = this.stepTitles.findIndex((step) => step.id === 10);
      this.stepTitles[form10].show = false;

      const form11 = this.stepTitles.findIndex((step) => step.id === 11);
      this.stepTitles[form11].show = false;

      const form12 = this.stepTitles.findIndex((step) => step.id === 12);
      this.stepTitles[form12].show = false;

      const form13 = this.stepTitles.findIndex((step) => step.id === 13);
      this.stepTitles[form13].show = false;

      const form14 = this.stepTitles.findIndex((step) => step.id === 14);
      this.stepTitles[form14].show = false;

      const form15 = this.stepTitles.findIndex((step) => step.id === 15);
      this.stepTitles[form15].show = true;

      const form16 = this.stepTitles.findIndex((step) => step.id === 16);
      this.stepTitles[form16].show = true;

      const form17 = this.stepTitles.findIndex((step) => step.id === 17);
      this.stepTitles[form17].show = true;

      this.stepFormServiceOrder = 6;
    } else {
      // MOSTRAR TODOS LOS PASOS
      this.stepTitles.forEach((step) => {
        step.show = true;
      });
    }
  },
  methods: {
    async saveForm() {
      this.loadingPageOverlay = true;
      let otherThis = this;
      await setTimeout(async function () {
        const allValid = await otherThis.validateAllSteps();

        if (allValid) {
          otherThis.formResponseStore();
        } else {
          otherThis.loadingPageOverlay = false;
          console.log("Hay errores en algunos de los formularios.");
        }
      }, 1000);
    },
    async validateAllSteps() {
      let allValid = true;

      // Filtramos los pasos visibles y recorremos su longitud
      const visibleSteps = this.stepTitles.filter((step) => step.show);

      for (let i = 0; i < visibleSteps.length; i++) {
        this.stepFormServiceOrder = visibleSteps[i].id; // Navegar al paso correspondiente
        await this.$nextTick(); // Esperar a que el DOM se actualice y el formulario se monte

        const formRef = this.$refs[`formStep${visibleSteps[i].id}`];

        if (formRef && formRef.validate) {
          const { valid } = await formRef.validate();

          if (!valid) {
            allValid = false;

            // Resaltar los campos con errores
            this.$nextTick(() => {
              const firstErrorField = formRef.$el.querySelector(".v-input--error");
              if (firstErrorField) {
                firstErrorField.scrollIntoView({ behavior: "smooth" });
              }
            });

            break; // Detener la validación en el primer error encontrado
          }
        }
      }

      return allValid;
    },
    formResponseStore() {
      let url = process.env.VUE_APP_API_URL;

      axios
        .post(url + "/form-responses/store", {
          form: this.form,
          form_relations_question: this.form_relations_question,
          form_id: 2,
          user_id: this.$store.state.user.id,
          season_id: this.season_id,
        })
        .then((response) => {
          this.dialogResponse = {
            title: "¡Formulario enviado con éxito!",
            message:
              "Tus respuestas han sido registradas correctamente. Gracias por tomarte el tiempo de completar el formulario. Puedes estar seguro de que tus datos han sido guardados de manera segura.",
            type: "success",
            color: "secondary",
            icon: "mdi-check",
            show: true,
            redirect: false,
          };
          console.log(response);
          this.loadingPageOverlay = false;
          //resetear todo el componente
          this.resetFormFields();
          this.stepFormServiceOrder = 1;
        })
        .catch((error) => {
          this.loadingPageOverlay = false;
          if (
            error &&
            error.response &&
            error.response.status &&
            error.response.status == 422
          ) {
            //ERROR RETORNADO POR LAS VALIDACIONES
            this.toast.error({
              component: ErrorLayoutComponent,
              props: {
                errorMessage: error.response.data,
              },
              timeout: 8000,
            });
          } else {
            this.dialogResponse = {
              title: "Error al enviar el formulario",
              message:
                "Ocurrió un problema al intentar guardar tus respuestas. Por favor, revisa los campos y vuelve a intentarlo. Si el problema persiste, contacta al soporte técnico.",
              type: "success",
              color: "red-app",
              icon: "mdi-cloud-alert",
              show: true,
              redirect: false,
            };
          }

          console.log(error);
        });
    },
    resetFormFields() {
      this.form = {
        field_b_legal_rep_name: null,
        field_b_nit: null,
        field_b_establishment_name: null,
        field_b_address: null,
        field_b_email: null,
        field_b_rnt: null,
        field_b_rnt_category: null,
        field_b_economic_activities: [],
        field_b_tourism_type: [],
        field_b_community_tourism: null,
        field_b_offered_activities: [],
        field_b_occupancy_rate: null,
        field_b_avg_accommodation_price: null,
        field_b_accommodation_class: null,
        field_b_room_count: null,
        field_b_bed_count: null,
        field_b_max_guests: null,
        field_b_additional_services: [],
        field_b_avg_dish_price: null,
        field_b_restaurant_tables: null,
        field_b_restaurant_chairs: null,
        field_b_bar_tables: null,
        field_b_bar_chairs: null,
        field_b_max_capacity: null,
        field_b_cultural_space_capacity: null,
        field_b_vehicle_count: null,
        field_b_tourists_served: null,
        field_b_foreign_tourists_pct: null,
        field_b_sales_b2c_pct: null,
        field_b_sales_b2b_pct: null,
        field_b_associations: [],
        field_b_escnna_strategy: null,
      };

      this.stepFormServiceOrder = 1;
    },
    showStepForms() {
      if (
        this.form.field_b_rnt_category == 135 ||
        this.form.field_b_economic_activities.includes(152) ||
        this.form.field_b_economic_activities.includes(154)
      ) {
        const form5 = this.stepTitles.findIndex((step) => step.id === 5);
        const form6 = this.stepTitles.findIndex((step) => step.id === 6);
        const form7 = this.stepTitles.findIndex((step) => step.id === 7);

        this.stepTitles[form5].show = true;
        if (this.activeWatch) {
          this.form.field_b_tourism_type = [];
        }
        if (
          this.form.field_b_rnt_category == 135 ||
          this.form.field_b_economic_activities.includes(152)
        ) {
          this.stepTitles[form6].show = true;
          this.stepTitles[form7].show = true;
          if (this.activeWatch) {
            this.form.field_b_community_tourism = null;
            this.form.field_b_offered_activities = [];
          }
        }
      } else {
        const form5 = this.stepTitles.findIndex((step) => step.id === 5);
        const form6 = this.stepTitles.findIndex((step) => step.id === 6);
        const form7 = this.stepTitles.findIndex((step) => step.id === 7);

        this.stepTitles[form5].show = false;
        this.stepTitles[form6].show = false;
        this.stepTitles[form7].show = false;

        if (this.activeWatch) {
          this.form.field_b_tourism_type = [];
          this.form.field_b_community_tourism = null;
          this.form.field_b_offered_activities = [];
        }
      }

      // if(this.form.field_b_economic_activities.includes(154)){
      //   const form5 = this.stepTitles.findIndex((step) => step.id === 5);

      //   this.stepTitles[form5].show = true;
      //   // this.form.field_b_tourism_type = [];
      // }else{
      //   const form5 = this.stepTitles.findIndex((step) => step.id === 5);

      //   this.stepTitles[form5].show = false;
      //   this.form.field_b_tourism_type = [];

      // }

      if (
        this.form.field_b_rnt_category == 137 ||
        this.form.field_b_economic_activities.includes(149)
      ) {
        if (this.activeWatch) {
          this.form.field_b_bar_tables = null;
          this.form.field_b_bar_chairs = null;
          this.form.field_b_max_capacity = null;
        }
      } else {
        if (this.activeWatch) {
          this.form.field_b_bar_tables = null;
          this.form.field_b_bar_chairs = null;
          this.form.field_b_max_capacity = null;
        }
      }

      if (
        this.form.field_b_rnt_category == 139 ||
        this.form.field_b_rnt_category == 147 ||
        this.form.field_b_rnt_category == 247 ||
        this.form.field_b_economic_activities.includes(154)
      ) {
        if (this.activeWatch) {
          this.form.field_b_cultural_space_capacity = null;
        }
      } else {
        if (this.activeWatch) {
          this.form.field_b_cultural_space_capacity = null;
        }
      }

      if (
        this.form.field_b_rnt_category == 136 ||
        this.form.field_b_rnt_category == 141 ||
        this.form.field_b_economic_activities.includes(153)
      ) {
        if (this.activeWatch) {
          this.form.field_b_vehicle_count = null;
        }
      } else {
        if (this.activeWatch) {
          this.form.field_b_vehicle_count = null;
        }
      }

      if (
        this.form.field_b_rnt_category == 143 ||
        this.form.field_b_rnt_category == 146 ||
        this.form.field_b_rnt_category == 246 ||
        this.form.field_b_rnt_category == 148 ||
        this.form.field_b_economic_activities.includes(151)
      ) {
        const form8 = this.stepTitles.findIndex((step) => step.id === 8);
        const form9 = this.stepTitles.findIndex((step) => step.id === 9);
        const form10 = this.stepTitles.findIndex((step) => step.id === 10);
        const form11 = this.stepTitles.findIndex((step) => step.id === 11);

        this.stepTitles[form8].show = true;
        this.stepTitles[form9].show = true;
        this.stepTitles[form10].show = true;
        this.stepTitles[form11].show = true;

        if (this.activeWatch) {
          this.form.field_b_occupancy_rate = null;
          this.form.field_b_avg_accommodation_price = null;
          this.form.field_b_accommodation_class = null;
          this.form.field_b_room_count = null;
          this.form.field_b_bed_count = null;
          this.form.field_b_max_guests = null;
          this.form.field_b_additional_services = [];
        }
      } else {
        const form8 = this.stepTitles.findIndex((step) => step.id === 8);
        const form9 = this.stepTitles.findIndex((step) => step.id === 9);
        const form10 = this.stepTitles.findIndex((step) => step.id === 10);
        const form11 = this.stepTitles.findIndex((step) => step.id === 11);

        this.stepTitles[form8].show = false;
        this.stepTitles[form9].show = false;
        this.stepTitles[form10].show = false;
        this.stepTitles[form11].show = false;

        if (this.activeWatch) {
          this.form.field_b_occupancy_rate = null;
          this.form.field_b_avg_accommodation_price = null;
          this.form.field_b_accommodation_class = null;
          this.form.field_b_room_count = null;
          this.form.field_b_bed_count = null;
          this.form.field_b_max_guests = null;
          this.form.field_b_additional_services = [];
        }
      }

      if (
        this.form.field_b_rnt_category == 144 ||
        this.form.field_b_economic_activities.includes(150)
      ) {
        const form12 = this.stepTitles.findIndex((step) => step.id === 12);
        const form13 = this.stepTitles.findIndex((step) => step.id === 13);

        this.stepTitles[form12].show = true;
        this.stepTitles[form13].show = true;

        if (this.activeWatch) {
          this.form.field_b_avg_dish_price = null;
          this.form.field_b_restaurant_tables = null;
          this.form.field_b_restaurant_chairs = null;
        }
      } else {
        const form12 = this.stepTitles.findIndex((step) => step.id === 12);
        const form13 = this.stepTitles.findIndex((step) => step.id === 13);

        this.stepTitles[form12].show = false;
        this.stepTitles[form13].show = false;

        if (this.activeWatch) {
          this.form.field_b_avg_dish_price = null;
          this.form.field_b_restaurant_tables = null;
          this.form.field_b_restaurant_chairs = null;
        }
      }

      if (
        this.form.field_b_rnt_category == 139 ||
        this.form.field_b_rnt_category == 147 ||
        this.form.field_b_rnt_category == 247 ||
        this.form.field_b_economic_activities.includes(153) ||
        this.form.field_b_economic_activities.includes(154) ||
        this.form.field_b_rnt_category == 136 ||
        this.form.field_b_rnt_category == 141
      ) {
        const form14 = this.stepTitles.findIndex((step) => step.id === 14);
        this.stepTitles[form14].show = true;
      } else {
        const form14 = this.stepTitles.findIndex((step) => step.id === 14);
        this.stepTitles[form14].show = false;
      }

      if (
        this.form.field_b_rnt_category == 137 ||
        this.form.field_b_economic_activities.includes(149) ||
        this.form.field_b_rnt_category == 144 ||
        this.form.field_b_economic_activities.includes(150)
      ) {
        const form13 = this.stepTitles.findIndex((step) => step.id === 13);
        this.stepTitles[form13].show = true;
      } else {
        const form13 = this.stepTitles.findIndex((step) => step.id === 13);
        this.stepTitles[form13].show = false;
      }
    },
    async getInfoForm() {
      try {
        let url = process.env.VUE_APP_API_URL;
        const token = this.$store.state.token;

        axios
          .get(url + `/forms/show/2`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            this.formConfig = response.data.form_config;
            this.form_relations_question = response.data.form_relations_question;

            if (this.formConfig && this.formConfig.questions) {
              const field_b_rnt_category = this.formConfig.questions.find(
                (q) => q.v_model === "field_b_rnt_category"
              );
              if (field_b_rnt_category) {
                this.formItems.itemsRntCategory = field_b_rnt_category.options;
              }

              const field_b_economic_activities = this.formConfig.questions.find(
                (q) => q.v_model === "field_b_economic_activities"
              );
              if (field_b_economic_activities) {
                this.formItems.itemsEconomicActivities =
                  field_b_economic_activities.options;
              }

              const field_b_tourism_type = this.formConfig.questions.find(
                (q) => q.v_model === "field_b_tourism_type"
              );
              if (field_b_tourism_type) {
                const groupedData = field_b_tourism_type.options.reduce((acc, item) => {
                  const key = item.description || `independent_${item.id}`;

                  if (!acc[key]) {
                    acc[key] = [];
                  }

                  acc[key].push(item);

                  return acc;
                }, {});

                this.formItems.itemsTourismType = groupedData;
              }

              const field_b_community_tourism = this.formConfig.questions.find(
                (q) => q.v_model === "field_b_community_tourism"
              );
              if (field_b_community_tourism) {
                this.formItems.itemsCommunityTourism = field_b_community_tourism.options;
              }

              const field_b_offered_activities = this.formConfig.questions.find(
                (q) => q.v_model === "field_b_offered_activities"
              );
              if (field_b_offered_activities) {
                this.formItems.itemsOfferedActivities =
                  field_b_offered_activities.options;
              }

              const field_b_occupancy_rate = this.formConfig.questions.find(
                (q) => q.v_model === "field_b_occupancy_rate"
              );
              if (field_b_occupancy_rate) {
                const sortedData = field_b_occupancy_rate.options.sort((a, b) => {
                  const rangeA = parseInt(a.text.match(/^\d+/)[0], 10); // Extrae el primer número del rango
                  const rangeB = parseInt(b.text.match(/^\d+/)[0], 10); // Extrae el primer número del rango

                  return rangeA - rangeB;
                });

                this.formItems.itemsOccupancyRate = sortedData;
              }

              const field_b_avg_accommodation_price = this.formConfig.questions.find(
                (q) => q.v_model === "field_b_avg_accommodation_price"
              );
              if (field_b_avg_accommodation_price) {
                const sortedData = field_b_avg_accommodation_price.options.sort(
                  (a, b) => {
                    // Extraer los números de los textos
                    const extractNumber = (text) => {
                      const match = text.match(/\d+\.?\d*/g); // Encuentra todos los números en el texto
                      return match ? parseFloat(match[0].replace(/\./g, "")) : 0; // Toma el primer número, lo convierte a float
                    };

                    const valueA = extractNumber(a.text);
                    const valueB = extractNumber(b.text);

                    return valueA - valueB;
                  }
                );

                this.formItems.itemsAvgAccommodationPrice = sortedData;
              }

              const field_b_accommodation_class = this.formConfig.questions.find(
                (q) => q.v_model === "field_b_accommodation_class"
              );
              if (field_b_accommodation_class) {
                this.formItems.itemsAccommodationClass =
                  field_b_accommodation_class.options;
              }

              const field_b_additional_services = this.formConfig.questions.find(
                (q) => q.v_model === "field_b_additional_services"
              );
              if (field_b_additional_services) {
                this.formItems.itemsAdditionalServices =
                  field_b_additional_services.options;
              }

              const field_b_avg_dish_price = this.formConfig.questions.find(
                (q) => q.v_model === "field_b_avg_dish_price"
              );
              if (field_b_avg_dish_price) {
                const sortedData = field_b_avg_dish_price.options.sort((a, b) => {
                  // Extraer los números de los textos
                  const extractNumber = (text) => {
                    const match = text.match(/\d+\.?\d*/g); // Encuentra todos los números en el texto
                    return match ? parseFloat(match[0].replace(/\./g, "")) : 0; // Toma el primer número, lo convierte a float
                  };

                  const valueA = extractNumber(a.text);
                  const valueB = extractNumber(b.text);

                  return valueA - valueB;
                });

                this.formItems.itemsAvgDishPrice = sortedData;
              }

              const field_b_associations = this.formConfig.questions.find(
                (q) => q.v_model === "field_b_associations"
              );
              if (field_b_associations) {
                this.formItems.itemsAssociations = field_b_associations.options;
              }

              const field_b_escnna_strategy = this.formConfig.questions.find(
                (q) => q.v_model === "field_b_escnna_strategy"
              );
              if (field_b_escnna_strategy) {
                this.formItems.itemsEscnnaStrategy = field_b_escnna_strategy.options;
              }
            }

            if (response.data.response_season) {
              this.label_season = response.data.response_season.label;
              this.season_id = response.data.response_season.id;
            } else {
              this.dialogSeason = true;
            }

            if (
              this.$store &&
              this.$store.state &&
              this.$store.state.user &&
              this.$store.state.user.roles &&
              this.$store.state.user.roles[0] != 1
            ) {
              if (response.data.autocomplete) {
                this.activeWatch = false;
                response.data.autocomplete.forEach((item) => {
                  // Verifica si el campo existe en el formulario de manera segura
                  if (Object.prototype.hasOwnProperty.call(this.form, item.field)) {
                    // Actualiza el campo del formulario con el valor correspondiente
                    this.form[item.field] = item.text_response || item.selected_options;
                  }
                });

                setTimeout(() => {
                  this.activeWatch = true;
                }, 1000);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      } finally {
        this.showDeleteConfirmation = false;
      }
    },

    handleScroll() {
      const container = this.$refs.scrollContainer;
      if (container) {
        this.showIndicator = container.scrollTop === 0; // Muestra el indicador si está en la parte superior

        // Control de las sombras
        this.showTopShadow = container.scrollTop > 0;
        this.showBottomShadow =
          container.scrollTop < container.scrollHeight - container.clientHeight;
      }
    },
    scrollToItem(index) {
      const item = this.$refs["timelineItem" + index][0]; // Acceso directo al primer elemento
      if (item) {
        const container = this.$refs.scrollContainer;
        const itemRect = item.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        // Ajuste adicional para el margen superior
        const offsetTop = itemRect.top + container.scrollTop - containerRect.top - 300; // El valor negativo asegura que el elemento no quede oculto

        container.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
        console.log(
          "Item Rect:",
          itemRect,
          "Container Rect:",
          containerRect,
          "Offset Top:",
          offsetTop
        );
      }
    },

    toggleActividadesCamara(activity) {
      const index = this.selectActividadesCamara.indexOf(activity);
      if (index === -1) {
        this.selectActividadesCamara.push(activity);
      } else {
        this.selectActividadesCamara.splice(index, 1);
      }
    },

    toggleActividadesOfrecidas(activity) {
      const index = this.selectActividadesOfrecidas.indexOf(activity);
      if (index === -1) {
        this.selectActividadesOfrecidas.push(activity);
      } else {
        this.selectActividadesOfrecidas.splice(index, 1);
      }
    },

    toggleServicioAdicional(activity) {
      const index = this.selectServicioAdicional.indexOf(activity);
      if (index === -1) {
        this.selectServicioAdicional.push(activity);
      } else {
        this.selectServicioAdicional.splice(index, 1);
      }
    },

    toggleGremiosAsociaciones(activity) {
      const index = this.selectGremiosAsociaciones.indexOf(activity);
      if (index === -1) {
        this.selectGremiosAsociaciones.push(activity);
      } else {
        this.selectGremiosAsociaciones.splice(index, 1);
      }
    },

    starryNight() {
      anime({
        targets: ["#sky .star"],
        opacity: [
          { duration: 700, value: "0" },
          { duration: 700, value: "1" },
        ],
        easing: "linear",
        loop: true,
        delay: (el, i) => 50 * i,
      });
    },
    shootingStars() {
      anime({
        targets: ["#shootingstars .wish"],
        easing: "linear",
        loop: true,
        delay: (el, i) => 1000 * i,
        opacity: [{ duration: 700, value: "1" }],
        width: [{ value: "150px" }, { value: "0px" }],
        translateX: 350,
      });
    },
    randomRadius() {
      return Math.random() * 0.7 + 0.6;
    },
    getRandomX() {
      return Math.floor(Math.random() * Math.floor(this.vw)).toString();
    },
    getRandomY() {
      return Math.floor(Math.random() * Math.floor(this.vh)).toString();
    },

    checkMobile() {
      this.isMobile = window.innerWidth < 600;
    },

    goToPreviousStep() {
      if (this.previousStep) {
        this.stepFormServiceOrder = this.previousStep.id;
      }
    },
    async goToNextStep() {
      if (this.nextStep) {
        //VALIDAR FORMULARIO ACTUAL EN REF formStep1,2,3... etc
        const { valid } = await this.$refs[
          "formStep" + this.stepFormServiceOrder
        ].validate();
        if (valid) {
          this.stepFormServiceOrder = this.nextStep.id;
        }
      }
    },
    showGroupLabel(item, index) {
      // Verifica si es el primer paso del grupo visible
      return index === 0 || item.group !== this.visibleSteps[index - 1].group;
    },
    async cancelDialogResponse() {
      this.dialogResponse = null;
    },
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  },

  computed: {
    visibleSteps() {
      // Filtrar los pasos visibles
      return this.stepTitles.filter((item) => item.show);
    },
    currentTitle() {
      const step = this.stepTitles[this.stepFormServiceOrder - 1];
      // Busca el label del grupo al que pertenece el step actual
      const groupLabel = this.stepTitles.find(
        (item) => item.group === step.group && item.label
      )?.label;

      return {
        title: step.title,
        label: groupLabel || "", // Si no se encuentra el label, se asigna una cadena vacía
      };
    },

    nextStep() {
      const currentIndex = this.stepTitles.findIndex(
        (step) => step.id === this.stepFormServiceOrder
      );
      return this.stepTitles.slice(currentIndex + 1).find((step) => step.show) || null;
    },

    previousStep() {
      const currentIndex = this.stepTitles.findIndex(
        (step) => step.id === this.stepFormServiceOrder
      );
      return (
        this.stepTitles
          .slice(0, currentIndex)
          .reverse()
          .find((step) => step.show) || null
      );
    },
  },
};
</script>
<style>
.v-field__input {
  padding-top: 8px;
}

/* #sky {
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    margin: 0;
    padding: 0;
} */

/* #shootingstars {
    margin: 0;
    padding: 0;
    width: 150vh;
    height: 100vw;
    position: fixed;
    overflow: hidden;
    transform: translatex(calc(50vw - 50%)) translatey(calc(50vh - 50%)) rotate(120deg);
} */

/* .wish {
    height: 2px;
    top: 300px;
    width: 100px;
    margin: 0;
    opacity: 0;
    padding: 0;
    background-color: white;
    position: absolute;
    background: linear-gradient(-45deg, white, rgba(0, 0, 255, 0));
    filter: drop-shadow(0 0 6px white);
    overflow: hidden;
} */

.v-row {
  margin: 0;
}
</style>

import { createStore } from 'vuex'

export default createStore({
  state: {
    user: null,
    token: null,
    custom_url: null,
    property_id: null,
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_IS_GUIDE(state, value = false) {
      state.is_guide = value
    },
    SET_BUSINESS_INFO(state, value) {
      state.business_info = value
    }
  },
  actions: {
    setUser({commit}, user) {
      commit('SET_USER', user)
    },
    setToken({commit}, token) {
      commit('SET_TOKEN', token)
    },
    setIsGuide({commit}, value = false) {
      commit('SET_IS_GUIDE', value)
    },
    setBusinessInfo({commit}, value) {
      commit('SET_BUSINESS_INFO', value)
    }
  }
})

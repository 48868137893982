<template>
  <v-container fluid class="px-0" style="height: 100%">
    <v-card-text class="px-0">
      <v-row style="height: 100%">
        <v-col cols="12" md="12" class="">
          <div class="d-flex">
            <v-spacer></v-spacer>

            <v-btn-toggle rounded="lg" elevation="1" density="compact" class="mr-3">
              <v-btn
                :active="false"
                @click="drawerFiltro = true"
                color="white"
                class="text-primary text-capitalize"
              >
                <v-icon start>mdi-filter</v-icon> Filtro
              </v-btn>
              <v-btn
                @click="cleanFilter()"
                v-if="isFilterCustomer"
                color="red-app"
                class="text-capitalize bg-red-app"
              >
                <v-icon start>mdi-backspace</v-icon>
                Limpiar filtro
              </v-btn>
            </v-btn-toggle>

            <v-btn
              @click="drawerUser = true"
              color="primary"
              rounded="lg"
              class="text-capitalize"
            >
              <v-icon start>mdi-plus-circle-outline</v-icon>
              Nuevo Usuario
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12" md="12" class="">
          <v-table density="compact">
            <thead>
              <tr>
                <th class="border-0 text-center bg-primary py-4 text-left">ID</th>
                <th class="border-0 text-center bg-primary py-4 text-left">
                  NOMBRE COMPLETO
                </th>
                <th class="border-0 text-center bg-primary py-4 text-left">
                  CORREO ELECTRÓNICO
                </th>
                <th class="border-0 text-center bg-primary py-4 text-left">TELÉFONO</th>
                <th class="border-0 text-center bg-primary py-4 text-left">ROL</th>
                <th class="border-0 text-center bg-primary py-4 text-left">ESTADO</th>
                <th class="border-0 text-center bg-primary py-4 text-left"></th>
              </tr>
              <tr>
                <th colspan="8" style="background: #fcfcfc; height: 15px; border: 0"></th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="item in itemsTable" :key="item.id">
                <td>{{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.email }}</td>
                <td>
                  {{
                    item.response && item.response.details
                      ? getResponseUser(item.response.details, 7)
                      : null
                  }}
                </td>
                <td>{{ item.roles && item.roles[0] ? item.roles[0].name : 'Sin definir' }}</td>
                <td class="text-center">
                  <v-chip
                    label
                    size="small"
                    :color="
                      item.status == 'Verificado'
                        ? 'green-app-2'
                        : item.status == 'Registrado'
                        ? 'primary'
                        : item.status == 'Rechazado'
                        ? 'red-app'
                        : 'grey-app-2'
                    "
                    variant="flat"
                    class="d-flex justify-center font-weight-bold"
                    :class="{ 'text-white': item.status == 'Verificado' }"
                  >
                    {{ item.status }}
                  </v-chip>
                </td>
                <td class="text-center">
                  <div class="d-flex justify-center">
                    <v-btn
                      rounded="lg"
                      style="border: 2px solid"
                      class="my-2 mx-1 font-weight-bold"
                      height="35"
                      max-width="25"
                      size="x-small"
                      variant="outlined"
                      color="primary"
                    >
                      <v-icon size="20">mdi-file-edit-outline</v-icon>
                    </v-btn>
                    <v-btn
                      @click="detailUser(item.id)"
                      rounded="lg"
                      style="border: 2px solid"
                      class="my-2 mx-1 font-weight-bold"
                      height="35"
                      max-width="25"
                      size="x-small"
                      variant="outlined"
                      color="secondary"
                    >
                      <v-icon size="20">mdi-eye-outline</v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
        <v-col cols="8" offset="4" class="d-flex">
          <v-spacer></v-spacer>
          <v-pagination
            :total-visible="4"
            v-model="page"
            variant="flat"
            border="1"
            active-color="secondary"
            density="compact"
            :length="totalPage"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </v-container>

  <v-navigation-drawer
    temporary
    touchless
    v-model="drawerFiltro"
    width="400"
    location="right"
    style="height: 100%; top: 0; z-index: 1009; position: fixed"
  >
    <template v-slot:prepend>
      <v-list-item>
        <v-list-item-action>
          <v-card-title class="font-weight-bold text-h5">Filtro</v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="drawerFiltro = false"
            icon
            size="x-small"
            variant="outlined"
            style="border: 2px solid"
            ><v-icon size="20">mdi-close</v-icon></v-btn
          >
        </v-list-item-action>
      </v-list-item>
    </template>

    <v-card elevation="0" color="transparent">
      <v-card-text>
        <v-row>
          <v-col xs="12" sm="12" md="12" cols="12" class="pb-1">
            <p class="font-weight-bold mb-1">Nombre</p>
            <v-text-field
              rounded="lg"
              clearable
              v-model="formFilter.name"
              density="compact"
              variant="outlined"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="12" cols="12" class="py-1">
            <p class="font-weight-bold mb-1">Correo Electrónico</p>
            <v-text-field
              v-model="formFilter.email"
              density="compact"
              variant="outlined"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="12" cols="12" class="pb-1">
            <p class="font-weight-bold mb-1">User</p>
            <v-select
              rounded="lg"
              clearable
              v-model="formFilter.name"
              density="compact"
              variant="outlined"
            ></v-select>
          </v-col>
          <v-col xs="12" sm="12" md="12" cols="12" class="pb-1">
            <p class="font-weight-bold mb-1">Estado</p>
            <v-select
              rounded="lg"
              clearable
              v-model="formFilter.name"
              density="compact"
              variant="outlined"
              :items="['Por validar', 'Registrado', 'Rechazado', 'Verificado']"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <template v-slot:append>
      <div class="px-8 pb-9">
        <v-btn
          rounded="lg"
          :disabled="loadingGetData"
          :loading="loadingGetData"
          @click="filterApply"
          class="text-capitalize"
          color="primary"
          block
        >
          Filtrar
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>

  <v-navigation-drawer
    temporary
    touchless
    v-model="drawerUser"
    width="400"
    location="right"
    style="height: 100%; top: 0; z-index: 1006; position: fixed"
  >
    <template v-slot:prepend>
      <v-list-item>
        <v-list-item-action>
          <v-card-title class="font-weight-bold text-h5">Agregar Usuario</v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="drawerUser = false"
            icon
            size="x-small"
            variant="outlined"
            style="border: 2px solid"
            ><v-icon size="20">mdi-close</v-icon></v-btn
          >
        </v-list-item-action>
      </v-list-item>
    </template>

    <v-card elevation="0" color="transparent">
      <v-card-text class="pt-0">
        <v-row>
          <v-col xs="12" sm="12" md="12" cols="12" class="pb-1">
            <p class="font-weight-bold mb-1">Nombre</p>
            <v-text-field
              rounded="lg"
              clearable
              density="compact"
              variant="outlined"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="12" cols="12" class="pb-1">
            <p class="font-weight-bold mb-1">Teléfono</p>
            <v-text-field
              rounded="lg"
              clearable
              density="compact"
              variant="outlined"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="12" cols="12" class="pb-1">
            <p class="font-weight-bold mb-1">Correo Electrónico</p>
            <v-text-field
              autocomplete="username"
              rounded="lg"
              clearable
              density="compact"
              variant="outlined"
            ></v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="12" cols="12" class="pb-1">
            <p class="font-weight-bold mb-1">Contraseña</p>
            <v-text-field
              autocomplete="new-password"
              :type="visible ? 'text' : 'password'"
              rounded="lg"
              clearable
              density="compact"
              variant="outlined"
            >
              <template v-slot:append-inner="">
                <v-icon style="opacity: 1" color="primary" @click="visible = !visible">
                  {{ visible ? "mdi-eye-off" : "mdi-eye" }}
                </v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col xs="12" sm="12" md="12" cols="12" class="py-1">
            <p class="font-weight-bold mb-1">Rol</p>
            <v-select
              rounded="lg"
              clearable
              density="compact"
              variant="outlined"
            ></v-select>
          </v-col>
          <v-col xs="12" sm="12" md="12" cols="12" class="py-1 d-flex align-center">
            <p class="font-weight-bold mb-1">¿Cuenta con empresa?</p>
            <div class="ml-auto">
              <v-radio-group
                v-model="formUser.haveCompany"
                color="secondary"
                inline
                hide-details
              >
                <v-radio label="Si" value="Si"></v-radio>
                <v-radio label="No" value="No"></v-radio>
              </v-radio-group>
            </div>
          </v-col>
          <v-col
            v-if="formUser.haveCompany == 'Si'"
            xs="12"
            sm="12"
            md="12"
            cols="12"
            class="pb-1"
          >
            <p class="font-weight-bold mb-1">Nombre de la empresa</p>
            <v-text-field
              rounded="lg"
              clearable
              density="compact"
              variant="outlined"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="formUser.haveCompany == 'Si'"
            xs="12"
            sm="12"
            md="12"
            cols="12"
            class="pb-1"
          >
            <p class="font-weight-bold mb-1">Nit</p>
            <v-text-field
              rounded="lg"
              clearable
              density="compact"
              variant="outlined"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="formUser.haveCompany == 'Si'"
            xs="12"
            sm="12"
            md="12"
            cols="12"
            class="pb-1"
          >
            <p class="font-weight-bold mb-1">Departamento</p>
            <v-select
              rounded="lg"
              clearable
              density="compact"
              variant="outlined"
            ></v-select>
          </v-col>
          <v-col
            v-if="formUser.haveCompany == 'Si'"
            xs="12"
            sm="12"
            md="12"
            cols="12"
            class="pb-1"
          >
            <p class="font-weight-bold mb-1">Municipio</p>
            <v-select
              rounded="lg"
              clearable
              density="compact"
              variant="outlined"
            ></v-select>
          </v-col>
          <v-col
            v-if="formUser.haveCompany == 'Si'"
            xs="12"
            sm="12"
            md="12"
            cols="12"
            class="pb-1"
          >
            <p class="font-weight-bold mb-1">Dirección</p>
            <v-text-field
              rounded="lg"
              clearable
              density="compact"
              variant="outlined"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <template v-slot:append>
      <div class="px-8 pb-9">
        <v-btn
          rounded="lg"
          @click="drawerUser = false"
          class="text-capitalize mb-3"
          variant="text"
          block
        >
          Cancelar
        </v-btn>
        <v-btn
          rounded="lg"
          :disabled="loadingGetData"
          :loading="loadingGetData"
          class="text-capitalize"
          color="primary"
          block
        >
          Guardar
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>

  <DetailUser
    v-if="idUserDetail"
    :userId="idUserDetail"
    @close="idUserDetail = null"
    @update="getItemsData()"
  ></DetailUser>
</template>
<script>
import axios from "axios";
import DetailUser from "./users/DetailUser.vue";

export default {
  name: "UsersAdmin",
  components: {
    DetailUser,
  },
  data() {
    return {
      drawerUser: false,
      drawerFiltro: false,
      isFilterCustomer: false,
      loadingGetData: false,
      idUserDetail: null,
      page: 1,

      visible: false,

      watchPage: true,

      formFilter: {
        name: "",
        name_contact: "",
        email: "",
      },

      formUser: {
        name: null,
        last_name: null,
        phone: null,
        email: null,
        password: null,
        rol: null,
        haveCompany: "No",
        company: null,
        nit: null,
        department: null,
        city: null,
        address: null,
      },

      itemsTable: [],
      totalPage: 0,
      cantPerPage: 10,
    };
  },
  created() {
    this.getItemsData();
  },
  methods: {
    getResponseUser(response_details, questionId) {
      const detail = response_details.find((item) => item.question_id == questionId);

      if (detail && detail.question_type && detail.question_type == "multiple_choice") {
        //LA RESPUESTA SE ENCUENTRA EN selected_options_detail Y TOCA RECORRERLO PARA OBTENER EL TEXTO DE LA RESPUESTA QUE ES text
        return detail.selected_options_detail.map((item) => item.text).join(", ");
      } else if (
        detail &&
        detail.question_type &&
        detail.question_type == "multiple_text"
      ) {
        //LA RESPUESTA SE ENCUENTRA EN selected_options Y ES UN ARRAY DE RESPUESTAS, DE STRINGS
        return detail.selected_options.join(", ");
      } else if (detail && detail.question_type && detail.question_type == "text") {
        //LA RESPUESTA SE ENCUENTRA EN selected_option Y ES UN STRING
        return detail.text_response;
      } else if (
        detail &&
        detail.question_type &&
        detail.question_type == "single_choice"
      ) {
        return detail.selected_options_detail.text;
      } else {
        return null;
      }
    },
    getItemsData() {
      let url = process.env.VUE_APP_API_URL;
      axios
        .get(url + "/users/list")
        .then((response) => {
          const responseData = response.data.data;

          this.itemsTable = responseData.map((obj) => {
            return { ...obj, show_detail: false };
          });

          this.totalPage = Math.ceil(response.data.total / this.cantPerPage);
        })
        .catch((error) => {
          console.error("Error al obtener los datos del recurso:", error);
        });
    },
    detailUser(id) {
      this.idUserDetail = id;
    },
    filterApply() {
      this.isFilterCustomer = true;
      this.drawerFiltro = false;
    },

    cleanFilter() {
      this.formFilter = {
        name: null,
        position: null,
        email: null,
      };
      this.isFilterCustomer = false;
    },
  },
};
</script>

<style>
.drawer-filter + .v-navigation-drawer__scrim {
  position: fixed !important;
}
</style>
